/* eslint-disable @typescript-eslint/no-explicit-any */
import { ItemNFT } from 'features/ShopAP/components'
import { BtnBacktSyled, ButtonSyled, ItemDetailsStyled } from './styled'
import { useEffect, useState } from 'react'
import { CARET_LEFT, LINE_ITEM, FRAME_TOP, LINE_TEXT } from 'assets/images'
import { useNavigate, useParams } from 'react-router-dom'
import Button from 'shared/Button'
import { ItemShop, ShopTypeRariTy, resultItemNFT } from 'models'
import { convertGasPrice, convertTypeItems, getAttribute, handleAsyncRequest } from 'utils/helper'
import itemShopsApi from 'apis/itemShopsApi'
import { useAppDispatch, useToggle, useAppSelector } from 'hooks'
import BuyItemModal from 'features/ShopAP/components/BuyItemModal'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import ConfirmBuyOkModal from 'features/Race/components/ConfirmBuyOkModal'
import { notification } from 'antd'
import SkeletonDetail from 'features/components/SkeletonDetail'
import IconMerahOrKuda from 'features/ShopAP/components/IconMerahOrKuda'
import userApi from 'apis/userApi'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import { ethers } from 'ethers'
import { configs } from 'apps'
import MemoizedImageRaratyCommon from 'shared/MemoizedImage/RaratyCommon'
import MemoizedImageRaratyEpic from 'shared/MemoizedImage/RaratyEpic'
import MemoizedImageRaratyLegendary from 'shared/MemoizedImage/RaratyLegendary'
import MemoizedImageRaratyMythic from 'shared/MemoizedImage/RaratyMythic'
import MemoizedImageRaratyRare from 'shared/MemoizedImage/RaratyRare'

import shopApNFTABI from 'json/ShopAP.json'
import { STATUS_TRANSACTION, timeCheckNonce } from 'apps/constants'
import InProgressBalanceModal from 'features/Balance/components/InProgress'
import { LENDING_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import { useTranslation } from 'react-i18next'

const contract = {
  shopAP: configs.shopAP,
  itemNFT: configs.itemNFT
}
export default function ItemDetails() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [itemDetails, setItemDetails] = useState<ItemShop>()
  const { id } = useParams()
  const [isBuyItemModal, toggleBuyItemModal] = useToggle(false)
  const [isConfirmBuyItemModal, toggleConfirmBuyItemModal] = useToggle(false)
  const [amountInput, setAmountInput] = useState(1)
  const currentUser = useAppSelector(state => state.profile)
  const dispatch = useAppDispatch()
  const [isConfirmCancelItemModal, toggleConfirmCancelItemModal] = useToggle(false)
  const [isItemIsNotAvalable, setIsItemIsNotAvalable] = useToggle(false)
  const [coinADIL, setCoinADIL] = useState<number>(0)

  //start: buy metamask
  const [openInProgressBalanceModal, toggleOpenInProgressBalanceModal] = useToggle(false)
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const fetchItem = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(itemShopsApi.getItemShopsDetails(id || ''))
    if (error?.code == '404') {
      setIsItemIsNotAvalable(true)
    }
    const records = result?.data
    setItemDetails(records)
    setIsLoading(false)
  }

  const oncloseModalItemNotFound = () => {
    setIsItemIsNotAvalable(false)
    navigate(-1)
  }

  useEffect(() => {
    fetchItem()
    getCoinADIL()
  }, [])

  // call api buy itemNFT
  const buyItemNFTAPI = async () => {
    if (itemDetails?.shop_fees_currency === 'MERAH' || itemDetails?.shop_fees_currency === 'MERAH_NEW') {
      toggleOpenInProgressBalanceModal(true)
    }
    if (itemDetails?.shop_fees_currency === 'ADIL' && Number(itemDetails?.price) > coinADIL) {
      transactionFaild(t(`${NOTIFICATION_MESSAGE}.notHaveEnoughADIL`))
      toggleConfirmBuyItemModal(false)
    } else {
      setIsLoading(true)
      const [error, result]: any = await handleAsyncRequest(
        itemShopsApi.postBuyItemShopNFT({
          currency: itemDetails?.shop_fees_currency,
          price: itemDetails?.price,
          token_id: itemDetails?.item?.item_token
        })
      )
      if (error) {
        transactionFaild(error?.message)
        toggleConfirmBuyItemModal(false)
      }
      if (result) {
        if (itemDetails?.shop_fees_currency === 'MERAH' || itemDetails?.shop_fees_currency === 'MERAH_NEW') {
          checkNonceContract(result.data.nonce)
          toggleConfirmBuyItemModal(false)
          toggleBuyItemModal(false)
        } else {
          toggleOpenInProgressBalanceModal(true)
          buyItemNFTContract(result.data)
          checkCurrentCoinUser()
          toggleConfirmBuyItemModal(false)
          toggleBuyItemModal(false)
        }
      }
      setIsLoading(false)
    }
  }
  //end

  const fetchCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return

    dispatch(setCoinUser(resultCoinUser.data))
  }

  // call api buy booster item
  const buyBoosterShopAPI = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      itemShopsApi.postBuyBoosterShop({
        amount: amountInput,
        shop_id: itemDetails?.id
      })
    )
    if (error) {
      transactionFaild(error?.message)
      toggleConfirmBuyItemModal(false)
    }
    if (result) {
      checkCurrentCoinUser()
      toggleConfirmBuyItemModal(false)
      toggleBuyItemModal(false)
      transactionSuccess()
      setAmountInput(1)
    }
    setIsLoading(false)
  }
  // end

  const buyItemNFTContract = async (value: resultItemNFT) => {
    if (!value) return
    try {
      const buyItemContract = new ethers.Contract(contract.shopAP, shopApNFTABI.contract.abi, signer)
      const orderId = value?.order_id
      const gasPrice = convertGasPrice(value.gas_price)
      try {
        const tx = await buyItemContract.buyNative(orderId, {
          gasPrice: gasPrice,
          value: ethers.utils.parseEther(value.price.toString())
        })
        if (tx.hash) {
          await provider.waitForTransaction(tx.hash)
          checkNonceContract(value.nonce)
        }
      } catch (err: any) {
        transactionFaild(err?.error?.data?.message)
        toggleOpenInProgressBalanceModal(false)
      }
    } catch (err: any) {
      transactionFaild(err?.error?.data?.message)
      toggleOpenInProgressBalanceModal(false)
    }
  }

  const checkNonceContract = async (nonce: string) => {
    let checkNonceExits = null as any
    let x = 0
    const intervalID = setInterval(async () => {
      const [, result] = await handleAsyncRequest(itemShopsApi.postCheckNonceItemShopNFT({ nonce }))
      if (!result) return
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        transactionSuccess()
        navigate(-1)
        fetchCoinUser()
      }

      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        transactionFaild(t(`${LENDING_MESSAGE}.failedTransaction`))
        toggleConfirmBuyItemModal(false)
      }

      if (++x === 10) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          clearInterval(intervalID)
          toggleOpenInProgressBalanceModal(false)
          toggleConfirmBuyItemModal(false)
          transactionFaild(t(`${NOTIFICATION_MESSAGE}.transferredPending`))
        }
      }
    }, timeCheckNonce)
  }

  const handleOnChangeInput = (value: number) => {
    setAmountInput(value)
  }

  const checkCurrentCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return
    dispatch(setCoinUser(resultCoinUser.data))
  }

  const onBuyItemClick = () => {
    if (itemDetails?.item_type === 'NFT') {
      toggleConfirmBuyItemModal()
    } else {
      toggleBuyItemModal()
    }
  }

  const onCancelItemClick = () => {
    toggleConfirmCancelItemModal()
  }

  const transactionSuccess = () => {
    toggleOpenInProgressBalanceModal(false)
    notification.success({
      message: 'SUCCESS',
      description: `Buy item successfully!`,
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })
  }

  const transactionFaild = (value: any) => {
    toggleOpenInProgressBalanceModal(false)
    notification.error({
      message: 'ERROR',
      description: value ?? 'Sorry! Your transaction has failed. Please go back and try again.',
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-error',
      icon: <></>
    })
  }

  const handleTotalPayment = () => {
    return parseFloat((Number(itemDetails?.price) * Number(amountInput)).toFixed(5))
  }

  const showIconRariTy = () => {
    const valueRariry = itemDetails?.item?.rarity_type || itemDetails?.boost?.rarity_type
    const handleKeySort = () => {
      switch (valueRariry) {
        case ShopTypeRariTy.ALL:
          return ''
        case ShopTypeRariTy.COMMON.toLocaleUpperCase(): {
          return <MemoizedImageRaratyCommon />
        }
        case ShopTypeRariTy.EPIC.toLocaleUpperCase(): {
          return <MemoizedImageRaratyEpic />
        }
        case ShopTypeRariTy.LEGENDARY.toLocaleUpperCase(): {
          return <MemoizedImageRaratyLegendary />
        }
        case ShopTypeRariTy.MYTHIC.toLocaleUpperCase(): {
          return <MemoizedImageRaratyMythic />
        }
        case ShopTypeRariTy.RARE.toLocaleUpperCase(): {
          return <MemoizedImageRaratyRare />
        }
        default:
          return valueRariry
      }
    }
    return handleKeySort()
  }
  const onCloseBuyItemModal = () => {
    toggleBuyItemModal(false)
    setAmountInput(1)
  }
  const onConfirmCancelSellNft = async () => {
    cancelSellItemShopAPI()
  }
  // call api cancelSellItemShopAPI
  const cancelSellItemShopAPI = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      itemShopsApi.postCancelSellItemShopNFT({
        currency: itemDetails?.currency,
        price: itemDetails?.price,
        token_id: itemDetails?.item?.item_token
      })
    )
    if (error) {
      transactionFaild(error?.message)
      toggleConfirmBuyItemModal(false)
    }
    if (result) {
      toggleOpenInProgressBalanceModal(true)
      cancelSellItemNFTContract(result.data)
      checkCurrentCoinUser()
      toggleConfirmBuyItemModal(false)
    }
    setIsLoading(false)
  }
  // end

  const cancelSellItemNFTContract = async (value: resultItemNFT) => {
    if (!value) return
    try {
      const addHorseContract = new ethers.Contract(contract.shopAP, shopApNFTABI.contract.abi, signer)
      const orderId = value?.order_id
      const gasPrice = convertGasPrice(value.gas_price)
      try {
        const tx = await addHorseContract.cancelSell(orderId, contract.itemNFT, { gasPrice: gasPrice })
        if (tx.hash) {
          await provider.waitForTransaction(tx.hash)
          checkNonceContract(value.nonce)
        }
      } catch (err: any) {
        console.log({ err })
        transactionFaild(err?.error?.data?.message)
      }
    } catch (err: any) {
      console.log({ err })
      transactionFaild(err?.error?.data?.message)
    }
  }

  const getCoinADIL = async () => {
    const addressUser = await signer.getAddress()
    const balance = await provider.getBalance(addressUser)
    const data = +ethers.utils.formatEther(balance)
    setCoinADIL(+data?.toFixed(5) || 0)
  }

  return (
    <ItemDetailsStyled>
      <BtnBacktSyled>
        <div className='btn-back' onClick={() => navigate(-1)}>
          <img src={CARET_LEFT} alt='' />
          <span className='text-btn'>BACK</span>
        </div>
      </BtnBacktSyled>
      <>
        {isLoading ? (
          <SkeletonDetail rows={10} />
        ) : (
          <div className='container'>
            <img src={FRAME_TOP} alt='' className='top-line' />
            <img src={FRAME_TOP} alt='' className='bottom-line' />
            <div className='item-block'>
              <div className='body-item'>
                <div className='item'>
                  <div className='avatar'>
                    <ItemNFT
                      isDetail
                      avt={itemDetails?.item?.img || itemDetails?.boost?.img}
                      type={itemDetails?.item?.body_part || itemDetails?.boost?.boost_type}
                    />
                  </div>
                  <div className='name'>
                    <div className='item-name'>{itemDetails?.item?.name || itemDetails?.boost?.name}</div>
                    <div className='d-flex align-items-center'>
                      {' '}
                      <span className='field-name'>Id </span> {itemDetails?.item?.item_token || itemDetails?.boost?.id}
                    </div>
                    <div className='d-flex align-items-center'>
                      {' '}
                      <span className='field-name'>Owner </span>{' '}
                      {itemDetails?.distributor === 'SYSTEM' ? 'SYSTEM' : itemDetails?.owner?.name}
                    </div>
                    <div className='d-flex align-items-center'>
                      {' '}
                      <span className='field-name'>Type </span>{' '}
                      {convertTypeItems(itemDetails?.item?.body_part || itemDetails?.boost?.boost_type)}
                    </div>
                    <div className='d-flex align-items-center'>
                      {' '}
                      <span className='field-name'>Rarity </span> {showIconRariTy()}
                    </div>
                  </div>
                </div>
                <div className='information'>
                  <div className='information-box'>
                    <div className='attribute active'>
                      Attribute
                      <img src={LINE_TEXT} alt='' className='line-text' />
                    </div>
                  </div>
                  <div className='content mb-3'>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            getAttribute(itemDetails?.item?.effect) || itemDetails?.boost?.attribute || 'No effect'
                        }}
                      />
                    </p>
                  </div>
                  <div className='information-box'>
                    <div className='description active'>
                      Description
                      <img src={LINE_TEXT} alt='' className='line-text' />
                    </div>
                  </div>
                  <div className='content'>
                    {itemDetails?.item?.description || itemDetails?.boost?.description || 'No Information'}
                  </div>
                </div>
              </div>
              <img className='f-left' src={LINE_ITEM} alt='' />
              <img className='f-right' src={LINE_ITEM} alt='' />
            </div>
          </div>
        )}
        {currentUser?.id !== Number(itemDetails?.owner?.id) ? (
          <ButtonSyled>
            <Button
              buttonName={
                <div
                  className={`btn-buy ${
                    itemDetails?.price && itemDetails?.price.toString().length > 10 ? 'scale-down' : ''
                  }`}
                >
                  <IconMerahOrKuda type={itemDetails?.currency} price={itemDetails?.price} />{' '}
                  <span>
                    <br></br>Buy
                  </span>
                </div>
              }
              onClickButton={onBuyItemClick}
              width={150}
              height={50}
              isLoading={isLoading}
              margingTop={true}
              disabled={!itemDetails}
            />
          </ButtonSyled>
        ) : (
          <ButtonSyled>
            <Button
              buttonName={
                <div
                  className={`btn-buy ${
                    itemDetails?.price && itemDetails?.price.toString().length > 10 ? 'scale-down' : ''
                  }`}
                >
                  <IconMerahOrKuda type={itemDetails?.currency} price={itemDetails?.price} />{' '}
                  <span>
                    <br></br>Cancel
                  </span>
                </div>
              }
              onClickButton={onCancelItemClick}
              width={150}
              height={50}
              isLoading={isLoading}
              margingTop={true}
              type='btnCancel'
            />
          </ButtonSyled>
        )}
      </>

      {isBuyItemModal && (
        <BuyItemModal
          toggleIsModalOpen={toggleBuyItemModal}
          onCloseButtonClick={onCloseBuyItemModal}
          itemData={itemDetails}
          onConfirm={toggleConfirmBuyItemModal}
          onChangeInput={handleOnChangeInput}
          value={amountInput}
        />
      )}

      {isItemIsNotAvalable && (
        <ConfirmOkModal
          onCloseButtonClick={oncloseModalItemNotFound}
          onConfirm={oncloseModalItemNotFound}
          message={'This item is not available for sale in the shop.'}
          isLoading={isLoading}
          isContentCenter
          title='failed'
        />
      )}
      {isConfirmCancelItemModal && (
        <ConfirmOkModal
          toggleIsModalOpen={toggleConfirmCancelItemModal}
          onCloseButtonClick={toggleConfirmCancelItemModal}
          onConfirm={onConfirmCancelSellNft}
          message={`You want to cancel the sell of ${
            itemDetails?.item?.name || itemDetails?.boost?.name
          } on the shop. \nAre you sure? `}
          isLoading={isLoading}
          heading='Confirm'
          isContentCenter
        />
      )}

      {isConfirmBuyItemModal && (
        <ConfirmBuyOkModal
          toggleIsModalOpen={toggleConfirmBuyItemModal}
          onCloseButtonClick={toggleConfirmBuyItemModal}
          onConfirm={itemDetails?.item_type === 'NFT' ? buyItemNFTAPI : buyBoosterShopAPI}
          message={''}
          itemDetails={itemDetails}
          isLoading={isLoading}
          heading='Confirm'
          isContentCenter
          handleTotalPayment={handleTotalPayment()}
          margingTop={true}
        />
      )}
      {openInProgressBalanceModal && (
        <InProgressBalanceModal
          title={
            currentUser?.id !== Number(itemDetails?.owner?.id) ? 'BUY ITEM IN PROGRESS' : 'CANCEL ITEM IN PROGRESS'
          }
        />
      )}
    </ItemDetailsStyled>
  )
}
