/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { STRING_ABILITY, STRING_BLOODLINE_CHARACTER, STRING_STATS_TYPE } from 'apps/constants'
import { GUILD_MESSAGE } from 'i18n/constants'
import { useTranslation } from 'react-i18next'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { RateStar } from 'features/Horse/components'
import { sortAbilities } from 'utils/helper'
import { HorseAbility } from 'models'
import HorseStatsContainer from './styled'
import { getAbility, getCharacteristic, getColorHorse, getImageBloodline, getRunType } from 'utils/getLinkImage'
import ModalHorseBloodline from 'features/components/ModalHorseBloodline'
import { useToggle } from 'hooks'

interface HorseStatsProps {
  data: any
}

const HorseStats = ({ data }: HorseStatsProps) => {
  const { t } = useTranslation()
  const [speed, setSpeed] = useState('')
  const [muscle, setMuscle] = useState('')
  const [stamina, setStamina] = useState('')
  const [agility, setAgility] = useState('')
  const [spirit, setSpirit] = useState('')
  const [iq, setIq] = useState('')
  const [bloodlineTitle, setBloodlineTitle] = useState('')
  const [bloodlineContent, setBloodlineContent] = useState('')
  const [openHorseBloodlineModal, toggleOpenHorseBloodlineModal] = useToggle(false)

  useEffect(() => {
    if (data) {
      data?.horse?.list_horse_stats?.map((stat: any) => {
        switch (stat?.stats_type) {
          case STRING_STATS_TYPE.SPEED: {
            return setSpeed(() => stat.stat_rank)
          }
          case STRING_STATS_TYPE.MUSCLE: {
            return setMuscle(() => stat.stat_rank)
          }
          case STRING_STATS_TYPE.STAMINA: {
            return setStamina(() => stat.stat_rank)
          }
          case STRING_STATS_TYPE.AGILITY: {
            return setAgility(() => stat.stat_rank)
          }
          case STRING_STATS_TYPE.SPIRIT: {
            return setSpirit(() => stat.stat_rank)
          }
          case STRING_STATS_TYPE.IQ: {
            return setIq(() => stat.stat_rank)
          }
          default:
            break
        }
      })
    }
  }, [data])

  const generateRateLevel = (maxLevel: number, currentLevel: number): JSX.Element[] => {
    const rateStars: JSX.Element[] = []

    for (let i = 0; i < maxLevel; i++) {
      rateStars.push(<RateStar key={i} isActive={i < currentLevel} />)
    }

    return rateStars
  }

  const _handleClickImage = (event: React.MouseEvent, type: string, content: string) => {
    event.stopPropagation()
    setBloodlineTitle(type)
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  const _handleClosePopup = () => {
    toggleOpenHorseBloodlineModal(false)
  }

  const _handleClickImageAbility = (event: React.MouseEvent, content: string) => {
    event.stopPropagation()
    switch (content?.toUpperCase()) {
      case STRING_ABILITY.DIRT?.toUpperCase():
      case STRING_ABILITY.TURF?.toUpperCase():
        setBloodlineTitle('Ability Field Type')
        break
      case STRING_ABILITY.LONG?.toUpperCase():
      case STRING_ABILITY.MEDIUM?.toUpperCase():
      case STRING_ABILITY.SHORT?.toUpperCase():
        setBloodlineTitle('Ability Distance')
        break
      default:
        break
    }
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  return (
    <HorseStatsContainer colorHorse={getColorHorse(data?.horse?.bloodline?.color)}>
      <div className='content-left'>
        <div className='box__bloodline'>
          {/* bloodline */}
          <div
            onClick={event =>
              _handleClickImage(
                event,
                STRING_BLOODLINE_CHARACTER.BLOODLINE,
                `${data?.horse?.bloodline?.name} - ${data?.horse?.bloodline?.type}`
              )
            }
            className='image_bloodline'
          >
            <MemoizedLoadImageCommon srcImg={getImageBloodline(data?.horse?.bloodline?.name)} />
          </div>

          {/* run type */}
          <div
            onClick={event => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.RUN_TYPE, data?.horse?.run_type)}
            className='image_run_type'
          >
            <MemoizedLoadImageCommon srcImg={getRunType(data?.horse?.run_type)} />
          </div>

          {/* characteristic */}
          <div
            onClick={event =>
              _handleClickImage(event, STRING_BLOODLINE_CHARACTER.CHARACTERISTIC, data?.horse?.characteristic_display)
            }
            className='image_charactic'
          >
            <MemoizedLoadImageCommon srcImg={getCharacteristic(data?.horse?.characteristic_display)} />
          </div>

          {/* color */}
          <div
            onClick={event => _handleClickImage(event, STRING_BLOODLINE_CHARACTER.COLOR, data?.horse?.bloodline?.color)}
            className='image_color'
          ></div>
        </div>
      </div>
      <div className='content-right'>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_speed`)}:</span>
          <span className='content_stats'>{speed ?? '---'}</span>
        </div>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_muscle`)}:</span>
          <span className='content_stats'>{muscle ?? '---'}</span>
        </div>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_stamina`)}:</span>
          <span className='content_stats'>{stamina ?? '---'}</span>
        </div>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_agility`)}:</span>
          <span className='content_stats'>{agility ?? '---'}</span>
        </div>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_spirit`)}:</span>
          <span className='content_stats'>{spirit ?? '---'}</span>
        </div>
        <div className='stats'>
          <span className='title title__stats'>{t(`${GUILD_MESSAGE}.horse_iq`)}:</span>
          <span className='content_stats'>{iq ?? '---'}</span>
        </div>

        {data?.horse &&
          sortAbilities(data?.horse?.list_horse_ability)?.map((skill: HorseAbility, index: number) => (
            <div key={index} className='stats stats_ability'>
              <span className='title title__stats'>
                <div onClick={event => _handleClickImageAbility(event, skill?.name_en)} className='img__ability'>
                  <MemoizedLoadImageCommon srcImg={getAbility(skill?.name_en)} />
                </div>
              </span>
              <span className='content_stats d-flex align-items-center'>{generateRateLevel(3, skill?.level)}</span>
            </div>
          ))}

        {openHorseBloodlineModal && (
          <ModalHorseBloodline title={bloodlineTitle} content={bloodlineContent} onClose={_handleClosePopup} />
        )}
      </div>
    </HorseStatsContainer>
  )
}

export default HorseStats
