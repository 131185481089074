export interface ShopResponse {
  limit: number
  page: number
  records: ItemShop[]
  total: number
  total_page: number
}

export interface ItemShop {
  amount: number
  boost: {
    boost_type: string
    currency: string
    description: string
    effect: {
      effect: {
        master_effect_type: string
        unit: string
        value: string
      }
      recover: string
    }
    effect_type: string
    id: string
    img: string
    level: string
    name: string
    price?: string
    rarity_type: string
    attribute: string
  }
  currency: string
  distributor: string
  id: string
  item: {
    active: true
    body_part: string
    color: string
    description: string
    effect: {
      effect: [
        {
          master_effect_type: string
          unit: string
          value: string
        }
      ]
      recover: string
    }
    id: string
    img: string
    item_set_id: string
    item_token: string
    name: string
    own_status: string
    rarity_type: string
    status: string
  }
  item_info: string
  item_type: string
  owner: {
    avatar: string
    description: string
    email: string
    guild_tag: string
    h2h_id: string
    id: string
    name: string
    public_address: string
  }
  price?: number
  img?: string
  name?: string
  body_part?: string
  item_token: string
  rarity_type: string
  effect: {
    effect: [
      {
        master_effect_type: string
        unit: string
        value: string
      }
    ]
    recover: string
  }
  description: string
  shop_fees_currency?: string
}

export interface ItemShopsParams {
  limit: number
  page: number
  name?: string
  type?: string
  search?: string | null
  sort?: string
  bodyPart?: string
  boostType?: string
  distributor?: string | null
  rarity?: string
  yourItems?: boolean
}

export interface ItemDetailsParams {
  itemShopId: number | string
}

export enum ShopTypeBodyPart {
  HEAD = 'inventory_head',
  BODY = 'inventory_body',
  LEG = 'inventory_leg',
  SKIN = 'inventory_skin',
  ENERGY = 'inventory_energy',
  DOPING = 'inventory_doping',
  NFT = 'inventory_nft',
  SELLING = 'inventory_selling',
  SELLING_HEAD = 'selling_head',
  SELLING_BODY = 'selling_body',
  SELLING_LEG = 'selling_leg',
  SELLING_SKIN = 'selling_skin'
}

export interface ShopInventoryParams {
  limit: number
  page: number
  name?: string
  bodyPart?: string
  search?: string | null
  sort?: string
  filter?: string
  rarity?: string
  type?: string
  boostType?: string
}

export interface SellItemNFTParams {
  token_id?: string
  currency: string
  price: number
}

export interface resultItemNFT {
  block_expired: number
  currency: string
  gas_price: number
  horse_farm_address: string
  nonce: string
  order_id: number
  owner: string
  price: number
  r: string
  s: string
  token_id: number
  transporter_address: string
  v: number
  token_address: string
}
