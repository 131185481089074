import { Spin } from 'antd'

import LoadingContainer from './styled'

const Loading = () => {
  return (
    <LoadingContainer>
      <Spin />
    </LoadingContainer>
  )
}

export default Loading
