import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getWeather } from './action'
import { WeatherCurrentModel, WeatherModel } from 'models'

interface initialStateType {
  loadingWeather: boolean
  weather: WeatherModel
  errorWeather: string
}

const initialState: initialStateType = {
  loadingWeather: false,
  weather: {},
  errorWeather: ''
}

export const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    updateWeather(state, action: PayloadAction<WeatherCurrentModel>) {
      state.weather = {
        ...state.weather,
        weather: { ...action.payload }
      }
    }
  },
  extraReducers: {
    //get weather
    [getWeather.pending.type]: state => {
      state.loadingWeather = true
    },

    [getWeather.fulfilled.type]: (state, action: PayloadAction<WeatherModel>) => {
      state.loadingWeather = false
      state.errorWeather = ''
      state.weather = action.payload
    },

    [getWeather.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingWeather = false
      state.errorWeather = action.payload
    }
  }
})

export const { updateWeather } = weatherSlice.actions
export default weatherSlice.reducer
