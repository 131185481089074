/* eslint-disable @typescript-eslint/no-explicit-any */
import lendingApi from 'apis/lending'
import specialRaceApi from 'apis/specialRaceApi'
import { configs, paths } from 'apps'
import { STATUS_TRANSACTION, timeCheckNonce } from 'apps/constants'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import InProgressBalanceModal from 'features/Balance/components/InProgress'
import { RequestLoginModal } from 'features/Race/components'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import SkeletonDetail from 'features/components/SkeletonDetail'
import { useAppSelector, useFocusTopScreen, useToggle } from 'hooks'
import { LENDING_MESSAGE, NOTIFICATION_MESSAGE, SPECIAL_MESSAGE } from 'i18n/constants'
import specialHorseFarm from 'json/SpecialHorseFarm.json'
import specialHorseFarm_V2 from 'json/SpecialHorseFarm_V2.json'
import { ApiResponse, LendingHorse, LendingHorseMarket, MODAL_TYPE, OWN_STATUS, notificationTemplate } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BtnBack from 'shared/BtnBack'
import Button from 'shared/Button'
import { convertGasPrice, handleAsyncRequest } from 'utils/helper'
import openNotification from 'utils/toast'
import HorseAvatar from '../../../Horse/components/HorseAvatar'
import HorseBodyInfo from '../../../Horse/components/HorseBodyInfo'
import HorseDetailStyled from './styled'

function HorseDetail() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { horse_id, raceId } = useParams()
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const auth = useAppSelector(state => state.auth)

  const [horse, setHorse] = useState<LendingHorse>()

  const [isLoading, setIsLoading] = useToggle(false)
  const [isWithdraw, setIsWithdraw] = useToggle(false)

  const [resultHorseContract, setResultHorseContract] = useState<any>()

  const [openNotificationModal, toggleOpenNotificationModal] = useToggle(false)
  const [message, setMessage] = useState<notificationTemplate>()
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)

  const [isWithdrawContract, toggleIsWithdrawContract] = useToggle(false)
  const [openConfirmLeaseModal, toggleOpenConfirmLeaseModal] = useToggle(false)
  const [openInProgressBalanceModal, toggleOpenInProgressBalanceModal] = useToggle(false)
  const [isCurrentFarm, setIsCurrentFarm] = useState(false)

  const { pathname: currentPathname } = useLocation()
  const urlPathName = currentPathname?.split('/').slice(2)[0]

  useFocusTopScreen()

  useEffect(() => {
    fetchHorse()
  }, [horse_id])

  const fetchHorse = async () => {
    setIsLoading(true)
    const [error, horseResponse]: any = await handleAsyncRequest<ApiResponse<LendingHorseMarket>>(
      urlPathName === paths.subDomainSpecialRace.currentEvent()
        ? specialRaceApi.getHorseDetail(horse_id || '', raceId || '')
        : specialRaceApi.getOldFarmDetail(horse_id || '', raceId || '')
    )
    if (error) {
      if (error.code === 400) {
        setMessage?.({
          body: error.message,
          title: MODAL_TYPE.failed
        })
      }
      // toggleOpenNotificationHorseDisableModal?.(true)
    }
    if (horseResponse) {
      setHorse(horseResponse.data.horse)
      setIsCurrentFarm(horseResponse.data?.is_in_current_farm && !horseResponse.data?.can_add_tournament)
    }
    setIsLoading(false)
  }

  const checkNonceWithDrawContract = async (nonce: string) => {
    let checkNonceExits = null as any
    let x = 0
    const intervalID = setInterval(async () => {
      const [, result] = await handleAsyncRequest(lendingApi.postCheckNonce({ nonce }))
      if (!result) return
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        fetchHorse()
        transactionSuccess()
      }

      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        setMessage?.({
          body: t(`${LENDING_MESSAGE}.failedTransaction`),
          title: MODAL_TYPE.failed
        })
        toggleOpenNotificationModal?.(true)
      }

      if (++x === 12) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          clearInterval(intervalID)
          toggleOpenInProgressBalanceModal(false)
          setMessage?.({
            body: t(`${NOTIFICATION_MESSAGE}.transferredPending`),
            title: 'warning'
          })
          toggleOpenNotificationModal?.(true)
        }
      }
    }, timeCheckNonce)
  }

  const pushNotification = (description: string, isSuccess?: boolean) => {
    openNotification({
      message: '',
      description: description,
      className: isSuccess ? 'toast-success' : 'toast-error'
    })
  }

  const withdrawHorseSpecial = async () => {
    setIsWithdraw(true)
    const [error, result]: any = await handleAsyncRequest(specialRaceApi.postWithdrawSpecial({ horse_ids: [horse_id] }))
    if (error) {
      setIsWithdraw(false)
      setMessage?.({
        body: error.message,
        title: MODAL_TYPE.failed
      })
      toggleOpenNotificationModal?.(true)
      toggleOpenConfirmLeaseModal(false)
      return
    }
    if (result) {
      setResultHorseContract(result.data)
      toggleIsWithdrawContract(true)
      toggleOpenInProgressBalanceModal(true)
    }
    setIsWithdraw(false)
  }

  useEffect(() => {
    if (isWithdrawContract) {
      withdrawHorseContract()
    }
  }, [isWithdrawContract])

  const withdrawHorseContract = async () => {
    if (!resultHorseContract) return
    try {
      const coverBlockExpired = new BigNumber(resultHorseContract.block_expired).toFixed()
      const coverHorseId = new BigNumber(resultHorseContract.token_ids).toFixed()

      let withdrawContract: any = ''
      let param: any = ''
      if (resultHorseContract?.horse_farm_address?.toUpperCase() === configs.specialHorseFarm?.toUpperCase()) {
        withdrawContract = new ethers.Contract(configs.specialHorseFarm, specialHorseFarm.contract.abi, signer)
        param = {
          owner: resultHorseContract.owner,
          horseId: resultHorseContract.token_ids,
          blockExpired: coverBlockExpired,
          nonce: resultHorseContract.nonce,
          v: resultHorseContract.v,
          r: resultHorseContract.r,
          s: resultHorseContract.s
        }
      } else {
        withdrawContract = new ethers.Contract(configs.specialHorseFarm_V2, specialHorseFarm_V2.contract.abi, signer)
        param = {
          owner: resultHorseContract.owner,
          horseAddress: resultHorseContract?.horse_contract,
          horseId: coverHorseId,
          blockExpired: coverBlockExpired,
          nonce: resultHorseContract.nonce,
          v: resultHorseContract.v,
          r: resultHorseContract.r,
          s: resultHorseContract.s
        }
      }

      try {
        const tx = await withdrawContract.withdraw(param, { gasPrice: convertGasPrice(resultHorseContract.gas_price) })
        if (tx.hash) {
          await provider.waitForTransaction(tx.hash)
          checkNonceWithDrawContract(resultHorseContract.nonce)
        }
      } catch (err) {
        transactionFaild()
      }
    } catch (err) {
      transactionFaild()
    }
  }

  const handleBtnBackClick = () => {
    navigate(-1)
  }

  const transactionSuccess = () => {
    toggleOpenInProgressBalanceModal(false)
    toggleOpenConfirmLeaseModal(false)
    pushNotification(t(`${SPECIAL_MESSAGE}.successTransaction`), true)
  }

  const transactionFaild = () => {
    setMessage?.({
      body: t(`${LENDING_MESSAGE}.failedTransaction`),
      title: MODAL_TYPE.failed
    })
    toggleOpenNotificationModal?.(true)
    toggleOpenInProgressBalanceModal(false)
    toggleOpenConfirmLeaseModal(false)
    toggleIsWithdrawContract(false)
  }

  const handleClickBtnWithdraw = () => {
    toggleOpenNotificationModal(false)
    toggleOpenConfirmLeaseModal(true)
  }

  const checkMyHorse = () => {
    if (auth.user_id === horse?.user?.id && horse?.own_status === OWN_STATUS.STABLE && !isCurrentFarm) return true
    else return false
  }

  return (
    <div className='container'>
      <HorseDetailStyled>
        <BtnBack onBack={handleBtnBackClick} />

        {isLoading && <SkeletonDetail />}
        {horse && !isLoading && (
          <div className='horse-detail-container'>
            <div className='quick-view-left'>
              <HorseAvatar horse={horse} displayVip={false} />
            </div>
            <div className='quick-view-center'>
              <div className='right-container color-white'>
                <HorseBodyInfo horse={horse} isLending={true} />
                <div className='btn-bottom'>
                  {checkMyHorse() && (
                    <Button
                      buttonName='Withdraw to wallet'
                      onClickButton={handleClickBtnWithdraw}
                      type='btnCancel'
                      // isLoading={btnLoading}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {openNotificationModal && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleOpenNotificationModal}
            onCloseButtonClick={toggleOpenNotificationModal}
            onConfirm={toggleOpenNotificationModal}
            message={<span> {message?.body} </span>}
            title={message?.title}
          />
        )}

        {openConfirmLeaseModal && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleOpenConfirmLeaseModal}
            onCloseButtonClick={toggleOpenConfirmLeaseModal}
            onConfirm={withdrawHorseSpecial}
            message={
              <div className='font-size-24 message-content'>
                {`To register to special race, your NFT horse will be transfer to farm. 
                A fee(ADIL) is required for farming.
                Afer put into farm, your authority over your horse will temporary locked.

                Withdraw NFT horse from farm to your wallet will also require fee(ADIL).
                
                Do you want to withdraw your NFT horse to wallet?`}
              </div>
            }
            isLoading={isWithdraw}
            title={t(`${SPECIAL_MESSAGE}.withdraw_horse_to_wallet`)}
          />
        )}

        {openInProgressBalanceModal && <InProgressBalanceModal title={'WITHDRAW IN PROGRESS'} />}
        {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
      </HorseDetailStyled>
    </div>
  )
}

export default HorseDetail
