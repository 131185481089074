/* eslint-disable @typescript-eslint/no-explicit-any */
import specialRaceApi from 'apis/specialRaceApi'
import { constants } from 'apps'
import { RaceTable, RequestLoginModal } from 'features/Race/components'
import CountdownTime from 'features/components/CountdownTime'
import TimeLine from 'features/components/TimeLine'
import { useAppDispatch, useDebounce, useToggle, useUpdateEffect } from 'hooks'
import {
  EVENT_DAY,
  GetCurrentRaceListParams,
  RaceStatus,
  RecordRace,
  TOURNAMENT_STATUS,
  TournamentStatus
} from 'models'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { OneLineTitle } from 'shared'
import { WS_MANAGER } from 'socket/socketClient'
import { openListEventColumns } from 'utils/columns'
import { convertDateTime, convertPluralText, getWeekDays, handleAsyncRequest } from 'utils/helper'
import CurrentFarm from '../CurrentFarm'

import { SPECIAL_MESSAGE } from 'i18n/constants'
import { useTranslation } from 'react-i18next'
import { convertShortDay } from 'utils/time'
import { NUMBER } from 'apps/constants'
import CurrentEventStyled from './styled'
import { getTooltipInfo } from 'apps/redux/tooltip/action'

const defaultParams: GetCurrentRaceListParams = {
  limit: NUMBER.TWENTY,
  page: NUMBER.ONE,
  myHorse: false,
  dayOrder: EVENT_DAY.FIRST
}

export default function CurrentEvent() {
  const [params, setParams] = useState<GetCurrentRaceListParams>(defaultParams)
  const [, setIsLoading] = useState(true)
  const dispatch = useAppDispatch()
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [dayType, setDayType] = useState<string>(EVENT_DAY.FIRST)
  const memoizedResultListColumns = useMemo(() => openListEventColumns, [])
  const [currentRaces, setCurrentRaces] = useState<RecordRace[]>([])
  const [totalFirstDay, setTotalFirstDay] = useState(NUMBER.ZERO)
  const [totalLastDay, setTotalLastDay] = useState(NUMBER.ZERO)
  const [timeOpen, setTimeOpen] = useState<number>(NUMBER.ZERO)
  const [searchValue] = useState<string>('')
  const [status, setStatus] = useState<TOURNAMENT_STATUS>()
  const [tournament, setTournament] = useState<TournamentStatus>()
  const [isMyHorseOn] = useToggle(false)
  const [isRefreshRace, toggleIsRefreshRace] = useToggle(false)

  const { t } = useTranslation()

  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const { WAITING, CLOSED, LIVE } = RaceStatus
  const { PENDING, POOL_OPEN, SELECTION_HORSE, SCHEDULE_PUBLIC, END } = TOURNAMENT_STATUS

  const fetchTournamentStatus = async () => {
    const [error, result] = await handleAsyncRequest(specialRaceApi.getTournamentStatus())
    if (error) {
      setStatus(END)
    }
    if (!result) return
    if (result.data.tournament_status === PENDING) {
      const time = result.data.event_open_time - new Date().getTime()
      setTimeOpen(time)
    }
    if (result.data.tournament_status === POOL_OPEN) {
      const time = result.data.lock_farm_time - new Date().getTime()
      setTimeOpen(time)
    }

    setTournament(result.data)

    const firstDay = result.data.time_open.find((item: any) => item.day_order === EVENT_DAY.FIRST)
    const lastDay = result.data.time_open.find((item: any) => item.day_order === EVENT_DAY.LAST)

    setTotalFirstDay(firstDay.total_race)
    setTotalLastDay(lastDay.total_race)

    // setStatus(POOL_OPEN)
    setStatus(result.data.tournament_status)
  }

  const fetchListRaces = async () => {
    setIsLoading(true)
    const [error, result] = await handleAsyncRequest(
      specialRaceApi.getCurrentEvent({
        ...params,
        dayOrder: dayType
      })
    )
    if (error) {
      setIsLoading(false)
      return
    }
    if (result) {
      const records = result?.data?.records
      setCurrentRaces(records)
      if (dayType === EVENT_DAY.FIRST && result?.data?.total === NUMBER.ZERO) {
        setDayType(EVENT_DAY.LAST)
        setIsLoading(false)
        return
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchTournamentStatus()
    dispatch(getTooltipInfo())

    const subscription = WS_MANAGER.subscribe('/topic/special-race-status', handleSocketChange)
    const subscriptionEventStatus = WS_MANAGER.subscribe('/topic/event-status', handleSocketEventStatusChange)
    return () => {
      subscription?.then(sub => sub?.unsubscribe())
      subscriptionEventStatus?.then(sub => sub?.unsubscribe())
    }
  }, [])

  useEffect(() => {
    if (tournament) {
      if (
        convertDateTime(tournament?.time_open[NUMBER.ZERO]?.race_start_time) !== convertDateTime(new Date()) &&
        convertDateTime(tournament?.time_open[NUMBER.ONE]?.race_start_time) === convertDateTime(new Date()) &&
        tournament?.time_open[NUMBER.ONE]?.total_race !== NUMBER.ZERO
      ) {
        setDayType(EVENT_DAY.LAST)
      }
    }
  }, [tournament])

  const handleSocketChange = (message: { body: string }) => {
    const { data } = JSON.parse(message.body)
    const { newStatus } = data[Object.keys(data)[NUMBER.ZERO]]

    if (newStatus === WAITING || newStatus === CLOSED) {
      toggleIsRefreshRace()
    }
    if (newStatus === LIVE) {
      // toggleRaceLive()
    }
  }

  const handleSocketEventStatusChange = (message: { body: string }) => {
    const { status } = JSON.parse(message.body)
    if (status) {
      fetchTournamentStatus()
    }
  }

  useEffect(() => {
    if (status === SCHEDULE_PUBLIC) {
      fetchListRaces()
    }
  }, [status, params, dayType, isRefreshRace])

  useUpdateEffect(() => {
    setParams({ ...params, page: NUMBER.ONE, search: debounceSearchValue, myHorse: isMyHorseOn })
  }, [debounceSearchValue, isMyHorseOn])

  const handleClickTab = (type: string) => {
    if (dayType === type) return
    setDayType(type)
    setParams({ ...params, page: NUMBER.ONE })
  }

  const handleTimeout = () => {
    fetchTournamentStatus()
  }

  const layoutCountdown = () => {
    const timeStatus = () => {
      switch (status) {
        case PENDING:
          return 'Event starts in'
        case POOL_OPEN:
          return 'Farm closes at'
        case END:
          return 'NO EVENT'
        default:
          break
      }
    }

    return status === END ? (
      <div className='color-orange-200 text-warning'> {timeStatus()}</div>
    ) : (
      <CountdownTime firstTime={timeOpen} title={timeStatus()} onTimeOut={handleTimeout} />
    )
  }

  return (
    <CurrentEventStyled>
      <div className='current-event-container'>
        {(status === POOL_OPEN || status === SELECTION_HORSE || status === SCHEDULE_PUBLIC) && (
          <TimeLine status={tournament} />
        )}
        {(status === POOL_OPEN || status === PENDING || status === END) && layoutCountdown()}
        {(status === POOL_OPEN || status === SELECTION_HORSE) && (
          <CurrentFarm status={status} tournamentId={tournament?.id} />
        )}
        {/* {status === SELECTION_HORSE && layoutNoPublicInfo()} */}
        {status === SCHEDULE_PUBLIC && (
          <div className='current-event'>
            <OneLineTitle text='Race list' isLending />
            <div className='tab-lend'>
              <div className='tab-block'>
                {tournament &&
                  tournament.time_open.map((item, index) =>
                    item.total_race === NUMBER.ZERO ? (
                      <Fragment key={index}></Fragment>
                    ) : (
                      <div
                        key={index}
                        className={`tab-link text-center ${dayType === item.day_order ? 'active' : 'disabled'} ${
                          totalFirstDay || totalLastDay ? 'w-100' : ''
                        }`}
                        onClick={() => handleClickTab(item.day_order)}
                      >
                        <div>
                          {convertShortDay(item.race_start_time)} {`(${getWeekDays(item.race_start_time)})`}
                        </div>
                        <div className='total-race'>{convertPluralText(item.total_race, 'race')}</div>
                      </div>
                    )
                  )}
              </div>
            </div>
            <RaceTable
              columns={memoizedResultListColumns}
              data={currentRaces}
              raisePage={setParams}
              loader={true}
              params={params}
              isRowClickable
              checkLoadingPage={false}
              totalRace={dayType === EVENT_DAY.FIRST ? totalFirstDay : totalLastDay}
              noDataText={t(`${SPECIAL_MESSAGE}.noRaceInEvent`)}
            />
            <CurrentFarm status={status} tournamentId={tournament?.id} />
          </div>
        )}
      </div>
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </CurrentEventStyled>
  )
}
