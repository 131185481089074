import ClassTagStyled from './styled'
import classNames from 'classnames'
import { removeCharacterEnd } from 'utils/helper'

interface ClassTagProps {
  isInHomePage?: boolean
  text?: string
  onTagClicked?: () => void
  isActive: boolean
  customClass?: string
}

function ClassTag({ text, onTagClicked, isActive, customClass = '', isInHomePage = false }: ClassTagProps) {
  const classTagClass = classNames(customClass)

  return (
    <ClassTagStyled
      className={classTagClass}
      isActive={isActive}
      isInHomePage={isInHomePage}
      canBeClicked={Boolean(onTagClicked)}
      valueText={text as string}
    >
      <span className='class-title text-uppercase color-primary font-bold'>{text === 'FreeStyle' ? 'Free Class' : text }</span>
    </ClassTagStyled>
  )
}

export default ClassTag
