import React from 'react'
import styled from 'styled-components'
import { HORSE_ARMOR_LINE_DECO } from 'assets/images'

const ContentStyled = styled.div`
  height: 100%;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
`

const MemoizedImageHorseArmorLine = () => {
  return (
    <ContentStyled>
      <img src={HORSE_ARMOR_LINE_DECO} alt='' loading='lazy' />
    </ContentStyled>
  )
}
export default React.memo(MemoizedImageHorseArmorLine)
