import { useOutlet } from 'react-router-dom'

import GuildStyle from './styled'

function GuildMain() {
  const outlet = useOutlet()

  return (
    <GuildStyle>
      <div className='container'>
        <div className='content'>{outlet}</div>
      </div>
    </GuildStyle>
  )
}

export default GuildMain
