/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from 'shared'
import { ModalConfirmCreateGuildContainer } from './styled'
// import { CLOSE_BTN } from 'assets/images'
import Button from 'shared/Button'

interface MProps {
  onClose: () => void
  onConfirm: () => void
  titleModal?: string
  loading?: boolean
  error?: string
  content?: any
  margingTop?: boolean
  widthM?: number
  heightM?: number
}

const ModalConfirmEditGuild = ({
  onClose,
  onConfirm,
  titleModal = '',
  loading,
  error = '',
  content = '',
  margingTop = false,
  widthM = 515,
  heightM = 274
}: MProps) => {
  const _onConfirm = () => {
    onConfirm()
  }

  return (
    <Modal>
      <ModalConfirmCreateGuildContainer widthM={widthM} heightM={heightM}>
        <div className='modal__container'>
          <div className='header'>
            <p>
              <span className='title'>{titleModal}</span>
            </p>
            <span className='line__under--title'></span>
          </div>
          {/* <button
            disabled={loading}
            className='close-btn p-0 position-absolute'
            role='button'
            onClick={onClose}
          >
            <img src={CLOSE_BTN} alt='close' />
          </button> */}
          <div className='content-box'>
            <p>{content}</p>
          </div>
          <p className='error'>{error}</p>
          <div className='button-box'>
            <Button
              buttonName='No'
              onClickButton={onClose}
              width={150}
              height={35}
              type='btnCancel'
              disabled={loading}
            />
            <Button
              buttonName='Yes'
              onClickButton={_onConfirm}
              width={150}
              height={35}
              margingTop={margingTop}
              isLoading={loading}
            />
          </div>
        </div>
      </ModalConfirmCreateGuildContainer>
    </Modal>
  )
}

export default ModalConfirmEditGuild
