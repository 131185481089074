/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from 'shared'
import { ModalConfirmLeaveGuildContainer } from './styled'
import { CLOSE_BTN } from 'assets/images'
import Button from 'shared/Button'

interface MProps {
  onClose: () => void
  onConfirm: () => void
  loading: boolean
  message?: any
  title?: string
}

const ModalConfirmLeaveGuild = ({ onClose, onConfirm, loading, message, title }: MProps) => {
  const _onConfirm = () => {
    onConfirm()
  }

  return (
    <Modal>
      <ModalConfirmLeaveGuildContainer>
        <div className='header'>
          <p>
            <span className='title'>{title || 'LEAVE GUILD'}</span>
          </p>
        </div>
        <button disabled={loading} className='close-btn p-0 position-absolute' role='button' onClick={onClose}>
          <img src={CLOSE_BTN} alt='close' />
        </button>
        <div className='content-box'>
          {message ? (
            <p>{message}</p>
          ) : (
            <p>
              When you leave the guild, you will not receive guild benefits.
              <br />
              All your horse will be returned to your farm
              <br />
              Are you sure you want to leave this guild?
            </p>
          )}
        </div>
        <div className='button-box'>
          <Button buttonName='No' onClickButton={onClose} width={100} height={35} type='btnCancel' disabled={loading} />
          <Button
            buttonName='Yes'
            onClickButton={_onConfirm}
            width={100}
            height={35}
            margingTop={true}
            isLoading={loading}
          />
        </div>
      </ModalConfirmLeaveGuildContainer>
    </Modal>
  )
}

export default ModalConfirmLeaveGuild
