import { Spin } from 'antd'
import {
  BG_BUTTON,
  BTN_CANCEL,
  // REMOVE_BTN,
  BTN_SELL,
  BTN_CEDE,
  BTN_SEND_TO_BLACKLIST,
  BTN_DISBAND
} from 'assets/images'
import ButtonStyled from './styled'

interface ButtonProps {
  onClickButton: (event: React.MouseEvent<HTMLElement>) => void
  buttonName: string | React.ReactNode
  disabled?: boolean
  width?: number
  height?: number
  isLoading?: boolean
  classNames?: string
  type?: string
}

function ButtonTable({
  onClickButton,
  buttonName,
  disabled,
  width = 215,
  height = 45,
  isLoading,
  type = 'default',
  classNames
}: ButtonProps) {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled || isLoading) return
    onClickButton(event)
  }

  const checkBtn = () => {
    switch (type) {
      case 'btnDisband': {
        return BTN_DISBAND
      }
      case 'btnSell': {
        return BTN_SELL
      }
      case 'btnCede': {
        return BTN_CEDE
      }
      case 'btnRemove': {
        return BTN_DISBAND
      }
      case 'btnSendToBlacklist': {
        return BTN_SEND_TO_BLACKLIST
      }
      case 'btnCancel': {
        return BTN_CANCEL
      }
      case 'default':
      default:
        return BG_BUTTON
    }
  }

  return (
    <ButtonStyled className={classNames}>
      <div className={`btn-container-${disabled || isLoading ? 'disable' : ''}`} onClick={handleClick}>
        <img src={checkBtn()} alt='' className='background-btn' width={width} height={height} />
        <span className='btn-name'>
          {isLoading && <Spin className='mt-2 mr-3' />}
          {buttonName}
        </span>
      </div>
    </ButtonStyled>
  )
}

export default ButtonTable
