/* eslint-disable @typescript-eslint/no-explicit-any */
import raceApi from 'apis/raceApi'
import { RaceTable, SwitchBtn } from 'features/Race/components'
import { useAppSelector, useDebounce, useToggle, useUpdateEffect } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import {
  GetRaceListParams,
  GetRaceListPopupParams,
  Race,
  RaceClassNumber,
  RaceStatus,
  RaceWaiting,
  RecordRace,
  RegionFilters,
  RegionOption,
  RegionOptionFilter
} from 'models'
import { ChangeEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { OneLineTitle, SearchInput } from 'shared'
import { WS_MANAGER } from 'socket/socketClient'
import { schedulingListColumns } from 'utils/columns'
import { handleAsyncRequest } from 'utils/helper'
import ScheduledRacesStyled from './styled'
import { convertFullDateLocal } from 'utils/time'
import { NUMBER, STRING, STRING_REGION } from 'apps/constants'
import Select from 'shared/Select'

const { SCHEDULING, WAITING, CLOSED, LIVE } = RaceStatus

const defaultParams: GetRaceListParams = {
  limit: NUMBER.TWENTY,
  page: NUMBER.ONE,
  status: SCHEDULING,
  freeRace: false,
  myHorse: false,
  search: ''
}

const defaultRegionFilters: RegionFilters = [
  {
    name: 'All',
    isActive: true
  },
  {
    name: RegionOption.TOKYO,
    isActive: false
  },
  {
    name: RegionOption.SEOUL,
    isActive: false
  }
]

function ScheduledRaces() {
  const [searchParams, setSearchParams] = useSearchParams()
  const paramsSearch = Object.fromEntries(searchParams.entries())
  const [params, setParams] = useState<GetRaceListParams>({ ...defaultParams })
  const [searchValue, setSearchValue] = useState<string>('')
  const [isMyHorseOn, toggleIsMyHorseOn] = useToggle(false)
  const debounceSearchValue = useDebounce<string>(searchValue, 500)
  const [raceNew, setRaceNew] = useState<any[]>([])
  const [raceClose, setRaceClose] = useState<any[]>([])
  const [raceWaiting, setRaceWaiting] = useState<RaceWaiting>()
  const [raceLive, toggleRaceLive] = useToggle(false)
  const [raceList, setRaceList] = useState<any[]>([])
  const [loader, setLoader] = useState(true)
  const auth = useAppSelector(state => state.auth)
  const { t } = useTranslation()
  const useRefSwitch = useRef<HTMLButtonElement>(null)
  const memoizedSchedulingListColumns = useMemo(() => schedulingListColumns, [])
  const [regionFilters, setRegionFilters] = useState<RegionFilters>(defaultRegionFilters)
  const [regionSelect, setRegionSelect] = useState(paramsSearch?.region || 'Region')

  useLayoutEffect(() => {
    const handleLoadingRaces = () => {
      if (raceList?.length < NUMBER.TWENTY && raceList?.length > NUMBER.ZERO) {
        setLoader(true)
      }
    }

    handleLoadingRaces()
  })

  const checkSpace = (name: any) => {
    return name?.includes('   ')
  }

  const fetchListRaces = async () => {
    const refSwitch = useRefSwitch.current && useRefSwitch.current?.className
    const isRefSwitch = refSwitch?.includes('switch-btn--on')
    setLoader(false)
    const [, result] = await handleAsyncRequest(
      raceApi.getRaceList({ ...params, myHorse: isRefSwitch || false, search: searchValue })
    )
    const records = result?.data.records?.filter(race => !checkSpace(race.name) === true)

    if (records && (searchValue.length > NUMBER.ZERO || isMyHorseOn)) {
      setRaceList(sortListRaces(records))
      setLoader(true)
      return
    }
    if (records && records.length > NUMBER.ZERO) {
      // isRefresh ? setRaces([...records]) : setRaces([...races, ...records])
      setRaceList(sortListRaces([...raceList, ...records]))
    }
    setLoader(true)
  }
  const fetchRaceDetails = async (raceId: string, isWaiting = false) => {
    setLoader(false)
    const [, result] = await handleAsyncRequest(raceApi.getRaceDetails(raceId))
    const raceDetail = result?.data

    if (!raceDetail) return

    if (isWaiting) {
      setRaceWaiting({ startAt: raceDetail?.start_at, id: raceDetail.id })
    } else {
      setRaceNew([
        {
          ...raceDetail,
          racing_class_name: raceDetail?.racing_class.name,
          distance: raceDetail?.distance.distance,
          field_type: raceDetail?.field_type.type
        }
      ])
    }

    setLoader(true)
  }

  useEffect(() => {
    fetchListRaces()
  }, [params])

  useUpdateEffect(() => {
    setRaceList([])
    setParams({ ...params, search: debounceSearchValue, page: NUMBER.ONE })
  }, [debounceSearchValue])

  useUpdateEffect(() => {
    setRaceList([])
    setParams({ ...params, myHorse: isMyHorseOn, page: NUMBER.ONE })
    setSearchParams({ myHorse: isMyHorseOn ? STRING.STR_1 : STRING.STR_0 })
  }, [isMyHorseOn])

  const handleSearchValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleSocketChange = (message: { body: string }) => {
    const { data } = JSON.parse(message.body)
    const { newStatus, raceId } = data[Object.keys(data)[NUMBER.ZERO]]
    if (newStatus === SCHEDULING) {
      // document.body.scrollTop = 0
      // document.documentElement.scrollTop = 0
      fetchRaceDetails(raceId)
    }
    if (newStatus === WAITING) {
      // document.body.scrollTop = 0
      // document.documentElement.scrollTop = 0
      fetchRaceDetails(raceId, true)
    }
    if (newStatus === LIVE) {
      // document.body.scrollTop = 0
      // document.documentElement.scrollTop = 0
      toggleRaceLive()
    }
    if (newStatus === CLOSED) {
      setRaceClose([raceId])
    }
  }

  const sortListRaces = (myArray: Race[] | RecordRace[]) => {
    if (myArray?.length === NUMBER.ZERO) return []
    // return myArray?.sort((firstItem: any, secondItem: any): any => firstItem.real_start_at - secondItem.real_start_at);

    const sortedAsc = myArray?.sort((firstItem: Race | RecordRace, secondItem: Race | RecordRace) => {
      if (firstItem?.start_at === null) {
        return NUMBER.ONE
      }

      if (secondItem.start_at === null) {
        return -NUMBER.ONE
      }

      if (firstItem.start_at === secondItem.start_at) {
        return NUMBER.ZERO
      }

      return firstItem.start_at < secondItem.start_at ? -NUMBER.ONE : NUMBER.ONE
    })
    return sortedAsc
  }

  useEffect(() => {
    setRaceList(sortListRaces([...raceList]))
  }, [raceLive])

  useEffect(() => {
    setRaceList(sortListRaces([...raceList].filter(item => !raceClose.includes(item.id))))
  }, [raceClose])

  useEffect(() => {
    if (isMyHorseOn || searchValue.length > NUMBER.ZERO) return
    setRaceList(sortListRaces([...raceList, ...raceNew]))
  }, [raceNew])

  useEffect(() => {
    const items = [...raceList]
    const index = items.findIndex(obj => {
      return obj?.id === raceWaiting?.id
    })
    if (index !== -NUMBER.ONE) {
      items[index].status = WAITING
      items[index].start_at = raceWaiting?.startAt
    }
    setRaceList(sortListRaces(items))
  }, [raceWaiting])

  useEffect(() => {
    const subscription = WS_MANAGER.subscribe('/topic/race-status', handleSocketChange)
    return () => {
      subscription?.then(sub => sub?.unsubscribe())
    }
  }, [])

  const date = new Date()
  const paramsFilterResult: GetRaceListPopupParams = {
    limit: NUMBER.TWENTY,
    page: NUMBER.ONE,
    startAt: convertFullDateLocal(date),
    endAt: convertFullDateLocal(date),
    startInstance: '1000',
    endInstance: '1000',
    raceClass: RaceClassNumber.Class1
    // sort: ['id-desc']
  }

  const updateRegionFilters = useCallback((regionFilters: RegionFilters, clickedFilter: RegionOptionFilter) => {
    const clonedRegionFilters = [...regionFilters]

    clonedRegionFilters.forEach(filter => {
      if (filter.name === clickedFilter.name) {
        filter.isActive = true
        setRegionSelect(filter.name)
      } else {
        filter.isActive = false
      }
    })

    return clonedRegionFilters
  }, [])

  const updateParamsAfterRegionClicked = useCallback((params: GetRaceListParams, clickedFilter: RegionOptionFilter) => {
    const clonedParams = { ...params, page: 1 }

    if (clickedFilter.name !== 'All') {
      if (clickedFilter.name === STRING_REGION.Tokyo) {
        clonedParams.region = STRING_REGION.TOKYO
      } else {
        clonedParams.region = STRING_REGION.SEOUL
      }
    } else {
      delete clonedParams.region
    }

    return clonedParams
  }, [])

  const handleRegionFilterClicked = useCallback(
    (filterName: string) => () => {
      const clickedFilter = regionFilters.find(filter => filter.name === filterName)

      if (!clickedFilter) return

      const newRegionFilters = updateRegionFilters(regionFilters, clickedFilter)
      const newParams = updateParamsAfterRegionClicked(params, clickedFilter)

      setRaceList([])
      setRegionFilters(newRegionFilters)
      setParams(newParams)
    },
    [regionFilters, params]
  )

  return (
    <ScheduledRacesStyled>
      <div className='head-container'>
        <OneLineTitle text='scheduled races' customClass='title' />
      </div>
      <div className='search-container d-flex flex-column flex-sm-row align-items-sm-center'>
        <SearchInput searchValue={searchValue} handleSearchValueChanged={handleSearchValueChanged} />
        {auth.isLogged && (
          <SwitchBtn
            title={t(`${NOTIFICATION_MESSAGE}.myHorse`)}
            isOn={isMyHorseOn}
            handleSwitchBtnClicked={toggleIsMyHorseOn}
            refSwitch={useRefSwitch}
          />
        )}
      </div>
      <div className='content-container'>
        <RaceTable
          columns={memoizedSchedulingListColumns}
          data={raceList ?? []}
          isRowClickable
          raisePage={setParams}
          loader={loader}
          params={params}
          paramsFilterResult={paramsFilterResult}
          totalRace={raceList?.length}
        />
      </div>
    </ScheduledRacesStyled>
  )
}

export default ScheduledRaces
