/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { GUILD_MESSAGE } from 'i18n/constants'
import guildApi from 'apis/guildApi'
import ModalManagerMember from 'features/Guild/components/ModalManagerMember'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from 'shared/Button'
import { handleAsyncRequest } from 'utils/helper'
import { STRING, NUMBER } from 'apps/constants'
import BtnApplyContainer from './styled'

const BtnCancelHorseBlacklist = ({ callBackSuccess }: any) => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()

  const { guild_id, horse_id } = useParams()

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: NUMBER.FOUR
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === STRING.ERROR ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const _handleToggleModal = () => {
    setShowModal(!showModal)
  }

  const _handleUpgrade = async () => {
    setIsLoading(true)

    const [error, result]: any = await handleAsyncRequest(
      guildApi.putCancelHorseBlacklist(Number(guild_id), Number(horse_id))
    )
    setIsLoading(false)
    if (error) {
      openNotification(STRING.ERROR, error.message || error.errors[NUMBER.ZERO]?.message)
    }

    if (result) {
      _handleToggleModal()
      callBackSuccess()
      openNotification(STRING.SUCCESS, t(`${GUILD_MESSAGE}.message.cancel_success`))
    }
  }

  return (
    <BtnApplyContainer>
      {contextHolder}
      <Button
        buttonName={t(`${GUILD_MESSAGE}.btn_cancel`)}
        onClickButton={_handleToggleModal}
        type='btnCancel'
        height={42}
        width={140}
      />
      {showModal && (
        <ModalManagerMember
          title={t(`${GUILD_MESSAGE}.title_modal_cancel_horse`)}
          message={
            <p>
              {t(`${GUILD_MESSAGE}.content_modal_cancel_horse`)}
              <br />
              {t(`${GUILD_MESSAGE}.content_modal_cancel_horse2`)}
            </p>
          }
          onClose={_handleToggleModal}
          onConfirm={_handleUpgrade}
          loading={isLoading}
          titleColor='rgb(78, 240, 118)'
          width={560}
          heigth={240}
        />
      )}
    </BtnApplyContainer>
  )
}

export default BtnCancelHorseBlacklist
