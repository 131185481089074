/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

interface paramStyleProps {
  widthM?: any
  heightM?: any
}

const ModalConfirmCreateGuildContainer = styled.div<paramStyleProps>`
  width: ${({ widthM }) => widthM + 'px'};
  height: ${({ heightM }) => heightM + 'px'};
  position: relative;
  background-color: #7a83a6;
  -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);

  &:before {
    content: '';
    position: absolute;
    width: ${({ widthM }) => widthM - 2 + 'px'};
    height: ${({ heightM }) => heightM - 2 + 'px'};
    top: 1px;
    left: 1px;
    background-color: #191d2c;
    display: block;
    -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
    clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  }

  .modal__container {
    object-fit: cover;

    .header {
      margin-bottom: 16px;
      p {
        text-align: center;
        padding-top: 20px;
        position: relative;

        &:after {
          content: '';
          width: 80%;
          height: 1px;
          background: #5e6684;
          bottom: -4px;
          left: 10%;
          position: absolute;
        }

        .title {
          display: inline-block;
          width: fit-content;
          color: #4ef076;
          font-size: 24px;
          line-height: 24px;
        }
      }

      .line__under--title {
        display: block;
        width: 70%;
        margin: 0 auto;
        border-bottom: 1px solid #7a83a6;
      }
    }

    .close-btn {
      top: 10px;
      right: 10px;
      background-color: ${({ theme }) => theme.color.transparent};
      border: none;
      z-index: 1;
    }

    .content-box {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-top: 30px;
      margin-bottom: 20px;

      p {
        color: #fff;
        font-size: 20px;
        text-align: center;
      }
    }

    .error {
      position: relative;
      width: 100%;
      min-height: 20px;
      text-align: center;
      color: #ff0000;
    }

    .button-box {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 20px 0 0;
    }
  }
`

export { ModalConfirmCreateGuildContainer }
