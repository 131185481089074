import styled from 'styled-components'

const SpecialRaceStyle = styled.div`
  padding-top: 45px;

  .container {
    .title-tabs-container {
      margin-bottom: 12px;
      width: 100%;
      display: flex;
      justify-content: center;
      ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
        overflow-x: scroll;
      }
      /* height: 70px; */

      .title-tabs {
        column-gap: 10px;

        ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
          width: fit-content;
        }
        font-size: 32px;
        .tab-link {
          ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
            font-size: 26px;
          }

          ${({ theme }) => theme.media.lessThan(theme.size.md)} {
            font-size: 20px;
            line-height: 20px;
          }

          ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
            min-width: 160px;
            width: auto;
          }
        }
      }
    }
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .guild-info {
    .head-guild {
      width: 575px;
      height: 50px;
      background: linear-gradient(
        90deg,
        rgba(18, 21, 32, 0) 0%,
        rgba(78, 242, 125, 0.4) 51.04%,
        rgba(18, 22, 33, 0) 100%
      );
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 30px;
      margin-top: 20px;

      .contain_img {
        width: 46px;
        height: 46px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }

      span {
        color: #fff;
        font-size: 20px;
        display: inline-block;
        padding: 0 3px;

        &:first-child {
          padding-left: 20px;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .container__horse_farm {
      max-width: 1260px;
    }
  }

  @media (min-width: 992px) {
    .container__horse_farm {
      max-width: 1260px;
    }
  }
`

export default SpecialRaceStyle
