import styled from 'styled-components'

const AvatarBoxContainer = styled.div`
  position: relative;
  height: 260px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 20px;
  padding-top: 10px;

  .left-bg {
    position: absolute;
    top: 12px;
    left: -25px;
    height: 260px;

    img {
      height: 100%;
    }
  }

  .bottom-bg {
    position: absolute;
    width: 352px;
    bottom: -35px;
    left: -10px;
    width: 352px;

    img {
      width: 100%;
    }
  }

  .avatar-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .tag,
    .level {
      font-size: 18px;
    }

    .avatar-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &.active {
        .tag {
          color: #4ef282;
        }
      }
    }
    .btn-setting {
      font-size: 20px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #bebebe;
      cursor: pointer;
    }
  }
`

export default AvatarBoxContainer
