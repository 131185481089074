import styled from 'styled-components'

const TwoLineTitleStyled = styled.div`
  text-align: center;
  background: ${({ theme }) => theme.color.primary};
  color: transparent;
  background-clip: text;
  .text {
    text-transform: uppercase;
  }

  .line {
    position: relative;
    top: -6px;
    width: 100%;
  }

  .line__create {
    position: relative;
    top: -30px;
    width: 100%;
  }
`

export default TwoLineTitleStyled
