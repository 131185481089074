/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import Button from 'shared/Button'
import { notification } from 'antd'
import { useDispatch } from 'react-redux'

import ModalConfirmLeaveGuild from 'features/Guild/components/ModalConfirmLeaveGuild'
import { handleAsyncRequest } from 'utils/helper'
import guildApi from 'apis/guildApi'
import { useAppSelector } from 'hooks'
import { setCurrentUser, resetGuildId } from 'features/Profile/profile.slice'

import LeaveGuildContainer from './styled'

const LeaveGuild = ({ onLeaveGuildSuccess }: any) => {
  const dispatch = useDispatch()
  const [showModalLeaveGuild, setShowModalLeaveGuild] = useState<boolean>(false)
  const [loadingLeave, setLoadingLeave] = useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()
  const { profile } = useAppSelector(state => state)

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const handleToggleModal = () => {
    setShowModalLeaveGuild(!showModalLeaveGuild)
  }

  const handleLeave = async () => {
    setLoadingLeave(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.userLeaveGuild(profile?.guild_id))

    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      handleToggleModal()
      dispatch(setCurrentUser({ ...profile, guild_id: 0 }))
      dispatch(resetGuildId())
      onLeaveGuildSuccess()
    }
    setLoadingLeave(false)
  }

  return (
    <LeaveGuildContainer>
      {contextHolder}
      <Button buttonName='Leave guild' onClickButton={handleToggleModal} type='btnCancel' width={147} />
      {showModalLeaveGuild && (
        <ModalConfirmLeaveGuild onClose={handleToggleModal} onConfirm={handleLeave} loading={loadingLeave} />
      )}
    </LeaveGuildContainer>
  )
}

export default LeaveGuild
