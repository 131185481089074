import styled from 'styled-components'

const ChooseHorseModalStyled = styled.div`
  .choose-horse-modal {
    background-color: ${({ theme }) => theme.color.neutral};
    clip-path: polygon(0 0, 100% 0, 100% 100%, 40px 100%, 0 calc(100% - 40px));
    padding: 32px;
    position: relative;
    min-height: 300px;
    min-width: 770px;
    .close-btn {
      top: 10px;
      right: 10px;
      background-color: ${({ theme }) => theme.color.transparent};
      border: none;
      z-index: 1;
    }

    .race-name-container {
      margin-bottom: 16px;

      .race-name {
        font-size: 24px;
        line-height: 20px;
      }
      .gate-no {
        border-radius: 4px;
        font-size: 18px;
        padding: 2px 10px;
        &.no-1 {
          background: #301934;
          color: #ffffff;
        }
        &.no-2 {
          background: #46295f;
          color: #ffffff;
        }
        &.no-3 {
          background: #592f75;
          color: #ffffff;
        }
        &.no-4 {
          background: #3a4476;
          color: #ffffff;
        }
        &.no-5 {
          background: #9e3c6d;
          color: #ffffff;
        }
        &.no-6 {
          background: #3b6f5c;
          color: #ffffff;
        }
        &.no-7 {
          background: #b54b4b;
          color: #ffffff;
        }
        &.no-8 {
          background: #806a45;
          color: #ffffff;
        }
        &.no-9 {
          background: #c17d22;
          color: #ffffff;
        }
        &.no-10 {
          background: #b06040;
          color: #ffffff;
        }
        &.no-11 {
          background: #722c72;
          color: #ffffff;
        }
        &.no-12 {
          background: #5c3086;
          color: #ffffff;
        }
      }
    }

    .race-info-container {
      gap: 32px;
      padding-bottom: 25px;
      border-bottom: 2px solid ${({ theme }) => theme.color.neutralBackground};

      .race-info-item {
        gap: 8px;

        .race-info-title {
          font-size: 14px;
          line-height: 14px;
        }

        .race-info-content {
          font-size: 16px;
          line-height: 19px;

          img {
            width: 15px;
            height: 15px;
            margin-top: -5px;
            margin-left: 3px;
          }
        }
      }
    }

    .search-horse-container {
      margin: 21px 0 16px 0;
      .search-title {
        font-size: 16px;
        line-height: 24px;
      }

      .search-input {
        background-color: ${({ theme }) => theme.color.neutralBackground};
        opacity: 0.85;
        width: 240px;
        font-size: 16px;
      }

      .filter-type {
        height: 36px;
      }
    }
    .no-horse {
      color: #a3a5ab;
      font-size: 18px;
      padding-top: 20px;
    }

    .horse-list-container {
      max-height: 450px;
      /* overflow-y: scroll; */
      gap: 16px;
      min-height: 180px;
    }

    .horse-list-container-clone {
      max-height: 450px;
      gap: 16px;
    }

    .infinite-scroll-component::-webkit-scrollbar-track {
      border: 1px solid #3a3238;
      padding: 2px 0;
      background-color: #3a3238;
    }

    .infinite-scroll-component::-webkit-scrollbar {
      width: 5px;
    }

    .infinite-scroll-component::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #8a7b9a;
      border: 1px solid #4b2c5e;
    }
    .race-type {
      padding: 0 0 6px 20px;
    }

    .box_tokyo {
      font-weight: 600;
      background-color: #ff1f6a;
      width: 80px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
    }

    .box_seoul {
      font-weight: 600;
      background-color: #1ecfcf;
      width: 80px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
    }

    .box_galaxy_road {
      font-weight: 600;
      background-color: #e6a01f;
      width: 120px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1px;
    }
  }
`

export default ChooseHorseModalStyled
