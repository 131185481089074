/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

interface Props {
  fontSize?: any
}

const TwoLineTitleStyled = styled.div<Props>`
  background-color: transparent;
  text-align: center;
  color: #4bf296;
  font-size: ${p => `${p.fontSize}px`};
  .text {
    text-transform: uppercase;
  }

  .line {
    position: relative;
    top: -6px;
    width: 100%;
  }

  .line__create {
    position: relative;
    top: -15px;
    width: 70%;
  }
`

export default TwoLineTitleStyled
