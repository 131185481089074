/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import guildApi from 'apis/guildApi'
import horseApi from 'apis/horseApi'
import lendingApi from 'apis/lending'
import userApi from 'apis/userApi'
import { configs, links } from 'apps'
import { CURRENCY_TYPE, STATUS_TRANSACTION, TIME_GUILD_CONFIGS, timeCheckNonce } from 'apps/constants'
import { CLOSE_BTN } from 'assets/images'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import InProgressBalanceModal from 'features/Balance/components/InProgress'
import LendingTable from 'features/Guild/components/LendingTable'
import ModalConfirmGuildCommon from 'features/Guild/components/ModalConfirmGuildCommon'
import { RequestLoginModal } from 'features/Race/components'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import SkeletonDetail from 'features/components/SkeletonDetail'
import { useAppDispatch, useAppSelector, useToggle } from 'hooks'
import { GUILD_MESSAGE, LENDING_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import guildFarm from 'json/GuildFarm.json'
import _ from 'lodash'
import {
  ApiResponse,
  GUILD_STATUS,
  GuildHorseInfo,
  GuildInforManager,
  GuildPriceConfigs,
  LENDING_TYPE,
  LendingHorse,
  LendingHorseMarket,
  LendingPriceConfigs,
  MODAL_TYPE,
  TypeModal,
  notificationTemplate
} from 'models'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal } from 'shared'
import Button from 'shared/Button'
import { convertGasPrice, handleAsyncRequest } from 'utils/helper'
import openNotification from 'utils/toast'
import HorseBodyInfo from '../../../Horse/components/HorseBodyInfoGuild'
import Loading from '../Loading'
import HorseAvatar from './HorseAvatar'
import { convertFullLongDate } from 'utils/time'
import VipIcon from 'features/components/VipIcon'
import HorseDetailStyled, { ConfirmBorrowStyle } from './styled'

const contract = {
  horseNFT: configs.horseNFT,
  horseFarm: configs.horseFarm,
  transporter: configs.transporter,
  guildFarm: configs.guildFarm
}

interface IModalCommonProps {
  onOverlayClick?: () => void
  onRefresh?: () => void
  typeBtnClick?: string
}

function HorseDetail({ onOverlayClick, onRefresh, typeBtnClick }: IModalCommonProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { horse_id } = useParams()
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const auth = useAppSelector(state => state.auth)

  const [horse, setHorse] = useState<LendingHorse>()
  const [lendingInfo, setLendingInfo] = useState<GuildHorseInfo>()
  const [lendingHorseInfo, setLendingHorseInfo] = useState<GuildPriceConfigs[]>(TIME_GUILD_CONFIGS)
  const [guildInfor, setGuildInfor] = useState<GuildInforManager>()

  const [isLoading, setIsLoading] = useToggle(false)
  const [resultWithdrawHorse, setResultWithdrawHorse] = useState<any>()
  const [openConfirmWithDrawModal, toggleOpenConfirmWithDrawModal] = useToggle(false)

  const [openInProgressBalanceModal, toggleOpenInProgressBalanceModal] = useToggle(false)
  const [openNotificationModal, toggleOpenNotificationModal] = useToggle(false)
  const [openNotificationHorseDisableModal, toggleOpenNotificationHorseDisableModal] = useToggle(false)
  const [message, setMessage] = useState<notificationTemplate>()

  const [isSendToMarketModal, togggleIsSendToMarketModal] = useToggle(false)
  const [isBorrowModal, toggleIsBorrowModal] = useToggle(false)
  const [isCancelLendingModal, toggleIsCancelLendingModal] = useToggle(false)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [isHorseInRace, toggleIsHorseInRace] = useToggle(false)

  const [lendingType, setLendingType] = useState('')
  const [isDisable, toggleIsDisable] = useToggle(false)
  const [value, setValue] = useState<LendingPriceConfigs>()
  const [btnLoading, setBtnLoading] = useToggle(false)
  const [messageError, setMessageError] = useState('')
  const horseBorrowedRef = useRef(false)

  useEffect(() => {
    fetchHorse()
  }, [horse_id])

  useEffect(() => {
    if (!value && !lendingHorseInfo?.some(value => value.active === true)) {
      setValue(undefined)
    }

    if (value && parseInt(value?.day) >= 0) {
      toggleIsDisable(false)
      return
    }
    toggleIsDisable(true)
  }, [value])

  const fetchHorse = async () => {
    setIsLoading(true)
    const [error, horseResponse]: any = await handleAsyncRequest<ApiResponse<LendingHorseMarket>>(
      guildApi.getHorseGuildDetails(horse_id || '')
    )
    if (error) {
      if (error.code === 400) {
        setMessage?.({
          body: error.message,
          title: MODAL_TYPE.failed
        })
      }
      toggleOpenNotificationHorseDisableModal?.(true)
    }
    if (horseResponse) {
      const fetchedHorse = horseResponse.data
      if (typeBtnClick !== fetchedHorse?.lending_info?.lending_status) {
        pushNotification(
          typeBtnClick === GUILD_STATUS.GuildFarm
            ? t(`${GUILD_MESSAGE}.message.LendingErr`)
            : t(`${GUILD_MESSAGE}.message.borrowErr`)
        )
        onOverlayClick?.()
        onRefresh?.()
        return
      }
      setHorse({ ...fetchedHorse.horse, career: fetchedHorse?.lending_info?.career })
      setLendingInfo(fetchedHorse?.lending_info)
      setGuildInfor(fetchedHorse.guild_info)
      const priceConfigs = fetchedHorse?.lending_info?.price_configs
      const sortPriceConfigs = priceConfigs?.sort((prev: any, cur: any) => prev.day - cur.day)
      const activeFirstDay =
        !_.isEmpty(sortPriceConfigs) &&
        sortPriceConfigs.map((v: any, index: number) => {
          if (index === 0) {
            setValue({ ...v, active: true })
            return { ...v, active: true }
          } else {
            return { ...v, active: false }
          }
        })
      setLendingHorseInfo(priceConfigs ? activeFirstDay : TIME_GUILD_CONFIGS)
    }
    setIsLoading(false)
  }

  const checkNonceWithDrawContract = async (nonce: string) => {
    let checkNonceExits = null as any
    let x = 0
    const intervalID = setInterval(async () => {
      const [, result] = await handleAsyncRequest(lendingApi.postCheckNonce({ nonce }))
      if (!result) return
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        pushNotification(t(`${GUILD_MESSAGE}.successTransaction`), true)
        navigate(links.guild.yourHorse())
      }

      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        setMessage?.({
          body: t(`${LENDING_MESSAGE}.failedTransaction`),
          title: MODAL_TYPE.failed
        })
        toggleOpenNotificationModal?.(true)
      }

      if (++x === 10) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          clearInterval(intervalID)
          toggleOpenInProgressBalanceModal(false)
          setMessage?.({
            body: t(`${NOTIFICATION_MESSAGE}.transferredPending`),
            title: 'warning'
          })
          toggleOpenNotificationModal?.(true)
        }
      }
    }, timeCheckNonce)
  }

  // const onShowModalCancelLending = () => {
  //   setMessage({ body: t(`${LENDING_MESSAGE}.cancelLendingConfirm`), title: 'Cancel lending' })
  //   toggleIsCancelLendingModal(true)
  // }

  // const handleBtnLendingClick = () => {
  //   setLendingShow(true)
  //   setLendingHorseInfo(TIME_GUILD_CONFIGS)
  // }

  const hanldeChangeTable = (item: any, type: string) => {
    if (type === 'checked') {
      const newState = lendingHorseInfo.map(obj => {
        if (lendingInfo?.lending_status !== GUILD_STATUS.GuildFarm) {
          if (obj.day === item.day) {
            if (obj.active === true) {
              return { ...obj, active: obj.active }
            }
            setValue(item)
            return { ...obj, active: !obj.active }
          }
        } else if (obj.id === item.id) {
          setValue(item)
          return { ...obj, active: !obj.active }
        }
        if (lendingInfo?.lending_status === GUILD_STATUS.GuildFarm) {
          return { ...obj }
        } else return { ...obj, active: false }
      })
      setLendingHorseInfo(newState)
    }
    if (type === 'day') {
      const newState = lendingHorseInfo.map(obj => {
        if (obj.id == item.target.id) {
          return { ...obj, day: item.target.value }
        }
        return { ...obj }
      })
      setLendingHorseInfo(newState)
    }
  }

  const handleParamsPriceConfigs = lendingHorseInfo.reduce((prev: any, cur) => {
    const { day, active } = cur
    if (active === true) {
      prev.push({ day })
    }
    return prev
  }, [])

  const handleSendToMarket = async () => {
    setBtnLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      guildApi.postSendToGuild(horse_id || '', {
        private_type: lendingType === LENDING_TYPE.Private ? true : false,
        price_configs: handleParamsPriceConfigs
      })
    )
    setBtnLoading(false)
    if (error) {
      togggleIsSendToMarketModal(false)
      toggleOpenNotificationModal(true)
      setMessage({ body: error.code ? error.message : error?.errors[0]?.message, title: MODAL_TYPE.failed })
    }
    if (result) {
      togggleIsSendToMarketModal(false)
      pushNotification(t(`${LENDING_MESSAGE}.successRentalHorse`), true)
      // fetchHorse()
      onRefresh?.()
      onOverlayClick?.()
      setLendingType(LENDING_TYPE.Available)
    }
  }

  const handleConfirmSendToMarket = () => {
    const dayArr = handleParamsPriceConfigs.map((item: any) => {
      return parseInt(item.day)
    })
    const isZero = dayArr.some((item: string) => {
      if (+item <= 0 || +item >= 365) {
        return true
      } else return false
    })
    const isNull = dayArr.some((item: string) => {
      if (!item) {
        return true
      }
    })
    const isDuplicate = dayArr.some((item: string, idx: number) => {
      return dayArr.indexOf(item) != idx
    })
    if (isNull) {
      setMessageError(t(`${LENDING_MESSAGE}.enterHorseRentalTime`))
      return
    }
    if (isZero) {
      setMessageError(t(`${LENDING_MESSAGE}.enterFrom1to364`))
      return
    }
    if (isDuplicate) {
      setMessageError(t(`${LENDING_MESSAGE}.enterDatesCannotBeSame`))
      return
    }

    togggleIsSendToMarketModal(true)
    setMessageError('')
  }

  const onShowModalBorrow = () => {
    if (!auth.isLogged) {
      toggleIsRequestLoginModalOpen(true)
      return
    }
    toggleIsBorrowModal(true)
    setMessage({ title: 'Borrowing' })
  }

  const fetchCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return
    dispatch(setCoinUser(resultCoinUser?.data))
  }

  const handleHorseRent = async () => {
    setBtnLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      guildApi.postHorseRentGuild(horse_id || '', {
        day: value?.day
      })
    )
    setBtnLoading(false)
    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen(true)
        return
      }
      if (
        error?.code === 400 &&
        error?.message === 'Horse do not exist in the market. Please choose another horse to rent.'
      ) {
        horseBorrowedRef.current = true
      }
      setMessage({ body: error?.message, title: MODAL_TYPE.failed })
      toggleOpenNotificationModal(true)
    }
    if (result) {
      // borrow success
      fetchCoinUser()
      pushNotification(t(`${LENDING_MESSAGE}.horseJoinRaceConfirm`), true)
      onRefresh?.()
      onOverlayClick?.()
    }
    toggleIsBorrowModal(false)
  }

  const handleCloseConfirmOkModal = () => {
    toggleOpenNotificationModal(false)
    if (horseBorrowedRef.current) {
      fetchCoinUser()
      onRefresh?.()
      onOverlayClick?.()
    }
  }

  const checkHorseIsInRace = async () => {
    setBtnLoading(true)
    const [, result]: any = await handleAsyncRequest(horseApi.getHorseIsInRace(horse_id || '', { statuses: 'OPEN' }))
    setBtnLoading(false)
    if (result?.data?.is_in_race) {
      toggleIsHorseInRace(true)
      setMessage({ body: t(`${LENDING_MESSAGE}.successRentedHorse`), title: MODAL_TYPE.warning })
      toggleIsCancelLendingModal(false)
      return
    } else {
      handleHorseBackToFarm()
    }
  }

  const handleHorseBackToFarm = async () => {
    setBtnLoading(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.postHorseBackToFarm(horse_id || ''))
    setBtnLoading(false)
    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen(true)
      } else {
        toggleOpenNotificationModal(true)
        setMessage({ body: error?.message, title: MODAL_TYPE.failed })
      }
    }
    if (result) {
      pushNotification(t(`${LENDING_MESSAGE}.successCancelLending`), true)
      fetchHorse()
    }
    toggleIsCancelLendingModal(false)
    toggleIsHorseInRace(false)
  }

  const pushNotification = (description: string, isSuccess?: boolean) => {
    openNotification({
      message: '',
      description: description,
      className: isSuccess ? 'toast-success' : 'toast-error'
    })
  }

  const handleBtnWithdrawClick = async () => {
    toggleOpenConfirmWithDrawModal(false)
    setBtnLoading(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.postWithrawHorse({ horse_id: [horse_id] }))
    setBtnLoading(false)
    if (error) {
      if (error.code === 400) {
        setMessage?.({
          body: error.message,
          title: MODAL_TYPE.failed
        })
      }
      toggleOpenNotificationModal(true)
    }
    if (result) {
      toggleOpenInProgressBalanceModal(true)
      setMessage({ title: 'WITHDRAW' })
      setResultWithdrawHorse(result.data)
    }
  }

  useEffect(() => {
    if (!resultWithdrawHorse) return
    handleWithDrawHorseContract()
  }, [resultWithdrawHorse])

  const handleWithDrawHorseContract = async () => {
    if (!resultWithdrawHorse) return
    try {
      const withdrawContract = new ethers.Contract(contract.guildFarm, guildFarm.contract.abi, signer)
      const coverBlockExpired = new BigNumber(resultWithdrawHorse.block_expired).toFixed()
      try {
        await withdrawContract.withdraw(
          {
            owner: resultWithdrawHorse.owner,
            horseId: resultWithdrawHorse.token_ids,
            blockExpired: coverBlockExpired,
            nonce: resultWithdrawHorse.nonce,
            v: resultWithdrawHorse.v,
            r: resultWithdrawHorse.r,
            s: resultWithdrawHorse.s
          },
          { gasPrice: convertGasPrice(resultWithdrawHorse.gas_price) }
        )
        checkNonceWithDrawContract(resultWithdrawHorse.nonce)
      } catch (err: any) {
        if (err?.code && err?.code === 4001) {
          setMessage?.({
            body: t(`${LENDING_MESSAGE}.failedTransaction`),
            title: MODAL_TYPE.failed
          })
          toggleOpenNotificationModal?.(true)
          toggleOpenInProgressBalanceModal(false)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleNavigateBack = () => {
    navigate(-1)
  }
  return (
    <Modal onOverlayClick={onOverlayClick}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className='container'>
          <HorseDetailStyled>
            <button className='close-btn p-0 position-absolute' role='button' onClick={onOverlayClick}>
              <img src={CLOSE_BTN} alt='close' />
            </button>
            {isLoading && <SkeletonDetail />}
            {horse && !isLoading && (
              <div className='horse-detail-container'>
                <div className='quick-view-left'>
                  <HorseAvatar horse={horse} lendingInfo={lendingInfo} />
                </div>
                <div className='quick-view-center'>
                  <div className='right-container color-white'>
                    <HorseBodyInfo horse={horse} isLending={true} customClass='horse-detail' />
                  </div>
                </div>

                {lendingInfo?.lending_status !== GUILD_STATUS.Available ? (
                  <div className='quick-view-right'>
                    <div className='right color-white'>
                      <div className='right-body'>
                        <LendingTable
                          timeLending={lendingHorseInfo || []}
                          lendingInfo={lendingInfo}
                          onChange={hanldeChangeTable}
                          disabledSelect={lendingInfo?.lending_status !== GUILD_STATUS.GuildFarm ? true : false}
                          messageError={messageError}
                          guildInfor={guildInfor}
                        />
                      </div>
                    </div>
                    <div className='right-bottom'>
                      {lendingInfo?.lending_status === GUILD_STATUS.GuildFarm && (
                        <Button
                          buttonName='Confirm'
                          onClickButton={handleConfirmSendToMarket}
                          isLoading={btnLoading}
                          disabled={_.isEmpty(handleParamsPriceConfigs)}
                        />
                      )}
                      {lendingInfo?.lending_status === GUILD_STATUS.OnMarket && (
                        <Button
                          buttonName='Borrow'
                          onClickButton={onShowModalBorrow}
                          disabled={isDisable}
                          isLoading={btnLoading}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}

            {openInProgressBalanceModal && <InProgressBalanceModal title={`${message?.title} IN PROGRESS`} />}
            {openNotificationModal && (
              <ConfirmOkModal
                toggleIsModalOpen={toggleOpenNotificationModal}
                onCloseButtonClick={handleCloseConfirmOkModal}
                onConfirm={toggleOpenNotificationModal}
                message={<span> {message?.body} </span>}
                title={message?.title}
              />
            )}

            {isSendToMarketModal && (
              <ModalConfirmGuildCommon
                message={t(`${GUILD_MESSAGE}.settingLending`)}
                onClose={togggleIsSendToMarketModal}
                onConfirm={handleSendToMarket}
                loading={btnLoading}
                title='Setting lending'
                // type={TypeModal.warning}
              />
            )}
            {isCancelLendingModal && (
              <ConfirmOkModal
                toggleIsModalOpen={toggleIsCancelLendingModal}
                onCloseButtonClick={toggleIsCancelLendingModal}
                onConfirm={checkHorseIsInRace}
                message={<div className='font-size-18'>{t(`${LENDING_MESSAGE}.cancelLendingConfirm`)}</div>}
                isLoading={btnLoading}
              />
            )}

            {isBorrowModal && (
              <ModalConfirmGuildCommon
                message={
                  <ConfirmBorrowStyle>
                    <div>
                      <div> {t(`${LENDING_MESSAGE}.reallyRentHorseConfirm`)}</div>
                      <div>
                        {t(`${LENDING_MESSAGE}.totalRentalDays`)} &nbsp; : &nbsp; {value?.day}{' '}
                        {value && Number(value?.day) <= 1 ? 'day' : 'days'}
                      </div>
                      {horse?.msp_details?.msp && (
                        <>
                          <div className='font-size-24 vip_expired'>
                            <div className='vip-icon'>
                              <VipIcon />
                            </div>
                            <div className='text__vip_contain'>
                              <span className='text__vip'>{t(`${LENDING_MESSAGE}.mspExpiredAt`)}</span>&nbsp; &nbsp;
                              <span>
                                {convertFullLongDate(+horse?.msp_details?.msp_expired_at * 1000 || new Date())}
                              </span>
                            </div>
                          </div>
                          <div className='text__vip text_align_center'>{t(`${LENDING_MESSAGE}.mspExpiredChange`)}</div>
                        </>
                      )}
                    </div>
                  </ConfirmBorrowStyle>
                }
                onClose={toggleIsBorrowModal}
                onConfirm={handleHorseRent}
                loading={btnLoading}
                title='BORROWING'
                type={TypeModal.warning}
                size='MD'
              />
            )}
            {openConfirmWithDrawModal && (
              <ConfirmOkModal
                toggleIsModalOpen={toggleOpenConfirmWithDrawModal}
                onCloseButtonClick={toggleOpenConfirmWithDrawModal}
                onConfirm={handleBtnWithdrawClick}
                message={
                  <div className='font-size-24  message-content'>
                    {`Metahorse Farm Service

                When farming, racehorse NFTs are transacted to the lending pool.
                A fee (${CURRENCY_TYPE.ADIL}) is required for farming.
                After farming, you will not be able to:
                ・Level up
                ・Participating in races

                Withdraw the transaction from the lending pool to your wallet
                A processing fee (${CURRENCY_TYPE.ADIL}) is also required for withdraw.

                Do you want withdraw horse from farm?`}
                  </div>
                }
              />
            )}
            {openNotificationHorseDisableModal && (
              <ConfirmOkModal
                toggleIsModalOpen={toggleOpenNotificationHorseDisableModal}
                onCloseButtonClick={handleNavigateBack}
                message={<span> {message?.body} </span>}
                title={message?.title}
              />
            )}

            {isHorseInRace && (
              <ConfirmOkModal
                toggleIsModalOpen={toggleIsHorseInRace}
                onCloseButtonClick={toggleIsHorseInRace}
                onConfirm={handleHorseBackToFarm}
                message={<span> {message?.body} </span>}
                title={message?.title}
                isLoading={btnLoading}
              />
            )}

            {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
          </HorseDetailStyled>
        </div>
      )}
    </Modal>
  )
}

export default HorseDetail
