/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse } from 'models'
import axiosClient from './axiosClient'

const tooltipInfoApi = {
  getTooltipInfo(): Promise<ApiResponse<any>> {
    const url = '/master-data/race-type'
    return axiosClient.get(url)
  }
}

export default tooltipInfoApi
