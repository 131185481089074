/* eslint-disable @typescript-eslint/no-explicit-any */
import { FLASH_ICON, PLUS_ICON } from 'assets/images'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatTime, getEnergyPercent } from 'utils/helper'
import EnergyBarStyled from './styled'
import { useParams } from 'react-router-dom'
import { AvatarEnergyItem } from 'features/Profile/components'

interface EnergyBarProps {
  maxEnergy: number | null
  currentEnergy: number | 0
  customClass?: string
  firstTime: number
  disableRecovery: boolean
  isShowPlusIcon?: boolean
  isRace?: boolean
  horse?: any
  onRefresh?: () => void
}

function EnergyBar({
  currentEnergy,
  maxEnergy,
  customClass = '',
  firstTime,
  disableRecovery = false,
  isShowPlusIcon,
  horse,
  onRefresh
}: EnergyBarProps) {
  const { t } = useTranslation()
  const [isAvatarItemModal, setAvatarItemModal] = useState(false)
  const { horse_id } = useParams()

  const currentEnergyPercent = useMemo<number>(() => getEnergyPercent(firstTime), [maxEnergy, firstTime])
  const hanldeUpEnergy = () => {
    setAvatarItemModal(true)
  }

  const handleCloseItemModal = () => {
    setAvatarItemModal(false)
  }

  return (
    <EnergyBarStyled currentEnergyPercent={currentEnergyPercent} className={customClass}>
      <div className='energy-bar d-flex'>
        <img src={FLASH_ICON} alt='' className='energy-icon position-relative' />
        <div className='energy-content d-flex flex-grow-1 flex-column'>
          <span className='energy-text color-grey'>{t(`${NOTIFICATION_MESSAGE}.energy`)}</span>
          <div className='energy-up'>
            <div className='bar flex-grow-1 position-relative' />
            {isShowPlusIcon && (
              <img
                src={PLUS_ICON}
                alt=''
                className={`energy-plus-icon ${currentEnergy === maxEnergy ? 'disabled' : ''}`}
                onClick={hanldeUpEnergy}
              />
            )}
          </div>
          {disableRecovery === true && (
            <span className='energy-text color-grey'>
              {t(`${NOTIFICATION_MESSAGE}.recovery`)}:{' '}
              <span className='energy-time color-white'>{formatTime(firstTime)}</span>
            </span>
          )}
        </div>
      </div>
      {isAvatarItemModal && (
        <AvatarEnergyItem
          onCloseButtonClick={handleCloseItemModal}
          horseId={horse ? horse?.id : horse_id}
          onRefresh={onRefresh}
        />
      )}
    </EnergyBarStyled>
  )
}

export default EnergyBar
