/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

interface Props {
  width?: any
  height?: any
  fontSize?: any
  leftText?: any
  topText?: any
  topICInfo?: any
  rigthICInfo?: any
  widthICInfo?: any
  heightICInfo?: any
}

const MspIconContainer = styled.div<Props>`
  width: ${p => `${p.width}px`};
  height: ${p => `${p.height}px`};

  .contain__img {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1px;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .img__text {
      position: absolute;
      top: ${p => `${p.topText}px`};
      bottom: 0;
      left: ${p => `${p.leftText}px`};
      right: 0;
      color: #fff;
      z-index: 7;
      font-size: ${p => `${p.fontSize}px`};
      font-weight: bold;
      font-style: italic;
      text-align: left;
    }

    .ic__info {
      position: absolute;
      top: ${p => `${p.topICInfo}px`};
      right: ${p => `${p.rigthICInfo}px`};
      width: ${p => `${p.widthICInfo}px`};
      height: ${p => `${p.heightICInfo}px`};
      z-index: 7;
      img {
        position: unset;
        top: unset;
        bottom: unset;
        left: unset;
        right: unset;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
`
export default MspIconContainer
