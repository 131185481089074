import React from 'react'
import LazyLoad from 'react-lazy-load'
import styled from 'styled-components'
import { HORSE_ARMOR_SKIN } from 'assets/images'

const ContentStyled = styled.div`
  height: 100%;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
`

const MemoizedImageHorseArmorSkin = () => {
  return (
    <ContentStyled>
      <LazyLoad>
        <img src={HORSE_ARMOR_SKIN} alt='' loading='lazy' />
      </LazyLoad>
    </ContentStyled>
  )
}
export default React.memo(MemoizedImageHorseArmorSkin)
