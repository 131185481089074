/* eslint-disable @typescript-eslint/no-explicit-any */
import fireBaseApi from 'apis/FireBaseApi'
import { paths } from 'apps'
import { TIME_NOTIFICATION } from 'apps/constants'
import firebase, { firebaseConfig } from 'apps/firebase'
import {
  AuthFeature,
  BalanceFeature,
  DisableAccountFeature,
  GuildFeature,
  HomeFeature,
  HorseFeature,
  LendingFeature,
  LoggedAccountFeature,
  MailBoxFeature,
  MaintenaceFeature,
  PageNotFoundFeature,
  ProfileFeature,
  RaceFeature,
  RankingFeature,
  ShopFeature,
  SpecialRaceFeature
} from 'features'
import Mobile from 'features/Mobile/pages/Mobile'
import { ResultHorseModal } from 'features/Race/components'
import { useAppSelector, useToggle } from 'hooks'
import { notificationTemplate } from 'models'
import { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { toast } from 'react-toastify'
import { handleAsyncRequest } from 'utils/helper'
import { BTN_CANCEL } from 'assets/images'

function GlobalRoute() {
  const auth = useAppSelector(state => state.auth)
  const [isModalNotification, toggleIsModalNotification] = useToggle(false)
  const [message, setMessage] = useState<notificationTemplate>()

  const firebaseConnect = () => {
    const subscribeFirebase = async (name: string, token: string) => {
      const [,] = await handleAsyncRequest(fireBaseApi.subscribe(name, token))
    }
    const messaging = firebase.messaging()
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken()
      })
      .then((token: any) => {
        subscribeFirebase(firebaseConfig.broadcastTopic, token)
      })

    messaging.onMessage((payload: { data: any; notification: any }) => {
      const { title, priority, body }: any = { ...payload.data, ...payload.notification }
      if (priority == 'WARNING') {
        toggleIsModalNotification(true)
        setMessage({ ...payload.data, ...payload.notification })
        return
      }
      toast(
        <div>
          <div className='font-bold font-size-20 color-orange'> {title}</div> <div className='body-content'>{body}</div>
        </div>,
        {
          position: 'top-right',
          autoClose: TIME_NOTIFICATION,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        }
      )
    })
  }

  const handleCloseModalJoinRaceOpen = () => {
    window.location.reload()
    return toggleIsModalNotification(false)
  }

  useEffect(() => {
    if (window.location.pathname === paths.mobile.feature()) return
    if (window.location.pathname === paths.maintenace.detail()) return
    // check is mobile device
    if ('ontouchstart' in window || 'onmsgesturechange' in window) return
    firebaseConnect()
  }, [auth.user_id])

  const onReloadPage = () => {
    window.location.reload()
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path={paths.home.feature()} element={<HomeFeature />} />
        <Route path={paths.race.feature()} element={<RaceFeature />} />
        <Route path={paths.auth.feature()} element={<AuthFeature />} />
        <Route path={paths.horse.feature()} element={<HorseFeature />} />
        <Route path={paths.profile.feature()} element={<ProfileFeature />} />
        <Route path={paths.market.feature()} element={<LendingFeature />} />
        <Route path={paths.balance.feature()} element={<BalanceFeature />} />
        <Route path={paths.maintenace.feature()} element={<MaintenaceFeature />} />
        <Route path={paths.disableAccount.feature()} element={<DisableAccountFeature />} />
        <Route path={paths.loggedAccount.feature()} element={<LoggedAccountFeature />} />
        <Route path={paths.notFound.feature()} element={<PageNotFoundFeature />} />
        <Route path={paths.mobile.feature()} element={<Mobile />} />
        <Route path={paths.mailbox.feature()} element={<MailBoxFeature />} />
        <Route path={paths.specialRace.feature()} element={<SpecialRaceFeature />} />
        <Route path={paths.guild.feature()} element={<GuildFeature />} />
        <Route path={paths.ranking.feature()} element={<RankingFeature />} />
        <Route path={paths.shop.feature()} element={<ShopFeature />} />
        <Route path='*' element={<Navigate replace to={paths.notFound.feature()} />} />
      </Routes>
      {isModalNotification && (
        <ResultHorseModal
          onCloseButtonClick={handleCloseModalJoinRaceOpen}
          message={
            <>
              <div className='font-bold font-size-20 color-orange text-uppercase'>{message?.title}</div>
              <div className='font-size-18'>{message?.body}</div>
              <div className='reload-page'>
                <button onClick={onReloadPage}>OK</button>
                <img src={BTN_CANCEL} alt='' />
              </div>
            </>
          }
        />
      )}
    </BrowserRouter>
  )
}

export default GlobalRoute
export { default as PrivateRoute } from './PrivateRoute'
export { default as PublicRoute } from './PublicRoute'
