/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, notification } from 'antd'
import { useState } from 'react'

import { Container, ModalContainer } from '../Armor/styled'

import Button from 'shared/Button'
// import { useParams } from 'react-router-dom'
import { handleAsyncRequest } from 'utils/helper'
import horseApi from 'apis/horseApi'
import Head from '../Head'

interface iprops {
  itemToken: number
  horseId: number
  onSuccess: () => void
  isChangeItem?: boolean
  isDisabled?: boolean
  oldToken?: any | undefined
}

const UseButton = ({ itemToken, horseId, onSuccess, isChangeItem = false, isDisabled }: iprops) => {
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  // const params: any = useParams()

  const _onToggleModal = () => {
    setShowModal(!showModal)
  }

  const _onUseItem = async () => {
    setLoading(true)

    const [error, data]: any = isChangeItem
      ? await handleAsyncRequest(horseApi.changeWeapon(itemToken, horseId))
      : await handleAsyncRequest(horseApi.addWeapon(itemToken, horseId))
    setLoading(false)

    _onToggleModal()

    if (error) {
      notification.error({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message || 'Can not use this item for this horse!',
        placement: 'bottomRight',
        duration: 4,
        className: 'ant-custom-error',
        icon: <></>
      })

      return
    }

    await notification.success({
      message: 'SUCCESS',
      description: 'This item is used for this horse!',
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })

    onSuccess()
  }

  return (
    <Container>
      <Button buttonName='Use' onClickButton={_onToggleModal} width={150} disabled={isDisabled} />
      <Modal
        open={showModal}
        onCancel={_onToggleModal}
        footer={false}
        width={615}
        className='modal-item'
        style={{ background: 'transparent' }}
        bodyStyle={{
          background: 'transparent',
          padding: 0
        }}
        centered
      >
        <ModalContainer>
          <Head name='Confirm' />
          <p className='text'>Are you sure using this item?</p>
          <div className='row button-group'>
            <Button buttonName='Cancel' onClickButton={_onToggleModal} width={130} type='btnCancel' />
            <Button buttonName='OK' onClickButton={_onUseItem} margingTop={true} width={130} isLoading={loading} />
          </div>
        </ModalContainer>
      </Modal>
    </Container>
  )
}

export default UseButton
