/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from 'antd'
import userApi from 'apis/userApi'
import { constants } from 'apps'
import { CLOSE_BTN, GAME_TOKEN_KUDA } from 'assets/images'
import RaceType from 'features/components/RaceType'
import { useDebounce, useFetch, useIsFirstRender, useIsMounted } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { defaultRegionFilters, HorseAvailable, OWNER_STATUS, Race, TypeSorts, UserAvailableHorses } from 'models'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ClassTag, Modal, SearchInput } from 'shared'
import SelectCustom from 'shared/SelectCustom'
import ChooseHorseItem from '../ChooseHorseItem'
import ChooseHorseModalStyled from './styled'
import { getBackgroundRegion } from 'utils/helper'
import { NUMBER, STRING_REGION } from 'apps/constants'

interface ChooseHorseModalProps {
  race: Race
  joiningGate: number
  toggleIsModalOpen: (value?: boolean) => void
  setTriggerFetchRaceDetail: Dispatch<SetStateAction<boolean>>
  hadJoined: boolean
  onCloseButtonClick: () => void
  onConfirmHorse: (id: number) => void
}

const defaultTypeFilter: TypeSorts = [
  {
    name: OWNER_STATUS.All,
    key: OWNER_STATUS.AllKey,
    isActive: false
  },
  {
    name: OWNER_STATUS.Owner,
    key: OWNER_STATUS.OwnerKey,
    isActive: false
  },
  {
    name: OWNER_STATUS.Lending,
    key: OWNER_STATUS.LendingKey,
    isActive: false
  },
  {
    name: OWNER_STATUS.Guild,
    key: OWNER_STATUS.GuildKey,
    isActive: false
  }
]

function ChooseHorseModal({
  race,
  toggleIsModalOpen,
  onCloseButtonClick,
  onConfirmHorse,
  hadJoined,
  joiningGate
}: ChooseHorseModalProps) {
  const defaultHorseListParams: UserAvailableHorses = {
    raceId: race.id,
    ownStatus: null,
    limit: NUMBER.TEN,
    page: NUMBER.ONE,
    keyword: ''
  }
  const horseListRef = useRef<HTMLDivElement>(null)
  const [horseListParams, setHorseListParams] = useState<UserAvailableHorses>(defaultHorseListParams)
  const [horseList, setHorseList] = useState<HorseAvailable[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const isMounted = useIsMounted()
  const isFirstRender = useIsFirstRender()
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const { t } = useTranslation()
  const [classSelect, setClassSelect] = useState('All')
  const [regionSelect, setRegionSelect] = useState('Region')

  const { data: horseListResponse, loading: isLoading } = useFetch(
    { fetcher: userApi.getUserAvailableHorses, params: horseListParams },
    [horseListParams]
  )

  useEffect(() => {
    if (isFirstRender) return
    setHorseList([])
    setHorseListParams({ ...horseListParams, page: NUMBER.ONE, keyword: debounceSearchValue })
  }, [debounceSearchValue])

  useEffect(() => {
    if (!horseListResponse || !isMounted()) {
      return
    }
    const cloneHorseListResponse = horseListResponse.records?.filter(item => item.active === true)
    const newHorseList: HorseAvailable[] = horseList.concat(cloneHorseListResponse)

    setHorseList(newHorseList)
  }, [horseListResponse])

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleChooseHorse = (horseId: number) => {
    onConfirmHorse(horseId)
    toggleIsModalOpen()
  }

  const handleKeyFilter = (status: string) => {
    switch (status) {
      case OWNER_STATUS.All:
        return null
      case OWNER_STATUS.Owner:
        return OWNER_STATUS.OwnerKey
      case OWNER_STATUS.Lending:
        return OWNER_STATUS.LendingKey
      case OWNER_STATUS.Guild:
        return OWNER_STATUS.GuildKey
      default:
        return null
    }
  }
  const handleClassFilterClicked = (item: string) => {
    setHorseList([])
    setHorseListParams({ ...horseListParams, ownStatus: handleKeyFilter(item), page: NUMBER.ONE })
    setClassSelect(item)
  }

  const handleRegionFilterClicked = (item: string) => {
    setHorseList([])
    if (item?.toUpperCase() === STRING_REGION.SEOUL || item?.toUpperCase() === STRING_REGION.TOKYO) {
      setHorseListParams({ ...horseListParams, page: NUMBER.ONE, region: item?.toUpperCase() })
    } else {
      setHorseListParams({ ...horseListParams, page: NUMBER.ONE, region: '' })
    }
    setRegionSelect(item)
  }

  const fetchMoreHorses = () => {
    setHorseListParams({ ...horseListParams, page: horseListParams.page + NUMBER.ONE })
  }

  return (
    <Modal onOverlayClick={toggleIsModalOpen}>
      <ChooseHorseModalStyled>
        <div className='choose-horse-modal'>
          <button className='close-btn p-0 position-absolute' role='button' onClick={onCloseButtonClick}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className='race-name-container d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <div className='race-name color-white font-bold text-uppercase'>{race.name}</div>
              <div className='race-class ps-2'>
                <div className='entry-fee text-uppercase font-bold display__flex'>
                  <span className='color-primary font-bold'>{race.racing_class.name === 'FreeStyle' ? 'Free Class' : race.racing_class.name }</span>
                </div>
              </div>
            </div>
            <div className='pe-3'>
              <span className={`race-info-item d-flex align-items-center gate-no no-${joiningGate}`}>
                <span className='race-info-title'>{t(`${NOTIFICATION_MESSAGE}.gateNo`)}</span>
                <span className='race-info-content ps-1'> {joiningGate}</span>
              </span>
            </div>
          </div>
          <div className='race-info-container d-flex align-items-center justify-content-between pe-3'>
            <div className='race-info-item d-flex align-items-center'>
              <span className='race-info-title color-grey'>{t(`${NOTIFICATION_MESSAGE}.racecourse`)}</span>
              <span className={`race-info-content color-white ${getBackgroundRegion(race?.course?.name)}`}>
                {race?.course?.name?.toUpperCase()}
              </span>
            </div>
            <div className='race-info-item d-flex align-items-center'>
              <span className='race-info-title color-grey'>{t(`${NOTIFICATION_MESSAGE}.fieldType`)}</span>
              <span className='race-info-content color-white'>{race.field_type.type}</span>
            </div>
            <div className='race-info-item d-flex align-items-center'>
              <span className='race-info-title color-grey'>{t(`${NOTIFICATION_MESSAGE}.distance`)}</span>
              <span className='race-info-content color-white'>{race.distance?.distance.toLocaleString()}m</span>
            </div>
            <div className='race-info-item d-flex align-items-center'>
              <span className='race-info-title color-grey'>{t(`${NOTIFICATION_MESSAGE}.entryFee`)}</span>
              {race.entry_fee === 0 ? (
                <span className='race-info-content font-bold color-primary'>{t(`${NOTIFICATION_MESSAGE}.free`)}</span>
              ) : (
                <span className='race-info-content font-bold color-kuda'>
                  {race.entry_fee}
                  <img src={GAME_TOKEN_KUDA} alt='kuda' />
                </span>
              )}
            </div>
          </div>
          {((horseList.length > 1 && !hadJoined) || searchValue.length > 0 || classSelect !== 'All') && (
            <div className='search-horse-container d-flex align-items-center justify-content-between'>
              <>
                <div className='search-title color-white'>{t(`${NOTIFICATION_MESSAGE}.selectHorseRace`)}</div>

                <SearchInput
                  onAutoFocus={true}
                  searchValue={searchValue}
                  handleSearchValueChanged={handleSearchValueChanged}
                  customClass='search-input'
                  width={180}
                />
                <SelectCustom
                  dataSelect={defaultRegionFilters}
                  nameSelect={regionSelect}
                  onSelected={handleRegionFilterClicked}
                  isFiltered
                  customClass='filter-type'
                  width={140}
                />
                <SelectCustom
                  dataSelect={defaultTypeFilter}
                  nameSelect={classSelect}
                  onSelected={handleClassFilterClicked}
                  isFiltered
                  customClass='filter-type'
                  width={140}
                />
              </>
            </div>
          )}
          {/* )} */}
          {horseList.length === 0 && !hadJoined && !searchValue && (
            <div className='search-title no-horse '>{t(`${NOTIFICATION_MESSAGE}.noHorseThisRace`)}</div>
          )}

          {horseList.length === 0 && searchValue && !isLoading && (
            <div>
              <div
                className='search-title no-horse'
                dangerouslySetInnerHTML={{
                  __html: t(`${NOTIFICATION_MESSAGE}.noHorseSearchThisRace`, { search: searchValue })
                }}
              />
            </div>
          )}

          {horseList.length > 0 && hadJoined ? (
            <div className='no-horse'>{t(`${NOTIFICATION_MESSAGE}.horseJoinedRace`)}</div>
          ) : (
            <InfiniteScroll
              dataLength={horseList.length}
              next={fetchMoreHorses}
              hasMore={true}
              loader={isLoading ? <Spin className='mt-2 mr-3 d-flex justify-content-center pt-5' /> : ''}
              height={455}
            >
              {horseList.length > 0 && (
                <div className='horse-list-container d-flex flex-column' ref={horseListRef}>
                  {horseList.map((horse, index: number) => (
                    <div key={index} className='horse-item'>
                      <>
                        <ChooseHorseItem horse={horse} onHorseClick={handleChooseHorse} />
                      </>
                    </div>
                  ))}
                </div>
              )}
            </InfiniteScroll>
          )}
        </div>
      </ChooseHorseModalStyled>
    </Modal>
  )
}

export default ChooseHorseModal
