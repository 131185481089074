/* eslint-disable @typescript-eslint/no-explicit-any */
import { STRING_WEATHER } from 'apps/constants'
import { HEAT, HEAVYRAIN, BLIZZARD, CLOUDY, RAINY, SNOWY, STORM, SUNNY } from 'assets/images'

export function getImgWeather(weather: any) {
  switch (weather) {
    case STRING_WEATHER.BLIZZARD: {
      return BLIZZARD
    }
    case STRING_WEATHER.CLOUDY: {
      return CLOUDY
    }
    case STRING_WEATHER.HEAT: {
      return HEAT
    }
    case STRING_WEATHER.HEAVY_RAIN: {
      return HEAVYRAIN
    }
    case STRING_WEATHER.RAINY: {
      return RAINY
    }
    case STRING_WEATHER.SNOWY: {
      return SNOWY
    }
    case STRING_WEATHER.STORM: {
      return STORM
    }
    case STRING_WEATHER.SUNNY: {
      return SUNNY
    }
    default:
      return
  }
}
