import { MODAL_BG } from 'assets/images'
import styled from 'styled-components'

const ModalManageMemberStyle = styled.div`
  background: url(${MODAL_BG});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 515px;
  height: 274px;
  position: relative;

  .header {
    margin-bottom: 16px;
    p {
      text-align: center;
      padding-top: 20px;

      .title {
        display: inline-block;
        width: fit-content;
        color: #4ef076;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }

  .close-btn {
    top: 10px;
    right: 10px;
    background-color: ${({ theme }) => theme.color.transparent};
    border: none;
    z-index: 1;
  }

  .content-box {
    display: flex;
    justify-content: start;
    gap: 20px;
    align-items: center;
    background-color: #0f121c;
    margin: 0px 40px;
    color: white;

    img {
      border-radius: 50%;
    }

    p {
      color: #fff;
      font-size: 20px;
      text-align: center;
    }
  }

  .button-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 40px;
  }
`

export { ModalManageMemberStyle }
