import styled from 'styled-components'

const SelectItemModalStyled = styled.div`
  .modal-select-item {
    width: 615px;
    background: #191d2c;
    padding: 40px 35px;
    position: relative;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 40px 100%, 0px calc(100% - 40px));
    .confirm-horse {
      text-align: center;
      h4 {
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        color: #4ef076;
        margin-bottom: 0;
      }
      .iframe {
        width: 212px;
      }
    }
    .confirm-horse-title {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 32px;
    }
    .close-btn {
      right: 23px;
      top: 19px;
      background: transparent;
      border: none;
    }
    .confirm-horse-btns {
      button {
        background: transparent;
        border: none;
        margin: 0 16.5px;
        position: relative;
        filter: brightness(0.8);

        span {
          position: absolute;
          width: 100%;
          margin: 0 auto;
          left: 0;
          color: #fff;
          top: 8px;
          font-size: 24px;
        }
        &:hover {
          filter: brightness(1);
        }
        &.disable {
          filter: brightness(0.5);
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }
    .err {
      color: #ff7a00;
      height: 20px;
      margin-top: 5px;
    }
    .list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .meta-block {
        margin-bottom: 10px;
        cursor: pointer;
        min-width: 170px;
        &:nth-child(4n) {
          margin-right: 0px;
        }
        &.active {
          .item-block {
            background-image: linear-gradient(#4ff077, #48f7b9);
            .sub-block {
              background-image: linear-gradient(#4ff077, #48f7b9);
            }
            .numb {
              img {
                filter: none;
              }
              span {
                color: #114d06;
              }
            }
          }
        }
      }
      .item-block {
        clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
        background: #515460;
        padding: 2px;
        width: 180px;
        margin: 0 auto;
        .sub-block {
          background: #030317;
          clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
          position: relative;
          .amount {
            position: relative;
            padding-bottom: 10px;
            justify-content: center;
            .star {
              .disable {
                display: none;
              }
            }
          }
          .numb {
            position: absolute;
            top: 5%;
            left: 5%;
            img {
              width: 38px;
            }
            span {
              position: absolute;
              left: 0;
              font-size: 15px;
              width: 100%;
              text-align: center;
              bottom: 5px;
              color: #fff566;
              font-size: 18px;
            }
          }
          .star {
            div:has(div.disable) {
              display: none;
            }
            div {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .item-dp {
        background: transparent;
        border: none;
        padding: 20px 0 10px;
        img {
          height: 112px;
        }
      }
      .count-item {
        position: relative;
        display: flex;
        margin-top: 4px;
        button {
          background: transparent;
          border: none;
          padding: 0;
          width: 41px;
          height: 41px;
          img {
            width: 41px;
          }
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        span {
          position: absolute;
          width: 100%;
          text-align: center;
          z-index: 1;
          top: 5px;
          top: 12px;
          left: 0px;
          font-size: 16px;
        }
        input {
          width: 100%;
          text-align: center;
          z-index: 1;
          top: 15px;
          left: 0px;
          background: transparent;
          color: #fff;
          border: 1px solid #fff;
          color: #fff;
          height: 37px;
          width: 180px;
          margin-top: 2px;
          &:focus-visible {
            outline: none;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
    }
    .confirm-horse-btns {
      margin-top: 25px;
    }
  }
`

export default SelectItemModalStyled
