import styled from 'styled-components'

const ButtonStyled = styled.div`
  cursor: pointer;
  column-gap: 8px;
  width: fit-content;
  .btn-container- {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      filter: brightness(1.5);
    }
    .background-btn {
      position: relative;
    }
  }

  .btn-container-disable {
    position: relative;
    filter: brightness(0.5);
  }

  .reset_horse {
    position: absolute;
    color: #ffffff;
  }
  .reset_refect {
    position: absolute;
    width: 32px;
    top: 9px;
    right: 32px;
  }

  .ant-spin-dot-item {
    background-color: #4ef076 !important;
  }
`

export default ButtonStyled
