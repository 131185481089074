import { links } from 'apps'
import { GAME_TOKEN_KUDA, VIEW_DOPING } from 'assets/images'
import SkeletonList from 'features/components/SkeletonList'
import { NOTIFICATION_MESSAGE, LENDING_MESSAGE } from 'i18n/constants'
import _ from 'lodash'
import { LENDING_STATUS, LENDING_TYPE, LendingHorse, LendingPriceConfigs } from 'models'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { convertStatusLending, handeLeaseType, handleCareer, nFormatterK, removeCharacterEnd } from 'utils/helper'
import VipIcon from 'features/components/VipIcon'
import HorseAvatar from 'features/Horse/components/HorseItem/HorseAvatar'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import HorseItemStyled, { BClassTagStyled } from './styled'
import { getImageGender, getImageRegion } from 'utils/getLinkImage'

interface IHorseItemProps {
  listHorse?: Array<LendingHorse>
  total?: number
  onViewMore?: () => void
  isLoading?: boolean
}

function HorseItem({ listHorse, total = 0, onViewMore, isLoading }: IHorseItemProps) {
  // const [isHovering, setIsHovering] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClickBtnDetail = (horse: LendingHorse) => {
    navigate(links.market.detail(horse?.horse_id))
  }

  const hanleTotalViewMore = () => {
    return total && total - ((listHorse && listHorse?.length) || 0)
  }

  const handlePriceLowest = (configs: LendingPriceConfigs[]) => {
    const priceLowest = configs.reduce((previous, current) => {
      return parseInt(current.value) < parseInt(previous.value) ? current : previous
    })
    return priceLowest.value
  }

  return (
    <HorseItemStyled>
      {isLoading ? (
        <SkeletonList />
      ) : (
        <>
          {' '}
          {total <= 0 ? (
            <div className='no-data'>{t(`${NOTIFICATION_MESSAGE}.horseNotFound`)}</div>
          ) : (
            <>
              <div className='market-container'>
                {listHorse &&
                  listHorse.map(horse => {
                    const styleFirstCount = horse?.career?.first_count
                    const styleSecondCount = horse?.career?.second_count
                    const styleThirdCount = horse?.career?.third_count
                    return (
                      <div key={horse.horse_id} className='market-item position-relative'>
                        <div className='top position-relative'>
                          <div className='top-frame position-absolute'></div>
                          <div className='bottom'></div>
                        </div>
                        <div className='horse-item'>
                          <div className='header'>
                            <div className='header-horse'>
                              <div className='header-horse-name'>
                                <div className='horse-name'>{horse.horse_name}</div>
                                <div className='horse_gender'>
                                  <MemoizedLoadImageCommon srcImg={getImageGender(horse?.horse_gender)} />
                                </div>
                              </div>
                              <div className='horse-lending'>
                                <div className='left d-flex justify-content-between'>
                                  <div className='horse-type'>{convertStatusLending(horse?.lending_status)}</div>
                                </div>
                                <div className='right'>
                                  {horse?.msp_details?.msp && (
                                    <div className='icon-vip'>
                                      <VipIcon />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='background-container'>
                            <div className=' d-flex align-items-center justify-content-center'>
                              <HorseAvatar
                                horse={horse}
                                name={horse?.name}
                                avatar={horse?.avatar}
                                items={horse?.weapon_items_equipped}
                              />
                            </div>
                          </div>

                          <div className='bottom-item'>
                            <div className='bottom_item_winrar'>
                              <div className='item_winrar'>
                                <div className='item_winrar_title'>{t(`${LENDING_MESSAGE}.winrate`)}</div>
                                <div className='item_winrar_content'>{handleCareer(horse?.career)}</div>
                              </div>
                              <div className='item_carrer'>
                                <div className='item_winrar_title'>{t(`${LENDING_MESSAGE}.career`)}</div>
                                <div className='item_winrar_content'>
                                  <span className='color__fff566'>
                                    {nFormatterK(+horse?.career?.total_number_of_races, 1)}
                                  </span>
                                  {` ${styleFirstCount}/${styleSecondCount}/${styleThirdCount}`}
                                </div>
                              </div>
                            </div>

                            <div className='bottom_item_class'>
                              <div className='bottom__class'>
                                <div className='class-title text-uppercase font-bold d-flex align-items-center justify-content-start'>
                                  <span className='color-primary font-bold'>{horse?.racing_class === 'FreeStyle' ? 'Free Class' : horse?.racing_class }</span>
                                </div>
                              </div>

                              <div className='bottom__price_lending'>
                                <div className='price__lending_type'>{handeLeaseType(horse?.lending_type)}</div>
                                {(horse.lending_status === LENDING_STATUS.Lending ||
                                  horse.lending_status === LENDING_STATUS.Borrowed) && (
                                  <div className='price-lending'>
                                    <div className='price'>
                                      {horse?.rent_days && horse?.rent_fee
                                        ? horse.rent_fee
                                        : !_.isEmpty(horse?.price_configs) && handlePriceLowest(horse?.price_configs)}
                                    </div>
                                    {horse.lending_type === LENDING_TYPE.Available ? (
                                      <div className='icon_kuda'>
                                        <MemoizedLoadImageCommon srcImg={GAME_TOKEN_KUDA} />
                                      </div>
                                    ) : (
                                      '%'
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='btn-detail-container'>
                          <button className='font-bold btn-detail' onClick={() => handleClickBtnDetail(horse)}>
                            <span className='color-light-neon'>{t(`${NOTIFICATION_MESSAGE}.details`)}</span>
                          </button>
                        </div>
                      </div>
                    )
                  })}
              </div>
              {hanleTotalViewMore() > 0 && (
                <div className='color-white view-more-container mt-2 mb-5' onClick={onViewMore}>
                  <button className='btn-view-more'>
                    <span className='btn-view-more-text'>View More {hanleTotalViewMore()} Horses </span>
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </HorseItemStyled>
  )
}

export default HorseItem
