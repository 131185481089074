/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GUILD_MESSAGE } from 'i18n/constants'

import guildApi from 'apis/guildApi'
import { ELIPSE_BG, FX_1, FX_2, LINE, LINE_YELLOW } from 'assets/images'
import { RequestLoginModal } from 'features/Race/components'
import { useAppSelector, useToggle, useAppDispatch } from 'hooks'
import { useParams } from 'react-router-dom'
import { handleAsyncRequest } from 'utils/helper'
import AvatarBox from './avatar-box'
import CareerBox from './career-box'
import InforBox from './infor-box'
import JoindGuild from './joind-guild'
import PutHorseToGuild from './put-horse-to-guild'
import InformationStyled from './styled'
import TopHorses from './top-horses'
import TopMembers from './top-members'
import TopOwners from './top-owners'
import userApi from 'apis/userApi'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import { getCurrentUser } from 'utils/metamask'
import { setCurrentUser } from 'features/Profile/profile.slice'
import { STRING_ROLE } from 'apps/constants'

export default function InformationDetail() {
  const { t } = useTranslation()
  const { guild_id } = useParams()
  const dispatch = useAppDispatch()
  const [api, contextHolder] = notification.useNotification()
  const [informationData, setInformationData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [disableBtnJoin, setDisableBtnJoin] = useState<boolean>(false)
  const [disableBtnApply, setDisableBtnApply] = useState<boolean>(false)

  const { profile } = useAppSelector(state => state)

  useEffect(() => {
    checkCurrentCoinUser()
    getInformationData()
  }, [guild_id])

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === t(`${GUILD_MESSAGE}.error`) ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const checkCurrentCoinUser = async () => {
    const [, currentUser]: any = await handleAsyncRequest(getCurrentUser())
    if (!currentUser) return

    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return

    dispatch(setCurrentUser(currentUser))
    dispatch(setCoinUser(resultCoinUser.data))
  }

  const getInformationData = async () => {
    setLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      guild_id ? guildApi.getGuildInfomationById(guild_id) : guildApi.getGuildInfomation()
    )
    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen()
        return
      }
      openNotification(t(`${GUILD_MESSAGE}.error`), error.message || error.errors[0]?.message)
      setLoading(false)
    }

    if (result) {
      if (result.data?.guild_policy?.total_race) {
        const datas: any = { ...result.data }
        datas.description = `${datas.description}\n\n\nConditions:\nTotal race: ${datas?.guild_policy?.total_race}`
        setInformationData(datas)
      } else {
        setInformationData(result.data)
      }
    }

    setLoading(false)
  }

  const handleJoinGuildSuccess = () => {
    setInformationData({
      ...informationData,
      total_member: +informationData?.total_member + 1
    })
    if (informationData?.private_guild) {
      openNotification(t(`${GUILD_MESSAGE}.success`), t(`${GUILD_MESSAGE}.message.apply_success`))
    } else {
      openNotification(t(`${GUILD_MESSAGE}.success`), t(`${GUILD_MESSAGE}.message.join_success`))
    }
  }

  useEffect(() => {
    if (informationData?.private_guild) {
      if (
        ![...(profile?.apply_guilds || [])]?.includes(Number(guild_id)) &&
        ![...(profile?.black_guilds || [])]?.includes(Number(guild_id)) &&
        (!profile?.guild_id || profile?.guild_id === 0) &&
        profile?.role_in_guild !== STRING_ROLE.MASTER &&
        +informationData?.master_guild_level?.max_member - +informationData?.total_member > 0
      ) {
        setDisableBtnApply(() => false)
      } else {
        setDisableBtnApply(() => true)
      }
    } else {
      if (
        (!profile?.guild_id || profile?.guild_id === 0) &&
        +informationData?.master_guild_level?.max_member - +informationData?.total_member > 0
      ) {
        setDisableBtnJoin(() => false)
      } else {
        setDisableBtnJoin(() => true)
      }
    }
  }, [informationData])

  return (
    <InformationStyled>
      {contextHolder}
      {loading ? (
        <div className='loading'>
          <Spin className='mt-2 mr-3' />
        </div>
      ) : (
        <>
          {!loading && informationData && (
            <>
              <div className='guild-information'>
                <div className='basic-infor'>
                  <div className='basic-infor-group'>
                    <AvatarBox informationData={informationData} />
                    <div className='line-box'>
                      <img src={LINE} alt='line' />
                    </div>
                    <InforBox informationData={informationData} />
                    <div className='line-box'>
                      <img src={LINE} alt='line' />
                    </div>
                    <CareerBox informationData={informationData} />
                  </div>
                </div>
                <div className='guild-description'>
                  <div className='description-content d-flex justify-content-between'>
                    <div className='bottom-left'>
                      <img src={FX_1} alt='fx-1' />
                    </div>
                    <div className='bottom-right'>
                      <img src={FX_2} alt='fx-2' />
                    </div>
                    <div className='description-text'>
                      <pre>
                        <p
                          dangerouslySetInnerHTML={{ __html: informationData?.description || '' }}
                          style={{ whiteSpace: 'pre-wrap' }}
                        />
                      </pre>
                    </div>
                  </div>
                </div>
                <div className='button-box'>
                  <PutHorseToGuild />
                  {!informationData?.private_guild ? (
                    <JoindGuild
                      informationData={informationData}
                      onJoinGuildSuccess={handleJoinGuildSuccess}
                      type={t(`${GUILD_MESSAGE}.type_join`)}
                      disabled={disableBtnJoin}
                    />
                  ) : (
                    <JoindGuild
                      informationData={informationData}
                      onJoinGuildSuccess={handleJoinGuildSuccess}
                      type={t(`${GUILD_MESSAGE}.type_apply`)}
                      disabled={disableBtnApply}
                    />
                  )}
                </div>
                <div className='bg-ellipse'>
                  <img src={ELIPSE_BG} alt='bg' />
                </div>
                <div className='list'>
                  <TopHorses guildId={informationData?.id} />
                  <img src={LINE_YELLOW} alt='line' className='line-bottom' />
                  <TopMembers guildId={informationData?.id} />
                  <img src={LINE_YELLOW} alt='line' className='line-bottom' />
                  <TopOwners guildId={informationData?.id} />
                </div>
              </div>
            </>
          )}
        </>
      )}
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </InformationStyled>
  )
}
