import styled from 'styled-components'

const SubMenuStyled = styled.div`
  width: 256px;
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
    background: #252b43;
    min-height: calc(100vh - 120px);
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #252b43;
  }
  .title-main {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 20px;
    color: #fff;
    font-weight: 400;
  }
  .ant-menu-item-group-title {
    background: linear-gradient(90deg, #4ef076 0%, rgba(72, 247, 186, 0) 100%);
    position: relative;
    &:after {
      position: absolute;
      content: '';
      width: 2px;
      height: 100%;
      right: 0;
      top: 0;
      background-image: linear-gradient(#4ef07600, #4ef076, #4ef07600);
    }
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #252b43;
    color: #4ef076;
    font-weight: 600;
  }
  .ant-menu-root > li:nth-child(1),
  .ant-menu-root > li:nth-child(5) {
    padding: 0px !important;
    color: white;
    font-weight: 400;
  }
`

export default SubMenuStyled
