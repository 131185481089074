/* eslint-disable @typescript-eslint/no-explicit-any */
import horseApi from 'apis/horseApi'
import { links } from 'apps'
import { CLOSE_BTN, GAME_TOKEN_KUDA, GAME_TOKEN_MERAH, ICON_ADIL } from 'assets/images'
import { EnergyBar } from 'features/Horse/components'
import { useAppDispatch, usePreventBodyScroll, useToggle } from 'hooks'
import { ApiResponse, Horse, ItemResponse } from 'models'
import { Fragment, useEffect, useState } from 'react'
import { Modal, TwoLineTitle } from 'shared'
import { handleAsyncRequest } from 'utils/helper'
import AvatarEnergyItemStyled from './styled'
import EnergyItemModal from '../EnergyItemModal'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import itemApi from 'apis/itemApi'
// import { ITEMS_MESSAGE } from 'i18n/constants'
// import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import userApi from 'apis/userApi'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import { VIEW_BUTTON, BG_BUTTON } from 'assets/images'
import { checkExistItem } from 'utils/getLinkImage'

interface AvatarEnergyItemProps {
  horseId: string
  onOverlayClick?: () => void
  onCloseButtonClick?: () => void
  onRefresh?: () => void
}

// interface iprops {
//   horse?: any
// }

const typeRuntype = 'RUNTYPE'
const typeChar = 'CHARACTERISTIC'
const typeAll = 'CHARACTERISTIC_RUNTYPE'
const glassUrl = '/_horse/_avatar/_glass/g_'
const shoesUrl = '/_horse/_avatar/_shoes/sh_'
const armorUrl = '/_horse/_avatar/_armor/'
const skinUrl = '/_horse/_avatar/_skin/'
const domain = process.env.REACT_APP_ROOT_DOMAIN

function AvatarEnergyItem({ horseId, onOverlayClick, onCloseButtonClick, onRefresh }: AvatarEnergyItemProps) {
  const [horse, setHorse] = useState<Horse>()
  const [isLoading, setIsLoading] = useToggle(false)
  const [firstTime, setFirstTime] = useState(0)
  const [isEnergyItemModal, toggleEnergyItemModal] = useToggle(false)
  const [isOneLevelModal, toggleOneLevelModal] = useToggle(false)
  const [isFullEnergyModal, toggleFullEnergyModal] = useToggle(false)
  const [masterItems, setMasterItems] = useState<any>()
  const [disableRecovery, setDisableRecovery] = useState<boolean>(false)
  // const { t } = useTranslation()
  const dispatch = useAppDispatch()

  usePreventBodyScroll(isFullEnergyModal)
  usePreventBodyScroll(isEnergyItemModal)
  usePreventBodyScroll(isOneLevelModal)

  const converToUtf8 = (value: string) => {
    return value.replace('#', '%23')
  }
  const horseColorCode = converToUtf8(horse?.bloodline?.color_code || horse?.blood_line?.color_code || '')

  const weapon = horse?.weapon_items_equipped
  const headItem = checkExistItem('HEAD', weapon)
  const bodyItem = checkExistItem('BODY', weapon)
  const footItem = checkExistItem('LEG', weapon)
  const skinItem = checkExistItem('SKIN', weapon)
  const fullURLGlass = domain + glassUrl + horse?.characteristic + '.png'
  const fullURLShoes = domain + shoesUrl + horse?.run_type_code + '.png'
  const typeOfMaterial = bodyItem?.material_type?.toUpperCase()

  const fullURLBody = (typeOfMaterial: any) => {
    switch (typeOfMaterial) {
      case typeRuntype: {
        return domain + armorUrl + bodyItem?.model_name + '_' + horse?.run_type_code + '.png'
      }
      case typeChar: {
        return domain + armorUrl + bodyItem?.model_name + '_' + horse?.characteristic + '.png'
      }
      case typeAll: {
        return (
          domain + armorUrl + bodyItem?.model_name + '_' + horse?.characteristic + '_' + horse?.run_type_code + '.png'
        )
      }
      default:
        return domain + armorUrl + bodyItem?.model_name + '.png'
    }
  }
  const headtUrl = domain + armorUrl + headItem?.model_name + '_' + horse?.characteristic + '.png'
  const footUrl = domain + armorUrl + footItem?.model_name + '_' + horse?.run_type_code + '.png'
  const fullSkinUrl = (hairColor: any) => {
    switch (hairColor) {
      case 'SAME_BODY': {
        return domain + skinUrl + skinItem?.model_name + '_' + horse?.hair_color + '_' + horseColorCode + '.png'
      }
      default:
        return domain + skinUrl + skinItem?.model_name + '_' + horse?.hair_color + '.png'
    }
  }

  const fetchHorse = async () => {
    setIsLoading(true)

    const [, horseResponse] = await handleAsyncRequest<ApiResponse<Horse>>(
      horseApi.getHorseDetailByTokenId({ horse_id: String(horseId) })
    )
    if (horseResponse) {
      const fetchedHorse = horseResponse.data
      setHorse(fetchedHorse)
    }
    setIsLoading(false)
  }
  const fetchItemsMasterData = async () => {
    setIsLoading(true)

    const [, result] = await handleAsyncRequest<ApiResponse<ItemResponse>>(itemApi.getItemsMaterData())
    if (result) {
      const { data } = result
      setMasterItems(data)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchHorse()
    fetchItemsMasterData()
  }, [horseId])

  // time waiting
  const timer = () => setFirstTime(firstTime - 1000)
  useEffect(() => {
    const id = setInterval(timer, 1000)
    if (firstTime > 0) {
      setDisableRecovery(true)
    }
    if (firstTime <= 0) {
      setDisableRecovery(false)
      clearInterval(id)
    }
    return () => clearInterval(id)
  }, [firstTime])

  // get time when access first room horse
  useEffect(() => {
    if (horse && horse?.remaining_time_to_next_energy >= 0) {
      const start_at = horse?.remaining_time_to_next_energy
      setFirstTime(start_at)
    }
  }, [horse])

  const handleRecoverOneLevelClicked = () => {
    // toggleEnergyItemModal(true)
    toggleOneLevelModal(true)
  }
  const handleFullRecoverClicked = () => {
    toggleFullEnergyModal()
  }
  const handleUseItemClicked = () => {
    toggleEnergyItemModal(true)
  }

  const handleCloseItemModal = () => {
    toggleEnergyItemModal(false)
  }

  const handlePutUseItem = async () => {
    setIsLoading(true)
    const [error, itemResponse]: any = await handleAsyncRequest<ApiResponse<ItemResponse>>(
      itemApi.putBosstItem(
        { horseId: horseId },
        {
          pay_for_energy: true,
          recover_type: isFullEnergyModal ? 'FULL' : 'NOT_FULL'
        }
      )
    )
    if (error) {
      toggleFullEnergyModal(false)
      toggleOneLevelModal(false)
      notification.error({
        message: 'ERROR',
        description: error?.message ?? 'Can not use this item for this horse!',
        placement: 'bottomRight',
        duration: 4,
        className: 'ant-custom-error',
        icon: <></>
      })
    }
    if (itemResponse) {
      handleUseItemSuccess()
    }
    setIsLoading(false)
  }

  const checkCurrentCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return
    dispatch(setCoinUser(resultCoinUser.data))
  }

  const handleUseItemSuccess = () => {
    onRefresh?.()
    checkCurrentCoinUser()
    toggleEnergyItemModal(false)
    toggleOneLevelModal(false)
    onCloseButtonClick?.()
    toggleFullEnergyModal(false)
    notification.success({
      message: 'SUCCESS',
      description: `You have successfully recovered your horse's energy`,
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })
  }

  // time waiting
  return (
    <Modal onOverlayClick={onOverlayClick}>
      <AvatarEnergyItemStyled>
        {horse && !isLoading && (
          <Fragment>
            <button className='close-btn p-0 position-absolute' role='button' onClick={onCloseButtonClick}>
              <img src={CLOSE_BTN} alt='close' />
            </button>
            <TwoLineTitle text='Energy recover' customClass='title' />
            <div className='quick-view'>
              <div className='container'>
                <div className='quick-view-box position-relative'>
                  <div className='quick-view-container align-items-lg-center'>
                    <div className='quick-view-left'>
                      <div className='left'>
                        <div className='name color-white font-bold d-block d-lg-none mb-3'>{horse.name}</div>
                        <div className='background-container mb-2'>
                          <div className='background d-flex align-items-center justify-content-center'>
                            <a rel='noopener noreferrer' href={links.horse.detail(+horseId)} target='_blank'>
                              <img src={horse.avatar} alt={horse.name} className='avatar' />
                              {headItem && headItem?.model_name ? (
                                <img src={headtUrl} alt={headItem.name} className='item head' />
                              ) : (
                                <img src={fullURLGlass} className='item glass' />
                              )}

                              {bodyItem && bodyItem?.model_name && (
                                <img src={fullURLBody(typeOfMaterial)} alt={bodyItem.name} className='item body' />
                              )}

                              {footItem && footItem?.model_name ? (
                                <img src={footUrl} alt={footItem.name} className='item foot' />
                              ) : (
                                <img src={fullURLShoes} className='item shoes' />
                              )}

                              {skinItem && skinItem?.model_name && (
                                <img src={fullSkinUrl(horse?.hair_color)} alt={skinItem.name} className='item skin' />
                              )}
                            </a>
                          </div>
                        </div>

                        <div className='energy-container'>
                          <EnergyBar
                            maxEnergy={horse.max_energy}
                            currentEnergy={horse.current_energy}
                            customClass='custom-energy mx-auto'
                            firstTime={firstTime}
                            disableRecovery={disableRecovery}
                          />
                        </div>
                        <div className='d-flex justify-content-center gap-3 mt-4'>
                          <div className='detail-btn-container' onClick={handleUseItemClicked}>
                            <button className='detail-btn font-bold'>
                              <img src={VIEW_BUTTON} alt='confirm' />
                              <span className=''>use Item</span>
                            </button>
                          </div>
                        </div>
                        <div className='d-flex justify-content-center gap-4 mt-4'>
                          <div className='detail-btn-container' onClick={handleRecoverOneLevelClicked}>
                            <button className='detail-btn font-bold'>
                              <img src={BG_BUTTON} alt='' />
                              <span className=''>recover 1 level</span>
                            </button>
                          </div>
                          <div className='detail-btn-container' onClick={handleFullRecoverClicked}>
                            <button className='detail-btn font-bold'>
                              <img src={BG_BUTTON} alt='confirm' />
                              <span className=''>Fulll recover</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
        {isEnergyItemModal && (
          <EnergyItemModal
            onCloseButtonClick={handleCloseItemModal}
            horseId={horseId}
            onClose={onCloseButtonClick}
            fetchHorse={onRefresh}
            masterItems={masterItems}
          />
        )}
        {isOneLevelModal && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleOneLevelModal}
            onCloseButtonClick={toggleOneLevelModal}
            onConfirm={handlePutUseItem}
            // message={t(`${ITEMS_MESSAGE}.pay_1_energy`, { price: masterItems?.energy_items?.NOT_FULL?.boost?.price || 0, currency: masterItems?.energy_items?.NOT_FULL?.boost?.currency })
            // }
            message={
              <div className='font-size-24  message-content'>
                This horse will recover 1 energy level with {masterItems?.energy_items?.NOT_FULL?.boost?.price || 0}
                {masterItems?.energy_items?.NOT_FULL?.boost?.currency === 'MERAH' ||
                masterItems?.energy_items?.NOT_FULL?.boost?.currency === 'MERAH_NEW' ? (
                  <img src={GAME_TOKEN_MERAH} alt='merah' className='icon merah' width={25} />
                ) : masterItems?.energy_items?.NOT_FULL?.boost?.currency === 'KUDA' ||
                  masterItems?.energy_items?.NOT_FULL?.boost?.currency === 'KUDA_NEW' ? (
                  <img src={GAME_TOKEN_KUDA} alt='kuda' className='icon kuda' width={25} />
                ) : (
                  <img src={ICON_ADIL} alt='adil' className='icon adil' width={25} />
                )}
                <br />
                Are you sure?
              </div>
            }
            isLoading={isLoading}
            heading='Energy recover'
            isContentCenter
          />
        )}
        {isFullEnergyModal && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleFullEnergyModal}
            onCloseButtonClick={toggleFullEnergyModal}
            onConfirm={handlePutUseItem}
            // message={t(`${ITEMS_MESSAGE}.pay_full_energy`, { price: masterItems?.energy_items.FULL?.boost?.price || 0, currency: masterItems?.energy_items?.FULL?.boost?.currency })}
            message={
              <div className='font-size-24  message-content'>
                This horse will recover full energy level with {masterItems?.energy_items.FULL?.boost?.price || 0}
                {masterItems?.energy_items?.FULL?.boost?.currency === 'MERAH' ||
                masterItems?.energy_items?.FULL?.boost?.currency === 'MERAH_NEW' ? (
                  <img src={GAME_TOKEN_MERAH} alt='merah' className='icon merah' width={25} />
                ) : masterItems?.energy_items?.FULL?.boost?.currency === 'KUDA' ||
                  masterItems?.energy_items?.FULL?.boost?.currency === 'KUDA_NEW' ? (
                  <img src={GAME_TOKEN_KUDA} alt='kuda' className='icon kuda' width={25} />
                ) : (
                  <img src={ICON_ADIL} alt='adil' className='icon adil' width={25} />
                )}
                <br />
                Are you sure?
              </div>
            }
            isLoading={isLoading}
            heading='Energy recover'
            isContentCenter
          />
        )}
      </AvatarEnergyItemStyled>
    </Modal>
  )
}

export default AvatarEnergyItem
