import TwoLineTitleStyled from 'shared/TwoLineTitle'

interface iprop {
  name: string
}

const Head = ({ name }: iprop) => {
  return (
    <div className='title-tabs-container'>
      <div className='title-tabs color-primary'>
        <TwoLineTitleStyled text={name} classGuild={'line__create'} />
      </div>
    </div>
  )
}

export default Head
