/* eslint-disable @typescript-eslint/no-explicit-any */
import { checkExistItem } from '../utils'
import HorseImageContainer from './styled'
import { StepHorse } from 'models'
import { HORSE_BODY_BLACK, HORSE_BODY_BLUE, HORSE_BODY_BROWN, HORSE_BODY_CYAN, HORSE_BODY_DARKBLUE, HORSE_BODY_GREEN, HORSE_BODY_ORANGE, HORSE_BODY_PINK, HORSE_BODY_PURPLE, HORSE_BODY_RED, HORSE_BODY_WHITE, HORSE_BODY_YELLOW } from 'assets/images';
interface iprops {
  avatar: string
  name: string
  items: any
  horse?: StepHorse
}

const HorseAvatar = ({ horse, avatar, name, items = [] }: iprops) => {
  const glassUrl = '/_horse/_avatar/_glass/g_'
  const shoesUrl = '/_horse/_avatar/_shoes/sh_'
  const armorUrl = '/_horse/_avatar/_armor/'
  const skinUrl = '/_horse/_avatar/_skin/'
  const typeRuntype = 'RUNTYPE'
  const typeChar = 'CHARACTERISTIC'
  const typeAll = 'CHARACTERISTIC_RUNTYPE'
  const converToUtf8 = (value: string) => {
    return value.replace('#', '%23')
  }
  const headItem = checkExistItem('HEAD', items)
  const bodyItem = checkExistItem('BODY', items)
  const footItem = checkExistItem('LEG', items)
  const skinItem = checkExistItem('SKIN', items)
  const modelBodyName = bodyItem?.modelName || bodyItem?.model_name
  const modelHeadName = headItem?.modelName || headItem?.model_name
  const modelFootName = footItem?.modelName || footItem?.model_name
  const modelSkinName = skinItem?.modelName || skinItem?.model_name
  const typeOfMaterial = bodyItem?.materialType || bodyItem?.material_type
  const horseCharacteristicCode = horse?.characteristic_code || horse?.characteristic
  const horseRunTpeCode = horse?.runTypeCode || horse?.run_type_code

  const fullURLGlass = process.env.REACT_APP_ROOT_DOMAIN + glassUrl + horseCharacteristicCode + '.png'
  const defautlShoes = process.env.REACT_APP_ROOT_DOMAIN + shoesUrl + horseRunTpeCode + '.png'

  const fullURLBody =
    typeOfMaterial?.toUpperCase() === typeRuntype
      ? process.env.REACT_APP_ROOT_DOMAIN + armorUrl + modelBodyName + '_' + horseRunTpeCode + '.png'
      : typeOfMaterial?.toUpperCase() === typeChar
      ? process.env.REACT_APP_ROOT_DOMAIN + armorUrl + modelBodyName + '_' + horseCharacteristicCode + '.png'
      : typeOfMaterial?.toUpperCase() === typeAll
      ? process.env.REACT_APP_ROOT_DOMAIN +
        armorUrl +
        modelBodyName +
        '_' +
        horseCharacteristicCode +
        '_' +
        horseRunTpeCode +
        '.png'
      : process.env.REACT_APP_ROOT_DOMAIN + armorUrl + modelBodyName + '.png'

  const footArmorUrl = process.env.REACT_APP_ROOT_DOMAIN + armorUrl + modelFootName + '_' + horseRunTpeCode + '.png'
  const headtUrl = process.env.REACT_APP_ROOT_DOMAIN + armorUrl + modelHeadName + '_' + horseCharacteristicCode + '.png'
  const fullSkinUrl =
    skinItem && (horse?.hairColor === 'SAME_BODY' || horse?.hair_color === 'SAME_BODY')
      ? process.env.REACT_APP_ROOT_DOMAIN +
        skinUrl +
        modelSkinName +
        '_' +
        (horse?.hairColor || horse?.hair_color) +
        '_' +
        converToUtf8(horse?.bloodLine?.colorCode || horse?.blood_line?.color_code) +
        '.png'
      : process.env.REACT_APP_ROOT_DOMAIN +
        skinUrl +
        modelSkinName +
        '_' +
        (horse?.hairColor || horse?.hair_color) +
        '.png'

        const getAvatarImageSource = (horse_id: number | undefined) => {
          const horse_id_str = horse_id + ''
          const color = horse_id_str.charAt(horse_id_str.length - 1);
          switch (color) {
            case '1': {
              return HORSE_BODY_BLACK
            }
            case '2': {
              return HORSE_BODY_BLUE
            }
            case '3': {
              return HORSE_BODY_BROWN
            }
            case '4': {
              return HORSE_BODY_CYAN
            }
            case '5': {
              return HORSE_BODY_DARKBLUE
            }
            case '6': {
              return HORSE_BODY_GREEN
            }
            case '7': {
              return HORSE_BODY_ORANGE
            }
            case '8': {
              return HORSE_BODY_PINK
            }
            case '9': {
              return HORSE_BODY_PURPLE
            }
            case '0': {
              return HORSE_BODY_RED
            }
            case '11': {
              return HORSE_BODY_WHITE
            }
            case '12': {
              return HORSE_BODY_YELLOW
            }
            default:
              return HORSE_BODY_WHITE
          }
        }

  return (
    <HorseImageContainer>
      <div className='background d-flex align-items-center justify-content-center'>
        <img src={getAvatarImageSource(horse?.id)} alt={name} className='avatar' />
      </div>
    </HorseImageContainer>
  )
}

export default HorseAvatar
