/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GUILD_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import { notification } from 'antd'

import ModalManagerMember from 'features/Guild/components/ModalManagerMember'
import { RequestLoginModal, RaceTable } from 'features/Race/components'
import { useAppSelector, useDebounce, useToggle, useUpdateEffect } from 'hooks'
import { Career, MyHorseListParams } from 'models'
import { SearchInput } from 'shared'
import { STRING, NUMBER } from 'apps/constants'
import { guildUserBlacklistColumns } from 'utils/columns'
import { links } from 'apps'
import constants from 'apps/constants'
import guildApi from 'apis/guildApi'
import { handleAsyncRequest, shortenUserName } from 'utils/helper'
import ManagementUserBlacklistStyled from './styled'

const myHorseListParams: MyHorseListParams = {
  limit: NUMBER.TWENTY,
  page: NUMBER.ONE
}

export default function ManagementUserBlacklist() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()
  const { profile } = useAppSelector(state => state)
  const [params, setParams] = useState<MyHorseListParams>(myHorseListParams)
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [guildUserBlacklist, setGuildUserBlacklistn] = useState<any>([])
  const [total, setTotal] = useState()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const userIdRef = useRef<any>(null)

  const memoizedResultListSelectionColumns = useMemo(() => {
    return guildUserBlacklistColumns
  }, [profile])

  useEffect(() => {
    if (profile.guild_id !== NUMBER.ZERO) {
      getListGuildUser()
    }
  }, [params, profile])

  const getListGuildUser = async () => {
    setLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      guildApi.getListGuildUserBlacklist(profile.guild_id, { ...params })
    )

    if (error) {
      openNotification(STRING.ERROR, error.message || error.errors[NUMBER.ZERO]?.message)
    }

    if (result) {
      const formatData = result.data.records.map((record: any) => {
        return {
          ...record,
          member: shortenUserName(record.user_name),
          winRate: handleCareer(record.career)
        }
      })
      setGuildUserBlacklistn(params.page > NUMBER.ONE ? [...guildUserBlacklist, ...formatData] : formatData)
      setTotal(result.data?.total || NUMBER.ZERO)
    }

    setLoading(false)
  }

  useUpdateEffect(() => {
    setParams({ ...params, page: NUMBER.ONE, search: debounceSearchValue })
  }, [debounceSearchValue])

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const onClickCancelMember = (value: any) => {
    userIdRef.current = value?.id
    setShowModal(!showModal)
  }

  const handleRowItemClick = (value: any) => {
    navigate(links.guild.userBlacklistDetail(profile.guild_id, value, NUMBER.TWO))
  }

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: NUMBER.FOUR
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === STRING.ERROR ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const handleCareer = (career: Career) => {
    const totalRace = career?.total_number_of_races
    const firstClass = career?.first_count
    const secondClass = career?.second_count
    const thirdClass = career?.third_count
    const tltClass = (firstClass + secondClass + thirdClass) / totalRace
    const newTltClass = isNaN(tltClass) ? 0 : tltClass * 100
    const winRateResult = `${
      Number.isInteger((firstClass / totalRace) * 100)
        ? (firstClass / totalRace) * 100
        : ((firstClass / totalRace) * 100).toFixed(2)
    }% - ${Number.isInteger(newTltClass) ? newTltClass : newTltClass.toFixed(2)}%`
    let valueDisplay = ''
    if (totalRace === 0) {
      valueDisplay = '---'
    }
    if (totalRace > 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
      valueDisplay = '0.00% - 0.00%'
    }
    if (totalRace !== 0 && (firstClass !== 0 || secondClass !== 0 || thirdClass !== 0)) {
      valueDisplay = winRateResult
    }
    return valueDisplay
  }

  const _handleToggleModal = () => {
    setShowModal(!showModal)
  }

  const _handleUpgrade = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      guildApi.putCancelUserBlacklist(profile.guild_id, userIdRef.current)
    )
    setIsLoading(false)
    if (error) {
      openNotification(STRING.ERROR, error.message || error.errors[NUMBER.ZERO]?.message)
    }

    if (result) {
      getListGuildUser()
      _handleToggleModal()
      openNotification(STRING.SUCCESS, t(`${GUILD_MESSAGE}.message.cancel_success`))
    }
  }

  return (
    <ManagementUserBlacklistStyled>
      {contextHolder}
      <div className='search-box'>
        <SearchInput
          searchValue={searchValue}
          handleSearchValueChanged={handleSearchValueChanged}
          customClass='search-horse-input'
          placeholder={t(`${GUILD_MESSAGE}.search_app`)}
        />
      </div>

      <div className='members'>
        <RaceTable
          columns={memoizedResultListSelectionColumns}
          data={guildUserBlacklist ?? []}
          raisePage={setParams}
          loader={!loading}
          params={params}
          isRowClickable
          checkLoadingPage={false}
          totalRace={total}
          currentUser={profile}
          noDataText={t(`${NOTIFICATION_MESSAGE}.dataNotFound`)}
          onClickOneCell={id => onClickCancelMember(id)}
          onRowClick={handleRowItemClick}
          isShowVipIcon={true}
        />
      </div>

      {showModal && (
        <ModalManagerMember
          title={t(`${GUILD_MESSAGE}.title_modal_cancel`)}
          message={
            <p>
              {t(`${GUILD_MESSAGE}.content_modal_cancel`)}
              <br />
              {t(`${GUILD_MESSAGE}.content_modal_cancel2`)}
            </p>
          }
          onClose={_handleToggleModal}
          onConfirm={_handleUpgrade}
          loading={isLoading}
          titleColor='rgb(78, 240, 118)'
          width={550}
          heigth={240}
        />
      )}

      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </ManagementUserBlacklistStyled>
  )
}
