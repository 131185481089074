/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getHorseInfo,
  getHorseInfoEventHistory,
  getHorseInfoGuild,
  getHorseInfoHorseBlacklist,
  getHorseInfoProfile,
  postHorseItem
} from './action'

interface initialStateType {
  loadingHorseInfo?: boolean
  horseInfo?: any
  horseItem?: string
  errorHorseInfo: string
}

const initialState: initialStateType = {
  loadingHorseInfo: false,
  horseInfo: [],
  horseItem: '',
  errorHorseInfo: ''
}

export const horseInfoSlice = createSlice({
  name: 'armorSkin',
  initialState,
  reducers: {},
  extraReducers: {
    // horse info
    [getHorseInfo.pending.type]: state => {
      state.loadingHorseInfo = true
      state.horseInfo = ''
    },

    [getHorseInfo.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingHorseInfo = false
      state.errorHorseInfo = ''
      state.horseInfo = action.payload
    },

    [getHorseInfo.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingHorseInfo = false
      state.errorHorseInfo = action.payload
    },

    // horse info in guild
    [getHorseInfoGuild.pending.type]: state => {
      state.loadingHorseInfo = true
      state.horseInfo = ''
    },

    [getHorseInfoGuild.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingHorseInfo = false
      state.errorHorseInfo = ''
      state.horseInfo = action.payload?.horse
    },

    [getHorseInfoGuild.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingHorseInfo = false
      state.errorHorseInfo = action.payload
    },

    // horse info in profile
    [getHorseInfoProfile.pending.type]: state => {
      state.loadingHorseInfo = true
      state.horseInfo = ''
    },

    [getHorseInfoProfile.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingHorseInfo = false
      state.errorHorseInfo = ''
      state.horseInfo = action.payload
    },

    [getHorseInfoProfile.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingHorseInfo = false
      state.errorHorseInfo = action.payload
    },

    // horse info in even history
    [getHorseInfoEventHistory.pending.type]: state => {
      state.loadingHorseInfo = true
      state.horseInfo = ''
    },

    [getHorseInfoEventHistory.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingHorseInfo = false
      state.errorHorseInfo = ''
      state.horseInfo = action.payload?.horse
    },

    [getHorseInfoEventHistory.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingHorseInfo = false
      state.errorHorseInfo = action.payload
    },

    // horse info in horse blacklist
    [getHorseInfoHorseBlacklist.pending.type]: state => {
      state.loadingHorseInfo = true
      state.horseInfo = ''
    },

    [getHorseInfoHorseBlacklist.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingHorseInfo = false
      state.errorHorseInfo = ''
      state.horseInfo = action.payload
    },

    [getHorseInfoHorseBlacklist.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingHorseInfo = false
      state.errorHorseInfo = action.payload
    },

    // horse item
    [postHorseItem.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.horseItem = action.payload
    }
  }
})
export default horseInfoSlice.reducer
