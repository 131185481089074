import { Progress } from 'antd'
import ProgressCircleContainer from './styled'
import { takeDecimalNumber } from 'utils/helper'

interface PProps {
  career: {
    total_number_of_races: number
    first_count: number
    second_count: number
    third_count: number
    win_count?: number
    total_horse_in_guild?: number
    total_horse_owner_in_guild: number
  }
}

const ProgressCircleV2 = ({ career }: PProps) => {
  const totalPercent = career?.total_horse_in_guild
    ? takeDecimalNumber((career?.total_horse_owner_in_guild / career?.total_horse_in_guild) * 100, 2)
    : 0

  return (
    <ProgressCircleContainer>
      <Progress
        className='progress-box'
        percent={totalPercent}
        type='circle'
        strokeColor='#F64040'
        trailColor='#D6D6D6'
        strokeLinecap='square'
        showInfo={false}
        width={110}
        strokeWidth={10}
      />
      <div className='text-info'>
        <div className='col'>
          <span className='place'>{totalPercent}%</span>
        </div>
      </div>
    </ProgressCircleContainer>
  )
}

export default ProgressCircleV2
