/* eslint-disable @typescript-eslint/no-explicit-any */
import { CLOSE_BTN } from 'assets/images'
import { Modal } from 'shared'
import Button from 'shared/Button'
import { ModalManagerMemberContainer } from './styled'

interface MProps {
  onClose: () => void
  onConfirm: () => void
  loading: boolean
  message?: any
  title?: any
  titleColor?: string
  width?: any
  heigth?: any
}

const ModalManagerMember = ({
  onClose,
  onConfirm,
  loading,
  message,
  title,
  titleColor,
  width = 515,
  heigth = 274
}: MProps) => {
  const _onConfirm = () => {
    onConfirm()
  }

  return (
    <Modal>
      <ModalManagerMemberContainer width={width} heigth={heigth}>
        <div className='header'>
          <p className='title'>
            <span className='title' style={{ color: titleColor }}>
              {title}
            </span>
          </p>
        </div>
        <button disabled={loading} className='close-btn p-0 position-absolute' role='button' onClick={onClose}>
          <img src={CLOSE_BTN} alt='close' />
        </button>
        <div className='content-box'>{message}</div>
        <div className='button-box'>
          <Button buttonName='No' onClickButton={onClose} width={100} height={35} type='btnCancel' disabled={loading} />
          <Button buttonName='Yes' onClickButton={_onConfirm} width={100} height={35} isLoading={loading} />
        </div>
      </ModalManagerMemberContainer>
    </Modal>
  )
}

export default ModalManagerMember
