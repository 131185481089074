import { NEXT_RACE_BOTTOM_FRAME } from 'assets/images'
import classNames from 'classnames'
import { RateStar } from 'features/Horse/components'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { useCallback, useState } from 'react'
import AbilityBoxStyled from './styled'
import { getAbility } from 'utils/getLinkImage'
import { useToggle } from 'hooks'
import ModalHorseBloodline from 'features/components/ModalHorseBloodline'
import { STRING_ABILITY } from 'apps/constants'

interface AbilityBoxProps {
  customClass?: string
  name: string
  level?: number
}

function AbilityBox({ customClass = '', name, level = 1 }: AbilityBoxProps) {
  const abilityBoxClass = classNames('position-relative', customClass)
  const [bloodlineTitle, setBloodlineTitle] = useState('')
  const [bloodlineContent, setBloodlineContent] = useState('')
  const [openHorseBloodlineModal, toggleOpenHorseBloodlineModal] = useToggle(false)

  const generateRateLevel = useCallback(
    (maxLevel: number, currentLevel: number): JSX.Element[] => {
      const rateStars: JSX.Element[] = []

      for (let i = 0; i < maxLevel; i++) {
        rateStars.push(<RateStar key={i} isActive={i < currentLevel} />)
      }

      return rateStars
    },
    [level]
  )

  const rateLevel = generateRateLevel(3, level)

  const _handleClosePopup = () => {
    toggleOpenHorseBloodlineModal(false)
  }

  const _handleClickImageAbility = (event: React.MouseEvent, content: string) => {
    event.stopPropagation()
    switch (content?.toUpperCase()) {
      case STRING_ABILITY.DIRT?.toUpperCase():
      case STRING_ABILITY.TURF?.toUpperCase():
        setBloodlineTitle('Ability Field Type')
        break
      case STRING_ABILITY.LONG?.toUpperCase():
      case STRING_ABILITY.MEDIUM?.toUpperCase():
      case STRING_ABILITY.SHORT?.toUpperCase():
        setBloodlineTitle('Ability Distance')
        break
      default:
        break
    }
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  return (
    <AbilityBoxStyled className={abilityBoxClass}>
      <div className='ability-box-container'>
        <div className='ability-box d-flex justify-content-between align-items-center'>
          <div
            onClick={event => _handleClickImageAbility(event, name)}
            className='title color-white text-uppercase img__ability'
          >
            <MemoizedLoadImageCommon srcImg={getAbility(name)} />
          </div>
          <div className='level d-flex align-items-center'>{rateLevel}</div>
        </div>
        <img src={NEXT_RACE_BOTTOM_FRAME} alt='' className='position-absolute bottom-frame d-none d-xl-inline-block' />
        {openHorseBloodlineModal && (
          <ModalHorseBloodline title={bloodlineTitle} content={bloodlineContent} onClose={_handleClosePopup} />
        )}
      </div>
    </AbilityBoxStyled>
  )
}

export default AbilityBox
