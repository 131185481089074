/* eslint-disable @typescript-eslint/no-explicit-any */
import { CLOSE_BTN, ONE_LINE_ORANGE, BUTTON_REDUCE, BUTTON_ADD } from 'assets/images'
import { ItemShop } from 'models'
import { Modal } from 'shared'
import Button from 'shared/Button'
import BuyItemModalStyled from './styled'
import IconMerahOrKuda from '../IconMerahOrKuda'
import { useEffect, useMemo, useState } from 'react'
import { handleAsyncRequest } from 'utils/helper'
import itemShopsApi from 'apis/itemShopsApi'

interface BuyItemModalProps {
  toggleIsModalOpen?: (value?: boolean) => void
  onCloseButtonClick: () => void
  title?: string
  itemData?: ItemShop
  onConfirm: () => void
  onChangeInput: (value: number) => void
  value: number
}

function BuyItemModal({
  value,
  onChangeInput,
  onConfirm,
  itemData,
  toggleIsModalOpen,
  onCloseButtonClick,
  title
}: BuyItemModalProps) {
  const [totalItems, setTotalItems] = useState(0)

  const isMaxItems = useMemo(() => Number(value) + totalItems >= 999, [value, totalItems])
  const isLimit = useMemo(() => totalItems >= 999, [totalItems])
  const remainingTotal = useMemo(() => 999 - totalItems, [value, totalItems])
  const fetchCurrentBoosterValue = async () => {
    const [, result] = await handleAsyncRequest(itemShopsApi.checkBoosterValue(Number(itemData?.boost?.id)))
    setTotalItems(result > 0 ? result : 0)
  }

  useEffect(() => {
    fetchCurrentBoosterValue()
  }, [])

  const handleBackModalCancel = () => {
    onCloseButtonClick()
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '')
    const intValue = Math.min(remainingTotal, Math.max(0, parseInt(newValue, 10)))
    onChangeInput(intValue ? intValue : 0)
  }

  const totalPayment = useMemo(() => {
    return Number(itemData?.price) * Number(value)
  }, [itemData, value])

  const onDecreaseClicked = () => {
    onChangeInput(value - 1)
  }
  const onIncreaseClicked = () => {
    onChangeInput(value + 1)
  }

  const onMaxClicked = () => {
    onChangeInput(remainingTotal)
  }

  return (
    <Modal onOverlayClick={toggleIsModalOpen}>
      <BuyItemModalStyled>
        <div className='choose-horse-modal head'>
          <div className='header'>
            <p>
              <span className='title'>BUY ITEM</span>
            </p>
            <span className='line__under--title'></span>
          </div>
          {title && (
            <div className='race-name-container'>
              <p className='race-name color-orange text-uppercase d-flex align-items-center flex-column'>
                <span className='title'>
                  {title}
                  <img className='line-bottom' src={ONE_LINE_ORANGE} alt='' />
                </span>
              </p>
            </div>
          )}
          <button className='close-btn p-0 position-absolute' role='button' onClick={handleBackModalCancel}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className='confirm-horse'>
            <div className='text'>
              <p>You are about to buy {itemData?.item?.name ?? itemData?.boost?.name} from the shop</p>
              <div className='amount'>
                <div> Amount </div>
                <div className='amount-btn'>
                  <button
                    className={`decrease font-bold ${Number(value) === 0 && 'disabled'}`}
                    onClick={onDecreaseClicked}
                    disabled={Number(value) === 0}
                  >
                    <img src={BUTTON_REDUCE} alt='' />
                  </button>
                  <input
                    type='text'
                    value={value}
                    onChange={handleInputChange}
                    placeholder='Enter a number (0-999)'
                    className='input-amount'
                  />
                  <button
                    className={`decrease font-bold ${isMaxItems && 'disabled'}`}
                    onClick={onIncreaseClicked}
                    disabled={isMaxItems}
                  >
                    <img src={BUTTON_ADD} alt='' />
                  </button>
                  <button
                    className={`btn-max font-bold ${isMaxItems && 'disabled'}`}
                    onClick={onMaxClicked}
                    disabled={isMaxItems}
                  >
                    MAX
                  </button>
                </div>
              </div>
              {isLimit && (
                <div className='color-red text-error'>
                  Your item has reached its limit.
                  <br />
                  You must use them before purchasing more.
                </div>
              )}
              <div className='d-flex'>
                <div className='text-price'>Unit price:</div>{' '}
                <IconMerahOrKuda type={itemData?.currency} price={itemData?.price} />
              </div>
              <div className='d-flex'>
                <span className='text-price'>Total payment:</span>{' '}
                <IconMerahOrKuda type={itemData?.currency} price={totalPayment} />
              </div>
            </div>

            <div className='confirm-horse-btns d-flex align-items-center justify-content-center'>
              <Button buttonName='BUY' onClickButton={onConfirm} width={150} height={35} disabled={!value || isLimit} />
            </div>
          </div>
        </div>
      </BuyItemModalStyled>
    </Modal>
  )
}

export default BuyItemModal
