import { ORANGE_LINE_LEAN_LEFT, ORANGE_LINE_LEAN_RIGHT, POLYGON } from 'assets/images'
import { useEffect, useState } from 'react'

import CountdownTimeStyled from './styled'

interface CountdownTimeProps {
  firstTime: number
  title?: string
  onTimeOut?: () => void
}

const TIME_DELAY = 2000

function CountdownTime({ firstTime, title = '', onTimeOut }: CountdownTimeProps) {
  const [countdown, setCountdown] = useState<number>(firstTime + TIME_DELAY)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((t: number) => t - 1000)
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    setCountdown(firstTime + TIME_DELAY)
  }, [firstTime])

  useEffect(() => {
    if (countdown <= 1000 && countdown > 0) {
      onTimeOut?.()
    }
  }, [countdown])

  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0')
  }

  const convertMsToTime = (milliseconds: number) => {
    if (milliseconds <= 1000) return '00: 00: 00'
    let seconds = Math.floor(milliseconds / 1000)
    let minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)

    seconds = seconds % 60
    minutes = minutes % 60

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
  }

  const timeWaitingDown = convertMsToTime(countdown)

  const handleDisplayBoardView = () => {
    let resultBoardView = null

    resultBoardView = (
      <>
        <div className='title-time'> {title}</div>
        <div className='time-remains font-bold'>TIME REMAINS</div>
        <div className='display-time font-bold'>
          <span>{timeWaitingDown?.split(':').join('\u00a0 \u00a0')}</span>
        </div>
        <div className='hour-min-sec font-bold'>
          <span>Hour</span>
          <span>Min</span>
          <span>Sec</span>
        </div>
      </>
    )
    return resultBoardView
  }

  return (
    <CountdownTimeStyled>
      <div className='board-view-time'>
        {handleDisplayBoardView()}
        <div className='orange-line-lean pb-3'>
          <div className='orange-line-lean-left'>
            <div>
              <img src={ORANGE_LINE_LEAN_LEFT} alt='' />
            </div>
          </div>
          <div className='polygon'>
            <img src={POLYGON} alt='' />
          </div>
          <div className='orange-line-lean-right'>
            <div>
              <img src={ORANGE_LINE_LEAN_RIGHT} alt='' />
            </div>
          </div>
        </div>
      </div>
    </CountdownTimeStyled>
  )
}

export default CountdownTime
