/* eslint-disable @typescript-eslint/no-explicit-any */
import specialRaceApi from 'apis/specialRaceApi'
import { RequestLoginModal, SwitchBtn } from 'features/Race/components'
import ChooseHoresModalSpecialRace from 'features/SpecialRace/components/ChooseHoresModalSpecialRace'
import ConfirmChooseHorseModalSpecialRace from 'features/SpecialRace/components/ConfirmSpecialRaceModal'
import HorseItem from 'features/SpecialRace/components/HorseItem'
import { useAppSelector, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import {
  defaultRegionFilters,
  HorseAvailable,
  ISpecialTypeSort,
  LendingHorse,
  MyHorseListParams,
  SpecialSortName
} from 'models'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OneLineTitle } from 'shared'
import ButtonCustom from 'shared/ButtonCustom'
import SelectCustom from 'shared/SelectCustom'
import { getKeySort, handleAsyncRequest } from 'utils/helper'
import OldFarmStyled from './styled'
import { NUMBER, STRING_REGION } from 'apps/constants'

export type IMyAssetProps = {
  isActive: boolean
}

const defaultTypeSort: ISpecialTypeSort = [
  {
    name: SpecialSortName.Newest,
    isActive: false
  },
  {
    name: SpecialSortName.Oldest,
    isActive: false
  },
  {
    name: SpecialSortName.ASC,
    isActive: false
  },
  {
    name: SpecialSortName.Desc,
    isActive: false
  }
]

export default function OldFarm() {
  const myHorseListParams: MyHorseListParams = {
    limit: NUMBER.TWENTY,
    page: NUMBER.ONE
  }
  const [params, setParams] = useState<MyHorseListParams>(myHorseListParams)
  const [total, setTotal] = useState()

  const [listHorse, setListHorse] = useState<LendingHorse[]>([])
  const [classSort, setClassSort] = useState('Sorted by...')
  const [isLoading, setIsLoading] = useState(false)
  const [isFetch, setIsFetch] = useState(false)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [isMyHorseOn, toggleIsMyHorseOn] = useToggle(false)
  const useRefSwitch = useRef<HTMLButtonElement>(null)
  const [isModalChooseHorseOpen, toggleIsChooseHorseModalOpen] = useToggle(false)
  const [isModalSpecialRaceOpen, toggleIsModalSpecialRaceOpen] = useToggle(false)
  const [isRefresh, toggleIsRefresh] = useToggle(false)
  const [horseDetails, setHorseDetails] = useState<HorseAvailable>()
  const [isWithdraw, setIsWithdraw] = useState(true)
  const [regionSelect, setRegionSelect] = useState('Region')

  const auth: any = useAppSelector(state => state.auth)

  const { t } = useTranslation()

  const fetchTournamentStatus = async () => {
    const [, result] = await handleAsyncRequest(specialRaceApi.getTournamentStatus())
    if (!result) return
  }

  const fetchListRaces = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      specialRaceApi.getOldFarmHorse({ ...params, myHorse: isMyHorseOn })
    )
    if (error) {
      setIsLoading(false)
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen(true)
      }
      return
    }
    const records = result?.data.records
    setListHorse(params.page > NUMBER.ONE ? [...listHorse, ...records] : records)
    setTotal(result?.data.total)
    setIsFetch(false)
    if (records && records.length === 0) {
      // setLoader(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchTournamentStatus()
    if (isFetch) {
      setListHorse([])
      setParams({ ...params, page: NUMBER.ONE })
    }
  }, [isFetch])

  useEffect(() => {
    toggleIsChooseHorseModalOpen(false)
    fetchListRaces()
  }, [params, isMyHorseOn, isRefresh])

  const hanldeViewMore = () => {
    setParams({ ...params, page: params.page + NUMBER.ONE })
  }

  const handleNameHorse = () => {
    if (total === 0 || total === NUMBER.ONE) {
      return 'Horse'
    } else return 'Horses'
  }

  const handleWidthdrawHorseFarm = () => {
    if (!auth.isLogged) {
      toggleIsRequestLoginModalOpen()
      return
    }
    toggleIsChooseHorseModalOpen()
    setIsWithdraw(true)

    // handleWithdrawHorse()
  }
  const handleHorseItem = (horse: HorseAvailable) => {
    setHorseDetails(horse)
    // setIsWithdraw(false)
  }

  const handleSortClicked = (item: string) => {
    setParams({
      ...params,
      page: NUMBER.ONE,
      sort: getKeySort(item)
    })
    setClassSort(item)
  }

  const handleRegionFilterClicked = (item: string) => {
    if (item?.toUpperCase() === STRING_REGION.SEOUL || item?.toUpperCase() === STRING_REGION.TOKYO) {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: item?.toUpperCase()
      })
    } else {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: ''
      })
    }
    setRegionSelect(item)
  }

  return (
    <OldFarmStyled>
      <div className='top-lending pt-3'>
        <OneLineTitle text={handleNameHorse()} total={total} isLending />
        <div className='lending-search'>
          <ButtonCustom onClickButton={handleWidthdrawHorseFarm} buttonName='Withdraw' customClass='btn-danger' />
          {auth.isLogged && (
            <SwitchBtn
              title={t(`${NOTIFICATION_MESSAGE}.myHorse`)}
              isOn={isMyHorseOn}
              handleSwitchBtnClicked={toggleIsMyHorseOn}
              refSwitch={useRefSwitch}
            />
          )}

          {/* region */}
          <SelectCustom
            dataSelect={defaultRegionFilters}
            nameSelect={regionSelect}
            onSelected={handleRegionFilterClicked}
          />

          <SelectCustom dataSelect={defaultTypeSort} nameSelect={classSort} onSelected={handleSortClicked} />
        </div>
      </div>
      <HorseItem isLoading={isLoading} total={total} listHorse={listHorse} onViewMore={hanldeViewMore} />
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
      {isModalChooseHorseOpen && (
        <ChooseHoresModalSpecialRace
          isWithdraw={isWithdraw}
          getHorseDetail={handleHorseItem}
          toggleIsModalSpecialRaceOpen={toggleIsModalSpecialRaceOpen}
          toggleIsModalOpen={toggleIsChooseHorseModalOpen}
          onCloseButtonClick={() => toggleIsChooseHorseModalOpen(false)}
        />
      )}
      {isModalSpecialRaceOpen && (
        <ConfirmChooseHorseModalSpecialRace
          toggleIsRefresh={toggleIsRefresh}
          horse={horseDetails}
          toggleIsModalOpen={toggleIsModalSpecialRaceOpen}
          onCloseButtonClick={() => toggleIsModalSpecialRaceOpen(false)}
          isWithdraw={isWithdraw}
        />
      )}
    </OldFarmStyled>
  )
}
