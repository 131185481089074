/* eslint-disable @typescript-eslint/no-explicit-any */
import { Progress } from 'antd'
import ProgressCircleContainer from './styled'
import { takeDecimalNumber } from 'utils/helper'

interface PProps {
  career: {
    total_number_of_races: number
    first_count: number
    second_count: number
    third_count: number
  }
  className?: any
}

const ProgressCircle = ({ career, className }: PProps) => {
  const successPercent = career?.total_number_of_races
    ? takeDecimalNumber((career?.first_count / career?.total_number_of_races) * 100, 2)
    : 0

  const totalPercent = career?.total_number_of_races
    ? takeDecimalNumber(
        ((career?.first_count + career?.second_count + career?.third_count) / career?.total_number_of_races) * 100,
        2
      )
    : 0

  return (
    <ProgressCircleContainer className={className}>
      <Progress
        className='progress-box'
        percent={totalPercent}
        success={{ percent: successPercent }}
        type='circle'
        strokeColor='#eb00ff'
        trailColor='#D6D6D6'
        strokeLinecap='square'
        showInfo={false}
        width={110}
        strokeWidth={10}
      />
      <div className='text-info'>
        <div className='col'>
          <span>Win</span>
          <span className='success'>{successPercent}%</span>
        </div>
        <div className='line-progress' />
        <div className='col'>
          <span>Place</span>
          <span className='place'>{totalPercent}%</span>
        </div>
      </div>
    </ProgressCircleContainer>
  )
}

export default ProgressCircle
