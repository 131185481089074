/* eslint-disable @typescript-eslint/no-explicit-any */

import { NEXT_RACE_BOTTOM_FRAME, SHADOW_DOPING, ICON_DOPING } from 'assets/images'
import classNames from 'classnames'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { RateStar } from 'features/Horse/components'
import { Horse } from 'models'
import { useCallback } from 'react'
import AbilityBoxStyled from './styled'
import { getAbility } from 'utils/getLinkImage'

interface AbilityBoxProps {
  customClass?: string
  name: string
  level?: number
  horse: Horse
}

function AbilityBoxGuild({ customClass = '', name, level = 1, horse }: AbilityBoxProps) {
  const abilityBoxClass = classNames('position-relative', customClass)
  const generateRateLevel = useCallback(
    (maxLevel: number, currentLevel: number): JSX.Element[] => {
      const rateStars: JSX.Element[] = []

      for (let i = 0; i < maxLevel; i++) {
        rateStars.push(<RateStar key={i} isActive={i < currentLevel} />)
      }

      return rateStars
    },
    [level]
  )
  function handleListGetPosition(currentType: any) {
    const found = horse?.boost_items_equipped.filter(element => element.slot == currentType)
    if (found) {
      return found
    } else {
      return []
    }
  }
  const listGetPosition = handleListGetPosition(name.toLocaleUpperCase())
  const rateLevel = generateRateLevel(3, level)
  const foundOne = listGetPosition.find((element: any) => element.position == 1)
  const foundTwo = listGetPosition.find((element: any) => element.position == 2)
  return (
    <AbilityBoxStyled className={abilityBoxClass}>
      <div className='ability-box-container'>
        <div className='ability-box d-flex justify-content-between align-items-center'>
          <div className='img__ability'>
            <MemoizedLoadImageCommon srcImg={getAbility(name)} />
          </div>
          <div className='level d-flex align-items-center'>
            {rateLevel}
            <>
              {foundOne ? (
                !(level == 3) && (
                  <button className='push-doping use'>
                    <img className='shadown' src={SHADOW_DOPING} alt='' />
                    <img src={foundOne.boost?.img} alt='' className='d-block' />
                    <span> {foundOne.amount}</span>
                  </button>
                )
              ) : (
                <button className='push-doping'>
                  <img src={ICON_DOPING} alt='' className='' />
                </button>
              )}
              {foundTwo ? (
                !(level > 2 || level == 2) && (
                  <button className='push-doping use'>
                    <img className='shadown' src={SHADOW_DOPING} alt='' />
                    <img src={foundTwo.boost?.img} alt='' className='d-block' />
                    <span> {foundTwo.amount}</span>
                  </button>
                )
              ) : (
                <button className='push-doping'>
                  <img src={ICON_DOPING} alt='' className='' />
                </button>
              )}
            </>
          </div>
        </div>
        <img src={NEXT_RACE_BOTTOM_FRAME} alt='' className='position-absolute bottom-frame d-none d-xl-inline-block' />
      </div>
    </AbilityBoxStyled>
  )
}

export default AbilityBoxGuild
