import { HORSE_AVATAR_BG } from 'assets/images'
import styled from 'styled-components'

const HorseImageContainer = styled.div`
  .background {
    background-image: url(${HORSE_AVATAR_BG});
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-size: contain;
    width: 300px;
    ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
      background-size: cover;
    }

    .avatar {
      width: 300px;
      aspect-ratio: 300 / 300;
      object-fit: contain;
      object-position: center;

      ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
        width: 100%;
      }
    }

    .item {
      width: 300px;
      height: 300px;
      position: absolute;
      top: 0;
      left: 0;

      &.head {
        z-index: 2;
        height: auto;
        width: 25px;
        top: 0%;
        right: 0%;
        left: unset;
      }
      &.body {
        z-index: 3;
        height: 300px;
      }

      &.foot {
        z-index: 4;
        width: 50px;
        height: auto;
        left: 0%;
        bottom: 0%;
        top: unset;
      }

      &.skin {
        z-index: 1;
      }

      &.shoes {
        width: 300px;
        height: auto;
        left: 0%;
        bottom: 0%;
        top: unset;
      }
      &.glass {
        height: auto;
        width: 150px;
        top: 0%;
        left: 0%;
        z-index: 2;
      }
    }
  }
`

export default HorseImageContainer
