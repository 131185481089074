import { links } from 'apps'
import { LEVEL_UP, VIEW_DOPING } from 'assets/images'
import { EnergyBar } from 'features/Horse/components'
import { useAppSelector } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import _ from 'lodash'
import { GuildHorseInfo, LENDING_STATUS, LendingHorse, LendingHorseInfo } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { convertStatusGuild, shortenUserName } from 'utils/helper'
import HorseAvatarLending from 'features/Horse/components/HorseItem/HorseAvatar'
import { convertShortDay, convertShortTime } from 'utils/time'
import { NUMBER } from 'apps/constants'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import HorseAvatarStyled from './styled'

import VipIcon from 'features/components/VipIcon'
import { getImageGender, getImageRegion } from 'utils/getLinkImage'
interface HorseAvatarProps {
  horse: LendingHorse
  lendingInfo?: LendingHorseInfo | GuildHorseInfo
}

function HorseAvatar({ horse, lendingInfo }: HorseAvatarProps) {
  const [firstTime, setFirstTime] = useState(NUMBER.ZERO)
  const [disableRecovery, setDisableRecovery] = useState<boolean>(false)
  const { pathname: currentPathname } = useLocation()
  const [pathname, setPathname] = useState('')

  const isHorseVip = horse?.msp_details?.msp

  const { t } = useTranslation()

  useEffect(() => {
    const slicePathName = currentPathname?.split('/').slice(1)[0]
    setPathname(slicePathName)
  }, [currentPathname])

  const profile = useAppSelector(state => state.profile)
  const isActive = horse?.owner?.id === profile?.id
  useEffect(() => {
    if (horse.remaining_time_to_next_energy > NUMBER.ZERO) {
      const start_at = horse.remaining_time_to_next_energy
      setFirstTime(start_at)
    }
  }, [horse])

  const timer = () => setFirstTime(firstTime - NUMBER.THOUSAND)
  useEffect(() => {
    const id = setInterval(timer, NUMBER.THOUSAND)
    if (firstTime > 0) {
      setDisableRecovery(true)
    }
    if (firstTime <= 0) {
      setDisableRecovery(false)
      clearInterval(id)
    }
    return () => clearInterval(id)
  }, [firstTime])

  return (
    <HorseAvatarStyled>
      {!_.isEmpty(horse) && (
        <div className='horse-container'>
          <div className='top-horse'>
            <div className='horse'>
              <div className='horse-info'>
                <div className='horse-name'>{horse.name}</div>
                <span className='horse-gender'>
                  <div className='icon-gender'>
                    <MemoizedLoadImageCommon srcImg={getImageGender(horse?.gender)} />
                  </div>
                </span>
                <div className='region'>
                  <MemoizedLoadImageCommon srcImg={getImageRegion(horse?.region)} />
                </div>
                {isHorseVip && (
                  <div className='vip-icon'>
                    <VipIcon />
                  </div>
                )}

                {horse.equipped_boost_item && (
                  <div className='used-doping'>
                    <MemoizedLoadImageCommon srcImg={VIEW_DOPING} />
                  </div>
                )}
              </div>
              {pathname === links.specialRace.index() ? (
                <div className='horse-name-info'>
                  <div className='owner-name'>
                    {' '}
                    <span> OWNER:</span> {shortenUserName(horse?.user?.name)}{' '}
                  </div>
                  <div className='token-id'>
                    {' '}
                    <span>TOKEN ID:</span> {horse?.token_id}{' '}
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className='horse-lending'>
                <div dangerouslySetInnerHTML={{ __html: convertStatusGuild(lendingInfo?.lending_status || '') }} />
                {lendingInfo?.lending_status === LENDING_STATUS.Available && isActive && (
                  <div className='horse-level'>
                    <img src={LEVEL_UP} alt='horse-level' className='background-level' />
                    <span
                      className={`horse-level-number ${
                        horse?.level?.level.toString().length > NUMBER.ONE ? 'level-length-max' : ''
                      }`}
                    >
                      {horse?.level?.level}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='background-container mb-2'>
            <HorseAvatarLending
              horse={horse}
              name={horse?.name}
              avatar={horse?.avatar}
              items={horse?.weapon_items_equipped}
            />
          </div>
          <div className='energy-container'>
            <EnergyBar
              maxEnergy={horse?.max_energy || NUMBER.ONEHUNDRED}
              currentEnergy={horse?.current_energy || NUMBER.ZERO}
              firstTime={firstTime}
              disableRecovery={disableRecovery}
            />
          </div>

          {lendingInfo?.start_date && (
            <div className='time-container'>
              <div className='time'>
                <div className='title'> Start At: </div>
                {/* <div className='day-time'> {lendingInfo?.start_date} </div> */}
                <div className='day-time'>
                  {convertShortDay(lendingInfo?.start_date * NUMBER.THOUSAND)}{' '}
                  {convertShortTime(lendingInfo?.start_date * NUMBER.THOUSAND)}
                </div>
              </div>
              <div className='time'>
                <div className='title'> End At: </div>
                <div className='day-time'>
                  {convertShortDay(lendingInfo?.end_date * NUMBER.THOUSAND)}{' '}
                  {convertShortTime(lendingInfo?.end_date * NUMBER.THOUSAND)}
                </div>
              </div>
            </div>
          )}

          {pathname === links.specialRace.index() ? (
            <div className='d-flex justify-content-center'>
              <div className='detail-btn-container align-items-center mt-4'>
                <Link to={links.horse.detail(horse?.token_id)}>
                  <button className='detail-btn font-bold w-100 h-100'>
                    <span className='color-primary'>{t(`${NOTIFICATION_MESSAGE}.details`)}</span>
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </HorseAvatarStyled>
  )
}

export default HorseAvatar
