import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CurrentUser } from 'models'

const initialState: CurrentUser = {
  id: 0,
  avatar: '',
  description: '',
  email: '',
  lose_count: 0,
  name: '',
  public_address: '',
  total_race: 0,
  win_count: 0,
  win_rate: '',
  total_horse: '',
  first_count: 0,
  second_count: 0,
  third_count: 0,
  guild_id: 0,
  role_in_guild: '',
  guild_tag: '',
  apply_guilds: [],
  black_guilds: []
}

export const profileSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (_, action: PayloadAction<CurrentUser>) => {
      return action.payload
    },

    logoutProfile: () => {
      return initialState
    },

    resetGuildId: state => {
      state.guild_id = 0
    }
  }
})

export const { setCurrentUser, logoutProfile, resetGuildId } = profileSlice.actions

export default profileSlice.reducer
