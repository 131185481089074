/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { CLOSE_BTN, ONE_LINE_ORANGE, ICON_ADIL } from 'assets/images'
import { ItemShop, MasterShopItem } from 'models'
import { Modal } from 'shared'
import Button from 'shared/Button'
import SellItemModalStyled from './styled'
import SelectCustom from 'shared/Select'
import { getOnlyDecimal, toPlainString } from 'utils/helper'
import { NUMBER } from 'apps/constants'

interface SellItemModalProps {
  toggleIsModalOpen?: (value?: boolean) => void
  onCloseButtonClick: () => void
  title?: string
  itemData?: ItemShop
  onConfirm: () => void
  onChangeInput: (params: { price: number; currency: string }) => void
  value: number
  masterItems: MasterShopItem
}

function SellItemModal({
  value,
  onChangeInput,
  onConfirm,
  itemData,
  toggleIsModalOpen,
  onCloseButtonClick,
  title,
  masterItems
}: SellItemModalProps) {
  const optionsSelectCoin = [
    {
      name: 'ADIL',
      value: 'ADIL',
      image: ICON_ADIL
    }
  ]
  const [classSelect, setClassSelect] = useState('ADIL')
  const [params, setParams] = useState({ price: 0, currency: 'ADIL' })

  useEffect(() => {
    onChangeInput({ ...params, price: params.price, currency: params.currency })
  }, [params])

  const handleBackModalCancel = () => {
    onCloseButtonClick()
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = getOnlyDecimal(e.target.value, NUMBER.NUM_2147483647)
    setParams({ ...params, price: newValue })
  }
  const handleClickedTypeRarity = (item: string) => {
    setClassSelect(item)
    setParams({ ...params, currency: item })
  }
  const shopFee = (((value * 10 ** 5 * masterItems?.fee_percent) / 10 ** 7) * 10 ** 18) / 10 ** 18
  const userReceive = (value * 10 ** 18 - shopFee * 10 ** 18) / 10 ** 18

  return (
    <Modal onOverlayClick={toggleIsModalOpen}>
      <SellItemModalStyled>
        <div className='choose-horse-modal head'>
          <div className='header'>
            <p>
              <span className='title'>SELL ITEM</span>
            </p>
            <span className='line__under--title'></span>
          </div>
          {title && (
            <div className='race-name-container'>
              <p className='race-name color-orange text-uppercase d-flex align-items-center flex-column'>
                <span className='title'>
                  {title}
                  <img className='line-bottom' src={ONE_LINE_ORANGE} alt='' />
                </span>
              </p>
            </div>
          )}
          <button className='close-btn p-0 position-absolute' role='button' onClick={handleBackModalCancel}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className='confirm-horse'>
            <div className='text'>
              <p>
                You are about to sell <b>{itemData?.name}</b> from the shop
              </p>
              <div className='amount'>
                <div className='amount-btn'>
                  <input
                    type='text'
                    value={params.price}
                    onChange={handleInputChange}
                    placeholder='Enter a number'
                    className='input-amount'
                    onKeyDown={evt => ['e', 'E', '+', '-', ','].includes(evt.key) && evt.preventDefault()}
                  />
                  <SelectCustom
                    dataSelect={optionsSelectCoin}
                    nameSelect={classSelect}
                    onSelected={handleClickedTypeRarity}
                  />
                </div>
              </div>
              <div className='d-flex'>
                <div className='text-price'>
                  Shop fee: {toPlainString(shopFee.toFixed(10))}
                  <img src={ICON_ADIL} alt='' />
                </div>
              </div>
              <div className='d-flex'>
                <span className='text-price'>
                  You receive: {toPlainString(userReceive.toFixed(10))}
                  <img src={ICON_ADIL} alt='' />
                </span>
              </div>
            </div>
            <div className='confirm-horse-btns d-flex align-items-center justify-content-center'>
              <Button
                buttonName='SELL'
                onClickButton={onConfirm}
                width={150}
                height={35}
                disabled={!value || classSelect === ''}
              />
            </div>
          </div>
        </div>
      </SellItemModalStyled>
    </Modal>
  )
}

export default SellItemModal
