/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from 'antd'
import guildApi from 'apis/guildApi'
import { constants } from 'apps'
import { CHECK_BOX, CLOSE_BTN, TICK_BOX } from 'assets/images'
import Loading from 'features/Guild/pages/Loading'
import { ChooseHorseItem } from 'features/Race/components'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import { useAppSelector, useDebounce, useToggle, useUpdateEffect } from 'hooks'
import { GUILD_MESSAGE, SPECIAL_MESSAGE } from 'i18n/constants'
import { defaultRegionFilters, HorseAvailable, MODAL_TYPE, MyHorseListParams, notificationTemplate } from 'models'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'
import { Modal, SearchInput } from 'shared'
import Button from 'shared/Button'
import { handleAsyncRequest } from 'utils/helper'
import openNotification from 'utils/toast'
import ChooseHorseModalGuildFarmStyled from './styled'
import SelectCustom from 'shared/SelectCustom'
import { NUMBER, STRING_REGION } from 'apps/constants'

interface ChooseHorseModalGuildFarmProps {
  toggleIsModalOpen: (value?: boolean) => void
  onCloseButtonClick: () => void
  onRefresh: () => void
  // getHorseDetail: (value: HorseAvailable) => void
}

const myHorseListParams: MyHorseListParams = {
  limit: NUMBER.TEN,
  page: NUMBER.ONE
  // sort: 'horse_id-asc'
}

function ChooseHorseModalPutToGuild({
  toggleIsModalOpen,
  onCloseButtonClick,
  onRefresh
}: ChooseHorseModalGuildFarmProps) {
  const horseListRef = useRef<HTMLDivElement>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [params, setParams] = useState<MyHorseListParams>(myHorseListParams)
  const [total, setTotal] = useState(1)
  const [totalSelect, setTotalSelect] = useState(0)
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const { guild_id } = useParams()
  const [loading, setLoading] = useToggle(false)
  const [isChosenHorseModal, toggleIsChosenHorseModal] = useToggle(false)
  const [informationData, setInformationData] = useState<any>(null)
  const currentUser = useAppSelector(state => state.profile)
  const [message, setMessage] = useState<notificationTemplate>()
  const [openNotificationModal, toggleOpenNotificationModal] = useToggle(false)
  const [horseList, setHorseList] = useState<HorseAvailable[]>([])
  const [checkedHorsePrev, setCheckedHorsePrev] = useState<HorseAvailable[]>([])
  const [horseListBack, setHorseListBack] = useState<HorseAvailable[]>([])
  const [horseIdChecked, setHorseIdChecked] = useState<any[]>([])
  const [regionSelect, setRegionSelect] = useState('Region')

  const updatedCheckedState = (horse: any) => {
    const horses = horseList.map(item => {
      return item.id === horse.id ? { ...item, isChecked: !item.isChecked } : { ...item, isChecked: item.isChecked }
    })
    setHorseList(horses)
    const mergeArrays = (arrCur: any, arrChecked: any) => {
      return arrChecked.map((item: any) => {
        const matchedItemCur = arrCur.find((item5: any) => item5.id === item.id)
        if (matchedItemCur) {
          return { ...item, isChecked: matchedItemCur.isChecked }
        }
        return item
      })
    }
    const mergedArray = mergeArrays(horses, [...checkedHorsePrev, horse])
    const horseChecked = mergedArray.filter((item: any) => item.isChecked === true)
    setCheckedHorsePrev(horseChecked)
    setTotalSelect(horseChecked?.length)
  }

  const { t } = useTranslation()

  useEffect(() => {
    getInformationData()
  }, [guild_id])

  const getInformationData = async () => {
    setLoading(true)
    const [, result]: any = await handleAsyncRequest(
      guild_id ? guildApi.getGuildInfomationById(guild_id) : guildApi.getGuildInfomation()
    )
    setLoading(false)
    if (result) {
      setInformationData(result.data)
    }
  }

  const fetchListHorseInFarm = async () => {
    setIsLoading(true)
    const [error, result] = await handleAsyncRequest(guildApi.getMyHorsesInFarm({ ...params }))
    if (error) {
      setIsLoading(false)
      return
    }
    const records = result?.data.records
    setTotal(result?.data.total)
    const arrChecked = [...horseList, ...records]
    const horseChecked = arrChecked.reduce((accumulator, item) => {
      const matchingItem = checkedHorsePrev.find((obj: any) => obj.id === item.id)
      if (matchingItem) {
        accumulator.push({ ...item, isChecked: true })
      } else {
        accumulator.push({ ...item, isChecked: false })
      }
      return accumulator
    }, [])
    setHorseList(horseChecked)
    setIsLoading(false)
  }

  useEffect(() => {
    if (isChosenHorseModal) return
    fetchListHorseInFarm()
  }, [params])

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  useUpdateEffect(() => {
    setHorseList([])
    setParams({ ...params, page: NUMBER.ZERO, horseName: debounceSearchValue })
  }, [debounceSearchValue])

  const fetchMoreRaces = () => {
    if (!isLoading && !isChosenHorseModal) {
      if (params.page * params.limit >= total) return
      setParams({ ...params, page: params.page + NUMBER.ONE })
    }
  }

  const checkNoHorse = () => total === NUMBER.ZERO && debounceSearchValue.length === NUMBER.ZERO && !isLoading

  const layoutBodySpecialHorseModal = () => {
    if (checkNoHorse()) {
      return layoutNoHorse()
    } else return layoutHorseList()
  }

  const putHorseInGuildFarm = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      guildApi.putHorseArrToGuild(guild_id ? guild_id : currentUser.guild_id, { horse_ids: horseIdChecked })
    )
    setIsLoading(false)
    if (error) {
      setMessage?.({
        body: error.message,
        title: MODAL_TYPE.failed
      })
      toggleOpenNotificationModal()
    }
    if (result) {
      pushNotification(t(`${GUILD_MESSAGE}.successPutToGuild`), true)
      toggleIsModalOpen()
      onRefresh()
    }
  }

  const pushNotification = (description: string, isSuccess?: boolean) => {
    openNotification({
      message: '',
      description: description,
      className: isSuccess ? 'toast-success' : 'toast-error'
    })
  }

  const addHorseSpecial = async () => {
    executeScroll()
    toggleIsChosenHorseModal()
    setHorseListBack(horseList)
    const horsesSelect = checkedHorsePrev.reduce((prev: HorseAvailable[], cur) => {
      const { isChecked } = cur
      if (isChecked) {
        prev.push(cur)
      }
      return prev
    }, [])
    setHorseList(horsesSelect)
    const horsesId = horsesSelect.map(item => {
      if (item.isChecked === true) {
        return item?.id
      }
    })
    setHorseIdChecked(horsesId)
  }

  const handleBackBtnOnClick = () => {
    executeScroll()
    toggleIsChosenHorseModal()
    setHorseList(horseListBack)
    setHorseIdChecked([])
  }

  const executeScroll = () => horseListRef?.current?.scrollIntoView()

  const handleRegionFilterClicked = (item: string) => {
    setHorseList([])
    if (item?.toUpperCase() === STRING_REGION.SEOUL || item?.toUpperCase() === STRING_REGION.TOKYO) {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: item?.toUpperCase()
      })
    } else {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: ''
      })
    }
    setRegionSelect(item)
  }

  const layoutHorseList = () => {
    return (
      <>
        {isChosenHorseModal ? (
          <div className='text-title'> You have chosen this horse</div>
        ) : (
          <div className='search-horse-container d-flex align-items-center justify-content-between'>
            <div className='search-title color-white'> Total: {!isLoading && total} </div>
            <div className='text-title'> Select your horse </div>
            <div className='search__box'>
              <SearchInput
                onAutoFocus={true}
                searchValue={searchValue}
                handleSearchValueChanged={handleSearchValueChanged}
                customClass='search-input'
              />

              {/* region */}
              <SelectCustom
                dataSelect={defaultRegionFilters}
                nameSelect={regionSelect}
                onSelected={handleRegionFilterClicked}
                customClass='select-custom'
              />
            </div>
          </div>
        )}

        {total === 0 && debounceSearchValue.length != 0 && !isLoading ? (
          <div
            className='no-horse pt-5'
            dangerouslySetInnerHTML={{
              __html: t(`${SPECIAL_MESSAGE}.noHorseSearch`, { search: searchValue })
            }}
          />
        ) : (
          <InfiniteScroll
            dataLength={horseList.length}
            next={fetchMoreRaces}
            hasMore={true}
            loader={isLoading ? <Spin className='mt-2 mr-3 d-flex justify-content-center pt-5' /> : ''}
            height={horseIdChecked.length === 1 ? 220 : 420}
          >
            {horseList.length > 0 && (
              <div className='horse-list-container d-flex flex-column' ref={horseListRef}>
                {horseList.map((horse, index: number) => (
                  <div key={index} className='horse-item'>
                    <>
                      {horseIdChecked.length === 0 && (
                        <div className='check-box'>
                          {horse?.isChecked ? (
                            <img
                              src={TICK_BOX}
                              alt='checkbox'
                              className='check-box-img'
                              onClick={() => updatedCheckedState(horse)}
                            />
                          ) : (
                            <img
                              src={CHECK_BOX}
                              alt='checkbox'
                              className='check-box-img'
                              onClick={() => updatedCheckedState(horse)}
                            />
                          )}
                        </div>
                      )}
                      <ChooseHorseItem horse={horse} onHorseClick={() => updatedCheckedState(horse)} />
                    </>
                  </div>
                ))}
              </div>
            )}
          </InfiniteScroll>
        )}
      </>
    )
  }

  const layoutNoHorse = () => {
    return (
      <>
        <div className='no-horse-title'>THERE IS NO HORSE</div>
        <div className='btn-container'>
          <Button buttonName='Close' onClickButton={onCloseButtonClick} width={150} type='btnCancel' />
        </div>
      </>
    )
  }

  return (
    <Modal onOverlayClick={toggleIsModalOpen}>
      <ChooseHorseModalGuildFarmStyled>
        <div className='choose-horse-modal'>
          <button className='close-btn p-0 position-absolute' role='button' onClick={onCloseButtonClick}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className={`race-info-container color-primary`}>{t(`${GUILD_MESSAGE}.putHorseToGuild`)}</div>
          <div className='guild-info'>
            {loading ? (
              <Loading />
            ) : (
              <div className='head-guild'>
                <img src={informationData?.avatar} alt='avatar' width={40} />
                <span className='color-yellow'>[{informationData?.guild_tag}] </span>
                <span>{informationData?.name}</span>
              </div>
            )}
          </div>
          <div className={`${checkNoHorse() ? '' : 'line'}`}></div>

          {layoutBodySpecialHorseModal()}
          {/* {layoutNoHorse()} */}
          <div className='text-confirm-container'>
            {isChosenHorseModal ? (
              <div className='register-horse'>
                <div className='text-confirm'>
                  When horse are put on the guild farm, the guild managers have the right to manage them.
                  <br />
                  Are you sure you want to put this horse on the guild farm?
                </div>
                <div className='btn-group'>
                  <Button
                    buttonName='No'
                    onClickButton={handleBackBtnOnClick}
                    type='btnCancel'
                    width={147}
                    height={42}
                  />
                  <Button buttonName='Yes' onClickButton={putHorseInGuildFarm} width={147} height={42} />
                </div>
              </div>
            ) : (
              total > 0 && (
                <Button
                  disabled={totalSelect === 0}
                  buttonName={`Select (${totalSelect})`}
                  onClickButton={addHorseSpecial}
                />
              )
            )}
          </div>
        </div>
        {openNotificationModal && (
          <ConfirmOkModal
            toggleIsModalOpen={toggleOpenNotificationModal}
            onCloseButtonClick={toggleOpenNotificationModal}
            onConfirm={toggleOpenNotificationModal}
            message={<span> {message?.body} </span>}
            title={message?.title}
          />
        )}
      </ChooseHorseModalGuildFarmStyled>
    </Modal>
  )
}

export default ChooseHorseModalPutToGuild
