import { AVATAR_DEFAULT } from 'assets/images'
import { useGetRankInfo } from 'features/Home/hooks'
import { Options, TopStable } from 'models'
import { useEffect, useRef } from 'react'
import { shortenUserName } from 'utils/helper'
import VanillaTilt from 'vanilla-tilt'
import TopStableBoxStyled from './styled'
import GuildInfo from '../GuildInfor'
// import VipIcon from 'features/components/VipIcon'

interface TopStableBoxProps {
  stable: TopStable
  selfIndex: number
  customClass?: string
  options: Options
  isTopOwners?: boolean
}

function TopStableBox({ stable, selfIndex, customClass = '', options, isTopOwners }: TopStableBoxProps) {
  const { medal, position, topFrame, boderFrame, boderAvt } = useGetRankInfo(selfIndex)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tilt = useRef<HTMLElement | any>(null)

  useEffect(() => {
    VanillaTilt.init(tilt.current, options)
  }, [options])

  return (
    <TopStableBoxStyled
      position={position}
      topFrame={topFrame}
      boderFrame={boderFrame}
      boderAvt={boderAvt}
      className={customClass}
      style={{
        marginTop: selfIndex === 1 ? '-30px' : 0
      }}
    >
      <div className='top-stable d-flex flex-column align-items-center mx-auto position-relative' ref={tilt}>
        <div className='top-frame position-absolute w-100 d-none d-lg-block' />
        <img src={medal} alt='' className='medal position-absolute' />
        {/* {stable?.msp === 1 && (
          <div className='vip-icon'>
            <VipIcon />
          </div>
        )} */}
        <div className='avatar-container d-flex align-items-center justify-content-center'>
          <img src={stable?.avatar ?? AVATAR_DEFAULT} alt={stable?.name} className='avatar' />
        </div>
        <div className='name color-white font-bold'>
          {`${stable?.guild ? `[${stable?.guild?.guild_tag}]` : ''}`} {shortenUserName(stable?.name)}
        </div>
        <div className='d-flex achievement text-center color-white font-bold my-1'>
          <GuildInfo career={stable} isTopOwners={isTopOwners} />
        </div>
      </div>
    </TopStableBoxStyled>
  )
}

export default TopStableBox
