/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CAREER_BIRU_OLD_ICON, CAREER_KUDA_ICON, CAREER_MERAH_ICON, CAREER_MERAH_OLD_ICON } from 'assets/images'
import { convertPluralText } from 'utils/helper'
import IncomeBoxContainer from './styled'
import { convertFullDate } from 'utils/time'
import { CURRENCY_TYPE, NUMBER } from 'apps/constants'

interface IncomeBoxProps {
  data: any
}

const IncomeBox = ({ data }: IncomeBoxProps) => {
  const guildInfo = data?.guild_info
  const lendingInfo = data?.lending_info

  const renderRevenue = (items: any) => {
    let coinMerah: any = 0
    let coinMerahNew: any = 0
    let coinKudaNew: any = 0
    let coinBiru: any = 0
    items.map((item: any) => {
      switch (item?.item_type) {
        case CURRENCY_TYPE.MERAH: {
          return (coinMerah = item?.amount || 0)
        }
        case CURRENCY_TYPE.MERAH_NEW: {
          return (coinMerahNew = item?.amount || 0)
        }
        case CURRENCY_TYPE.KUDA_NEW: {
          return (coinKudaNew = item?.amount || 0)
        }
        case CURRENCY_TYPE.BIRU: {
          return (coinBiru = item?.amount || 0)
        }
        default:
          break
      }
    })
    return (
      <>
        <div className='row-revenue-total'>
          <div className='row-revenue'>
            <span>{coinMerahNew}</span>
            <img src={CAREER_MERAH_ICON} alt='merah' />
          </div>
          <div className='row-revenue'>
            <span>{coinMerah}</span>
            <img src={CAREER_MERAH_OLD_ICON} alt='merah' />
          </div>
        </div>
        <div className='row-revenue-total'>
          <div className='row-revenue'>
            <span>{coinKudaNew}</span>
            <img src={CAREER_KUDA_ICON} alt='merah' />
          </div>
          <div className='row-revenue'>
            <span>{coinBiru}</span>
            <img src={CAREER_BIRU_OLD_ICON} alt='merah' />
          </div>
        </div>
      </>
    )
  }

  return (
    <IncomeBoxContainer>
      <div className='content-left'>
        <div>
          <p className='title'>Share income</p>
          <span>{guildInfo?.profit_renter ? guildInfo?.profit_renter + '%' : '---'} </span>
        </div>
        <div>
          <p className='title'>Start at</p>
          <span>{lendingInfo?.start_date ? convertFullDate(lendingInfo.start_date * NUMBER.THOUSAND) : '---'}</span>
        </div>
        {/* fix */}
        <div className='revenue-box'>
          <p className='title'>Total Revenue</p>
          {/* <div className='row-revenue'> */}
          {data?.lending_info?.revenue ? renderRevenue(data?.lending_info?.revenue) : <span>---</span>}
          {/* </div> */}
        </div>
      </div>
      <div className='content-right'>
        <div>
          <p className='title'>Duration</p>
          <span>{lendingInfo?.rent_days ? convertPluralText(lendingInfo?.rent_days, 'day') : '---'}</span>
        </div>
        <div>
          <p className='end'>End at</p>
          <span>{lendingInfo?.end_date ? convertFullDate(lendingInfo.end_date * NUMBER.THOUSAND) : '---'}</span>
        </div>
      </div>
    </IncomeBoxContainer>
  )
}

export default IncomeBox
