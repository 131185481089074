import styled from 'styled-components'

interface StyledProps {
  colorHorse: string
}

const HorseStatsContainer = styled.div<StyledProps>`
  display: flex;
  justify-content: space-between;

  span {
    color: #fff;
    font-size: 16px;
  }

  .title {
    font-size: 16px;
    color: #fff566;
    margin-bottom: 0;
  }

  .title__stats {
    display: inline-block;
    width: 60px;
  }

  .content-left {
    width: 120px;

    .horse_runtype {
      word-break: break-all;
      min-height: 60px;
      margin-right: 5px;
    }
  }

  .content-right {
    flex: 1;

    .stats {
      height: 26px;
      line-height: 26px;
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    .stats_ability {
      margin-top: 5px;
    }

    .content_stats {
      padding-left: 5px;
    }
  }

  .box__bloodline {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -10px;

    .image_bloodline {
      height: 60px;
      width: 80px;
      cursor: pointer;
    }

    .image_run_type {
      height: 20px;
      width: 80px;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .image_charactic {
      height: 45px;
      width: 45px;
      margin: 10px 0;
      cursor: pointer;
    }

    .image_color {
      display: block;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: ${({ colorHorse }) => colorHorse};
      border: 1px solid #fff;
      cursor: pointer;
    }
  }

  .img__ability {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
`

export default HorseStatsContainer
