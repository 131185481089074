/* eslint-disable @typescript-eslint/no-explicit-any */
import userApi from 'apis/userApi'
import { links, paths } from 'apps'
import { AVATAR_DEFAULT, BAR_PRIMARY, SETTING } from 'assets/images'
import { AttributeBox } from 'features/Horse/components'
import ConnectH2hModal from 'features/Profile/components/ConnectH2Hmodal'
import UpdateInforModal from 'features/Profile/components/UpdateInforModal'
import { setCurrentUser } from 'features/Profile/profile.slice'
import { useAppDispatch, useAppSelector, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { CurrentUser, MyHorseListParams } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useOutlet } from 'react-router-dom'
import { handleAsyncRequest, shortenUserName } from 'utils/helper'
import { getCurrentUser, getSigner } from 'utils/metamask'
import ProfileCloneStyled from './styled'
import LazyLoad from 'react-lazy-load'

export default function ProfileClone() {
  const myHorseListParams: MyHorseListParams = {
    limit: 5,
    page: 1,
    ownStatus: null,
    search: null
  }
  const outlet = useOutlet()

  const profile = useAppSelector(state => state.profile)

  const [openUpdateInforModal, setUpdateInforModal] = useToggle(false)
  const [openConnectH2hModal, setOpenConnectH2hModal] = useToggle(false)
  const [career, setCareer] = useState<string>('')
  const [numberHorse, setNumberHorse] = useState<string>('')
  const [winRate, setWinRate] = useState<string>('')
  const [, setIsH2H] = useState<boolean>(false)
  const [, setH2HId] = useState<string>('')
  const [total, setTotal] = useState<number>(0)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const auth = useAppSelector(state => state.auth)

  const handleTotalStatistic = (currentUser: CurrentUser) => {
    const totalRace = currentUser.total_race
    setTotal(totalRace)
    const firstClass = currentUser.first_count
    const secondClass = currentUser.second_count === null ? 0 : currentUser.second_count
    const thirdClass = currentUser.third_count === null ? 0 : currentUser.third_count
    const tltClass = (firstClass + secondClass + thirdClass) / totalRace
    const newTltClass = isNaN(tltClass) ? 0 : tltClass * 100
    const totalStatistic = ` - ${firstClass}/${secondClass}/${thirdClass}`
    const winRateResult = `${
      Number.isInteger((firstClass / totalRace) * 100)
        ? (firstClass / totalRace) * 100
        : ((firstClass / totalRace) * 100).toFixed(2)
    }% - ${Number.isInteger(newTltClass) ? newTltClass : newTltClass.toFixed(2)}%`
    const handleWinRate = () => {
      let valueDisplay = ''
      if (totalRace === 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
        valueDisplay = '---'
      }
      if (totalRace > 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
        valueDisplay = '0.00% - 0.00%'
      }
      if (totalRace !== 0 && (firstClass !== 0 || secondClass !== 0 || thirdClass !== 0)) {
        valueDisplay = winRateResult
      }
      return valueDisplay
    }
    setCareer(totalStatistic)
    setWinRate(handleWinRate())
    if (currentUser?.h2h_id) {
      setIsH2H(true)
      setH2HId(currentUser?.h2h_id)
    }
  }
  const { t } = useTranslation()

  const fetchListRaces = async () => {
    const [error]: any = await handleAsyncRequest(userApi.getUserHorseList(myHorseListParams))
    if (error?.code === 403) {
      window.location.href = paths.auth.login()
    }
  }

  const fetchCurrent = async () => {
    const [error, currentUser]: any = await handleAsyncRequest(getCurrentUser())
    if (error?.code === 403) {
      window.location.href = paths.auth.login()
    }
    if (!currentUser) return

    handleTotalStatistic(currentUser)
    setNumberHorse(currentUser.total_horse)
  }

  // const fetchSyncHorse = async () => {
  //   const [, result]: any = await handleAsyncRequest(userApi.getSyncHorse({ loginFlag: 0 }))
  //   if (!result) return
  // }

  useEffect(() => {
    const checkSignerProfile = async () => {
      const [signerError] = await handleAsyncRequest(getSigner())
      if (signerError) {
        navigate(links.home.index())
      }
    }

    const checkCurrentUser = async () => {
      const [, currentUser]: any = await handleAsyncRequest(getCurrentUser())
      if (!currentUser) return

      dispatch(setCurrentUser(currentUser))
    }

    checkSignerProfile()
    checkCurrentUser()
    fetchListRaces()
  }, [])

  // useEffect(() => {
  //   fetchSyncHorse()
  // }, [])

  useEffect(() => {
    if (auth.isLogged) {
      fetchCurrent()
    }
  }, [auth])

  const handleSetting = () => {
    setUpdateInforModal(true)
  }

  const handleCancelUpdate = () => {
    setUpdateInforModal(false)
  }

  const handleCancelConnectH2hModal = () => {
    setOpenConnectH2hModal(false)
  }

  return (
    <ProfileCloneStyled total={total}>
      <div className='header-top'>
        <div className='infor-body'>
          <div className='avatar'>
            <LazyLoad>
              <img src={profile.avatar ?? AVATAR_DEFAULT} alt='' className='avatar' loading='lazy' />
            </LazyLoad>
          </div>
          <div className='information-block'>
            <div className='name-profile'>
              <span className='color-white text-uppercase'>
                {profile?.guild_tag?.length > 0 && `[${profile?.guild_tag}]`} {shortenUserName(profile.name)}
              </span>
            </div>
            <div className='address-wallet'>
              <span className='color-white text-uppercase'>{profile.public_address}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='undisableH2H mt-3'>
        <span className='setting'>
          <button onClick={handleSetting}>
            <img src={SETTING} alt='' />
            <span className='color-white '>{t(`${NOTIFICATION_MESSAGE}.settings`)}</span>
          </button>
        </span>
      </div>
      <div className='attribute-container mt-3 d-flex justify-content-center'>
        <div className='width-attributeBox'>
          <AttributeBox title='HORSE NUM' value={numberHorse ?? ''} />
        </div>
        <div className='width-attributeBox'>
          <AttributeBox title='CAREER' value={career ?? ''} total={total} />
        </div>
        <div className='width-attributeBox'>
          <AttributeBox title='WIN RATE' value={winRate ?? ''} />
        </div>
      </div>
      <div className='mt-1'>
        <img src={BAR_PRIMARY} alt='' />
      </div>

      <div>{outlet}</div>
      {openConnectH2hModal && (
        <ConnectH2hModal
          onIsH2H={setIsH2H}
          toggleIsModalOpen={setOpenConnectH2hModal}
          onCancelModal={handleCancelConnectH2hModal}
          onUpdateId={fetchCurrent}
        />
      )}
      {openUpdateInforModal && (
        <UpdateInforModal
          toggleIsModalOpen={setUpdateInforModal}
          onCancelUpdate={handleCancelUpdate}
        ></UpdateInforModal>
      )}
    </ProfileCloneStyled>
  )
}
