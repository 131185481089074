/* eslint-disable @typescript-eslint/no-explicit-any */
import { AVATAR_DEFAULT } from 'assets/images'
import { GUILD_STATUS, GuildHorseInfo, GuildInforManager, LendingHorseInfo } from 'models'
import { useEffect, useState } from 'react'
import { shortenUserName } from 'utils/helper'
import LendingTableStyled from './styled'

interface ILendingTableProps {
  onChange?: (item: any, type: string) => void
  timeLending: any
  disabledSelect?: boolean
  lendingInfo?: LendingHorseInfo | GuildHorseInfo
  isActive?: boolean
  messageError?: string
  guildInfor?: GuildInforManager
}
const headers = ['Duration']

function LendingTable({
  messageError,
  onChange,
  timeLending,
  disabledSelect,
  lendingInfo,
  isActive,
  guildInfor
}: ILendingTableProps) {
  const [priceConfig, setPriceConfig] = useState<any>()

  useEffect(() => {
    if (lendingInfo?.rent_days) {
      setPriceConfig([{ day: lendingInfo?.rent_days, value: lendingInfo.rent_fee, active: true }])
    } else setPriceConfig(timeLending)
  }, [timeLending])

  const handleKeyDown = (e: any) => {
    const typedValue = e.keyCode
    if (typedValue < 48 && typedValue > 57) {
      // If the value is not a number, we skip the min/max comparison
      return
    }

    const typedNumber = parseInt(e.key)
    const min = parseInt(e.target.min)
    const max = parseInt(e.target.max)
    const currentVal = parseInt(e.target.value) || ''
    const newVal = parseInt(currentVal.toString() + typedNumber.toString())

    if (newVal < min || newVal > max || ['e', 'E', '+', '-', ',', '.'].includes(e.key)) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <LendingTableStyled>
      <div className='horse-table-container'>
        <div className='info'>
          <div className='info-owner-wrap'>
            <div className='info-owner'>
              <img src={guildInfor?.avatar} alt='' className='avatar rounded-circle' />
              <div className='info-owner-name'>
                <div className='title'> Guild </div>
                <div className='owner-name'> {shortenUserName(guildInfor?.name)}</div>
              </div>
            </div>
            <div className='color-orange-200'> {guildInfor?.profit_guild}% </div>
          </div>
          {lendingInfo?.owner && (
            <div className='info-owner-wrap'>
              <div className='info-owner'>
                <img src={lendingInfo?.owner?.avatar ?? AVATAR_DEFAULT} alt='' className='avatar rounded-circle' />
                <div className='info-owner-name'>
                  <div className='title'> Owner</div>
                  <div className='owner-name'> {shortenUserName(lendingInfo?.owner?.name)}</div>
                </div>
              </div>
              <div className='color-orange-200'> {guildInfor?.profit_owner}% </div>
            </div>
          )}

          <div className='info-owner-wrap'>
            <div className='info-owner'>
              <img src={lendingInfo?.renter?.avatar ?? AVATAR_DEFAULT} alt='' className='avatar rounded-circle' />
              <div className='info-owner-name'>
                <div className='title'> Renter </div>
                <div className='owner-name'>
                  {' '}
                  {lendingInfo?.renter ? shortenUserName(lendingInfo?.renter?.name) : '---'}
                </div>
              </div>
            </div>
            <div className='color-orange-200'> {guildInfor?.profit_renter}% </div>
          </div>

          {/* 
          {lendingInfo?.renter && (
            <div className='info-owner-wrap'>
              <div className='info-owner'>
                <img src={lendingInfo?.renter?.avatar ?? AVATAR_DEFAULT} alt='' className='avatar rounded-circle' />
                <div className='info-owner-name'>
                  <div className='title'> Borrower</div>
                  <div className='owner-name'> {shortenUserName(lendingInfo?.renter?.name)}</div>
                </div>
              </div>
              {lendingInfo?.lending_status === GUILD_STATUS.Borrowed &&
                lendingInfo?.lending_type === LENDING_TYPE.Private && (
                  <div className='info-owner-percent'>
                    <span>{`${Number(100 - lendingInfo?.rent_fee)}`} %</span>
                  </div>
                )}
              <div className='color-orange-200'> {guildInfor?.profit_renter}% </div>
            </div>
          )} */}
        </div>
        <div className='horse-table-header d-flex px-3 justify-content-between'>
          {headers.map((header, index) => (
            <div key={index}>{header}</div>
          ))}
        </div>
        {priceConfig?.map((item: any, index: number) => (
          <div key={index} className={`horse-item ${item?.active ? 'active' : ''}`}>
            <div
              className={`lease-price ${
                item?.active && lendingInfo?.lending_status === GUILD_STATUS.InFarm ? 'out-line' : ''
              }`}
            >
              <div className='lease-price-input'>
                <input
                  className='me-4 input-custom'
                  id={item.id}
                  value={priceConfig}
                  type='checkbox'
                  checked={isActive ? isActive : item?.active}
                  onChange={() => onChange?.(item, 'checked')}
                  width={50}
                />
                <input
                  disabled={disabledSelect || item.active === false}
                  type='number'
                  id={item.id}
                  className='day-input input-custom'
                  value={item.day.length >= 2 ? item.day.replace(/^0/, '') : item.day}
                  placeholder='0'
                  min={1}
                  max={364}
                  onKeyDown={handleKeyDown}
                  onChange={e => onChange?.(e, 'day')}
                  onPaste={e => {
                    e.preventDefault()
                    return false
                  }}
                  maxLength={3}
                />
                {item.day <= 1 ? 'day' : 'days'}
              </div>
            </div>
          </div>
        ))}
        {messageError && messageError?.length > 0 ? <div className='color-red message-err'> {messageError}</div> : ''}
      </div>
    </LendingTableStyled>
  )
}

export default LendingTable
