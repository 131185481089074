import styled from 'styled-components'

const UserInfoContainer = styled.div`
  margin: 20px 0;
  vertical-align: middle;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(86, 36, 95, 1) 0%, rgba(114, 68, 160, 1) 80%, rgba(4, 19, 37, 1) 100%);
  padding: 5px 0;

  .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    /* margin-left: 50px; */

    .img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .center {
    color: #fff;

    span {
      color: #fff;
    }

    &.text {
      span {
        line-height: 40px;
      }
    }
  }

  .left {
    color: #fff;
    text-align: left;
    /* padding-left: 105px; */

    span {
      color: #fff;
      line-height: 40px;
    }
  }
`

export default UserInfoContainer
