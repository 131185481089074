/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import tooltipInfoApi from 'apis/tooltipInfoApi'
import { handleAsyncRequest } from 'utils/helper'

export const getTooltipInfo = createAsyncThunk('tooltip/getTooltipInfo', async () => {
  const [error, result] = await handleAsyncRequest(tooltipInfoApi.getTooltipInfo())
  if (error) {
    return (error as any)?.message
  }
  return (result as any)?.data
})
