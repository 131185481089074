import styled from 'styled-components'

const SelectItemModalStyled = styled.div`
  .modal-select-item {
    width: 620px;
    background: #1e1a24;
    padding: 40px 35px;
    position: relative;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 40px 100%, 0px calc(100% - 40px));
    .confirm-horse {
      text-align: center;
      h4 {
        font-size: 24px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        color: #bf48f7;
        margin-bottom: 0;
      }
      .iframe {
        width: 212px;
      }
    }
    .confirm-horse-title {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 32px;
    }
    .close-btn {
      right: 23px;
      top: 19px;
      background: transparent;
      border: none;
    }
    .confirm-horse-btns {
      button {
        background: transparent;
        border: none;
        margin: 0 16.5px;
        position: relative;
        filter: brightness(0.8);

        span {
          position: absolute;
          width: 100%;
          margin: 0 auto;
          left: 0;
          color: #fff;
          top: 8px;
          font-size: 24px;
        }
        &:hover {
          filter: brightness(1);
        }
        &.disable {
          filter: brightness(0.5);
          cursor: not-allowed;
        }
      }
    }
    .list-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 40px;
      height: 193px;
      overflow: auto;
      .confirm-horse-title {
        width: 100%;
      }
      &.infinite-scroll-component::-webkit-scrollbar-track {
        border: 1px solid #000;
        padding: 2px 0;
        background-color: #000;
      }

      &.infinite-scroll-component::-webkit-scrollbar {
        width: 5px;
      }

      &.infinite-scroll-component::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #2f323e;
        border: 1px solid #000;
      }
      .meta-block {
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        &:nth-child(4n) {
          margin-right: 0px;
        }
        &.active,
        &:hover {
          .item-block {
            background-image: linear-gradient(#4ff077, #48f7b9);
            .sub-block {
              background-image: linear-gradient(#4ff077, #48f7b9);
            }
            .numb {
              img {
                filter: none;
              }
              span {
                color: black;
                border: 1px solid black;
                width: 38px;
                height: 38px;
                border-radius: 50%;
                padding: 3px;
              }
            }
          }
        }
      }
      .item-block {
        clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
        background: #515460;
        padding: 2px;
        position: relative;
        .numb {
          position: absolute;
          top: 5%;
          left: 5%;
          img {
            width: 38px;
          }
          span {
            color: #fff566;
            font-size: 18px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 15%;
          }
        }
        .sub-block {
          background: #030317;
          clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
          position: relative;
          .amount {
            position: relative;
            justify-content: center;
            padding-bottom: 3px;
            .star {
              .disable {
                display: none;
              }
            }
          }
          .star {
            div {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
      .item-dp {
        background: transparent;
        border: none;
        padding: 17px 0;
        &:focus-visible {
          outline: none;
        }
        img {
          height: 70px;
        }
      }
      .count-item {
        position: relative;
        width: 120px;
        span {
          position: absolute;
          width: 100%;
          text-align: center;
          z-index: 1;
          top: 12px;
          left: 0px;
          font-size: 16px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          padding: 0 5px;
        }
        img {
          width: 100%;
          height: 44px;
          margin-top: 4px;
        }
      }
    }
  }
`

export default SelectItemModalStyled
