import styled from 'styled-components'
import { MANAGER_BG } from 'assets/images'

const ManagerStyled = styled.div`
  width: 100%;
  max-width: 775px;
  margin: 0 auto;
  .guild-manager {
    width: 100%;
    .basic-infor {
      max-width: 1044px;
      height: 100%;
      padding: 2px;
      color: white;
      margin: 0 auto;
      clip-path: polygon(98% 0%, 100% 20px, 100% 100%, 0 100%, 0 0);
      background: linear-gradient(90deg, rgba(19, 22, 33, 0) -1.19%, #4d5984 101.28%);
    }
    .basic-infor-group {
      background-color: white;
      padding: 0.25rem 0.75rem 0.25rem 0.5rem;
      clip-path: polygon(98% 0%, 100% 20px, 100% 100%, 0 100%, 0 0);
      background: linear-gradient(90deg, #121520 -1.15%, #191d2c 100%);
      align-items: center;
    }

    .guild-income {
      max-width: 775px;
      height: 260px;
      padding: 2px;
      margin: 0 auto;
      margin-top: 20px;
      color: white;
      background: linear-gradient(90deg, rgba(18, 21, 32, 0.5) 0%, #96acff 50.52%, rgba(18, 21, 32, 0.5) 100%),
        linear-gradient(90deg, #121520 -3.73%, #191d2c 48.95%, #121520 101.97%);
      background: url(${MANAGER_BG});
    }
    .income-content {
      padding: 0.25rem 0.75rem 0.25rem 0.5rem;
      /* background: #191d2c; */
    }

    .guild-avatar {
      border-radius: 50%;
    }
    .income-text {
      color: #df9036;
      font-size: 28px;
      text-align: center;
      margin: 10px 0;
    }

    .income-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      max-width: 396px;
      margin: 0 auto;
      margin-bottom: 10px;

      span {
        display: inline-block;
        font-size: 18px;

        &:last-child {
          background: #30343f;
          padding: 4px 30px;
          border: 1px solid #9c9c9c;
          min-width: 99px;
          text-align: center;
        }

        &.text {
          &:last-child {
            background: transparent;
            padding: 4px 30px;
            border: none;
            min-width: 99px;
            text-align: center;
            padding-left: 40px;
          }
        }
      }
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-spin-dot-item {
      background-color: #4ef076 !important;
    }
  }

  .line {
    width: 100%;
    margin-top: 30px;

    img {
      width: 100%;
    }
  }

  .career-horse {
    display: inline-block;
    padding-right: 5px;
  }

  .button-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 30px;
  }

  .avatar-box {
    align-items: center;
    .avatar {
      margin-right: 10px;
    }
    .name {
      font-size: 18px;

      &.role {
        span {
          text-transform: lowercase;
          display: inline-block;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .head {
    color: rgb(255, 245, 102);
    font-size: 18px;
  }

  .infor {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
`

const FormContainer = styled.div`
  .income-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    span {
      display: inline-block;
      color: #fff;
      font-size: 16px;

      &:last-child {
        background: #30343f;
        padding: 4px 30px;
        border: 1px solid #9c9c9c;
        min-width: 99px;
        text-align: center;
        display: inline-block;
      }
    }

    .input-box {
      .ant-form-item-control-input-content {
        border: 1px solid rgb(156, 156, 156);
        background: rgb(48, 52, 63);
      }
      .ant-form-item-control-input {
        width: 99px;
        position: relative;

        &:after {
          position: absolute;
          content: '%';
          top: 5px;
          right: 32px;
          color: #fff;
          font-size: 16px;
        }
      }

      input {
        background: rgb(48, 52, 63);
        box-shadow: none;
        border: none;

        width: 65px;
        height: 28px;
        text-align: right;
        color: #fff;
        font-size: 18px;

        &.ant-input-status-error,
        &:hover,
        &:focus {
          background: rgb(48, 52, 63) !important;
          border: none !important;
          border-color: transparent !important;
          box-shadow: none !important;
        }
      }

      span {
        position: absolute;
        right: 22px;
        top: 1px;

        background: transparent !important;
        padding: 0 !important;
        border: none !important;
        min-width: 0 !important;
        text-align: center;
        display: inline-block;
        padding-left: 2px;
      }

      .ant-form-item {
        margin: 0;

        &.ant-form-item-has-error {
          margin: 0 0 24px;
        }
      }
    }

    .ant-form-item-explain {
      position: absolute;
      min-width: 200px;
      right: 0;
      text-align: right;
    }
  }
`

export { ManagerStyled, FormContainer }
