/* eslint-disable @typescript-eslint/no-explicit-any */
import { RECTANGLE_1 } from 'assets/images'
import { convertTimestampToDate, shortenRaceNameV2 } from 'utils/helper'

import InforBoxContainer from './styled'

interface masterGuild {
  max_member: number
  exp_to_level_up: number
  max_horse: number
}

interface infoProps {
  informationData: {
    avatar: string
    guild_tag: string
    name: string
    level: string
    created?: any
    role_in_guild: string
    total_member: number
    master_guild_level: masterGuild
    total_horse: number
    total_exp: number
    guild_master: string
  }
}

const InforBox = ({ informationData }: infoProps) => {
  return (
    <InforBoxContainer>
      <div className='info-box'>
        <div className='head'>
          <p>Guild info</p>
          <img src={RECTANGLE_1} alt='line' />
        </div>

        <div className='row'>
          <span>Founded in</span>
          <span>{convertTimestampToDate(informationData?.created)}</span>
        </div>
        <div className='row'>
          <span>Guild master</span>
          <span>{shortenRaceNameV2(informationData?.guild_master, 12)}</span>
        </div>
        <div className='row'>
          <span>Members</span>
          <span>
            {informationData?.total_member}/{informationData?.master_guild_level?.max_member}
          </span>
        </div>
        <div className='row'>
          <span>Horses</span>
          <span>
            {informationData?.total_horse}/{informationData?.master_guild_level?.max_horse}
          </span>
        </div>
        <div className='row'>
          <span>EXP</span>
          <span>
            {informationData?.total_exp}/{informationData?.master_guild_level?.exp_to_level_up}
          </span>
        </div>
      </div>
    </InforBoxContainer>
  )
}

export default InforBox
