import { ICON_ADIL, GAME_TOKEN_MERAH, GAME_TOKEN_KUDA } from 'assets/images'
import IconMerahOrKudatyled from './styled'

interface IconMerahOrKudaProps {
  type?: string
  price?: number
}

export default function IconMerahOrKuda({ type, price }: IconMerahOrKudaProps) {
  return (
    <IconMerahOrKudatyled>
      {price && parseFloat(price.toFixed(5))}{' '}
      {type === 'MERAH' || type === 'MERAH_NEW' ? (
        <img src={GAME_TOKEN_MERAH} alt='merah' className='icon merah' width={15} />
      ) : type === 'KUDA' || type === 'KUDA_NEW' ? (
        <img src={GAME_TOKEN_KUDA} alt='kuda' className='icon kuda' width={15} />
      ) : (
        <img src={ICON_ADIL} alt='adil' className='icon adil' width={15} />
      )}
    </IconMerahOrKudatyled>
  )
}
