/* eslint-disable @typescript-eslint/no-explicit-any */

import { AbilityBoxGuild } from 'features/Horse/components'
import Stats from 'features/components/Stats'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { Horse, HorseAbility } from 'models'
import { sortAbilities } from 'utils/helper'
import HorseCareerWinRates from '../HorseCareerWinRates'
import HorseBodyInfoStyled from './styled'
import { ICON_DOPING, SHADOW_DOPING, UNION } from 'assets/images'
import { getCharacteristic, getColorHorse, getImageBloodline, getRunType } from 'utils/getLinkImage'
import { STRING_BLOODLINE_CHARACTER } from 'apps/constants'
import { useState } from 'react'
import { useToggle } from 'hooks'
import ModalHorseBloodline from 'features/components/ModalHorseBloodline'
interface HorseBodyInfoProps {
  horse: Horse
  isLending?: boolean
  customClass?: string
}
function HorseBodyInfoGuild({ horse, isLending, customClass }: HorseBodyInfoProps) {
  const [bloodlineTitle, setBloodlineTitle] = useState('')
  const [bloodlineContent, setBloodlineContent] = useState('')
  const [openHorseBloodlineModal, toggleOpenHorseBloodlineModal] = useToggle(false)

  function handleCurrentAmountStats(currentType: any) {
    const found = horse?.boost_items_equipped.find(element => element.slot == currentType)
    if (found) {
      return found.amount
    } else {
      return 0
    }
  }
  function handleCurrentImgDoping(currentType: any) {
    const found = horse?.boost_items_equipped.find(element => element.slot == currentType)
    if (found) {
      return found.boost?.img
    } else {
      return 0
    }
  }

  const _handleClickImage = (event: React.MouseEvent, type: string, content: string) => {
    event.stopPropagation()
    setBloodlineTitle(type)
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  const _handleClosePopup = () => {
    toggleOpenHorseBloodlineModal(false)
  }

  return (
    <HorseBodyInfoStyled colorHorse={getColorHorse(horse?.bloodline?.color)}>
      {horse && (
        <div className={customClass}>
          <div className={`right-body ${isLending ? 'lending-container' : ''}`}>
            <div className='right'>
              <div className='box__bloodline'>
                {/* bloodline */}
                <div
                  onClick={event =>
                    _handleClickImage(
                      event,
                      STRING_BLOODLINE_CHARACTER.BLOODLINE,
                      `${horse?.bloodline?.name} - ${horse?.bloodline?.type}`
                    )
                  }
                  className='image_bloodline'
                >
                  <MemoizedLoadImageCommon srcImg={getImageBloodline(horse?.bloodline?.name)} />
                </div>

                {/* run type */}
                <div className='image_run_type'>
                  <MemoizedLoadImageCommon srcImg={getRunType(horse.run_type)} />
                </div>

                {/* characteristic */}
                <div className='image_charitic'>
                  <MemoizedLoadImageCommon srcImg={getCharacteristic(horse.characteristic_display)} />
                </div>

                {/* color */}
                <div className='image_color'></div>
              </div>
              <div className='career-win-rates'>
                <HorseCareerWinRates horse={horse} isLending={isLending} />
              </div>
              <div className='stats-body-container'>
                <Stats horse={horse} isLending={isLending} customClass='lending-stats' />
              </div>
              <div className='ability-container row gy-4'>
                {horse &&
                  sortAbilities(horse?.list_horse_ability)?.map((skill: HorseAbility, index: number) => (
                    <div className='col-12 col-xxl-6 col-sm-12' key={index}>
                      <AbilityBoxGuild horse={horse} name={skill.name_en} level={skill.level} />
                    </div>
                  ))}
              </div>
              <div className='ability-container doping-plus row gy-4 only-view'>
                <div className='col-12 col-xxl-6 col-sm-12'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <img src={UNION} alt='' className='frame-union' />
                    <div className='name'>ITEM PLUS</div>
                    {handleCurrentAmountStats('PLUS') == 0 ? (
                      <button className='push-doping new'>
                        <img src={ICON_DOPING} alt='' className='' />
                      </button>
                    ) : (
                      <button className='push-doping'>
                        <img className='shadown' src={SHADOW_DOPING} alt='' />
                        <img src={handleCurrentImgDoping('PLUS')} alt='' className='d-block' />
                        {handleCurrentAmountStats('PLUS') !== 0 && <span> {handleCurrentAmountStats('PLUS')}</span>}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openHorseBloodlineModal && (
            <ModalHorseBloodline title={bloodlineTitle} content={bloodlineContent} onClose={_handleClosePopup} />
          )}
        </div>
      )}
    </HorseBodyInfoStyled>
  )
}

export default HorseBodyInfoGuild
