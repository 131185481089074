/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

interface Props {
  width?: number
  height?: any
}

const ImageArmorSkinContainer = styled.div<Props>`
  .contain__img_armor_skin {
    display: flex;

    .img_armor_skin {
      width: ${p => `${p.width}px`};
      height: ${p => `${p.height}px`};
    }

    .img__line {
      width: 1px;
      height: ${p => `${p.height}px`};
      display: flex;
      align-items: center;
      div {
        height: ${p => (p.height > 20 ? `${p.height - 6}px` : `${p.height}px`)};
      }
    }

    .mg__left_5 {
      margin-left: 5px;
    }

    .pointer_armor_skin {
      cursor: pointer;
    }
  }
`
export default ImageArmorSkinContainer
