/* eslint-disable @typescript-eslint/no-explicit-any */
import { AVATAR_DEFAULT, CLOSE_BTN } from 'assets/images'
import { useAppSelector } from 'hooks'
import { Modal } from 'shared'
import ButtonCustom from 'shared/ButtonCustom'
import { ModalManageMemberStyle } from './styled'

interface MProps {
  onClose: () => void
  onConfirm?: () => void
  loading: boolean
  memberInfor?: any
  onClickPromote?: () => void
  onClickDowngrade?: () => void
  onClickGuildOut?: () => void
}

const ModalManageMember = ({
  onClose,
  loading,
  memberInfor,
  onClickPromote,
  onClickDowngrade,
  onClickGuildOut
}: MProps) => {
  const currentUser: any = useAppSelector(state => state.profile)

  const _onClickPromote = () => {
    onClickPromote?.()
  }
  const _onClickDowngrade = () => {
    onClickDowngrade?.()
  }
  const _onClickGuildOut = () => {
    onClickGuildOut?.()
  }

  const onDisabledBtnPromote = () => {
    if (currentUser.role_in_guild === 'MASTER' && memberInfor.role === 'MANAGER') {
      return true
    }
    if (currentUser.role_in_guild === 'MANAGER') return true
    else return false
  }

  const onDisabledBtnDowngrade = () => {
    if (currentUser.role_in_guild === 'MASTER' && memberInfor.role === 'MEMBER') {
      return true
    }
    if (currentUser.role_in_guild === 'MANAGER') return true
    else return false
  }

  return (
    <Modal>
      <ModalManageMemberStyle>
        <div className='header'>
          <p>
            <span className='title'>MANAGE</span>
          </p>
        </div>
        <button disabled={loading} className='close-btn p-0 position-absolute' role='button' onClick={onClose}>
          <img src={CLOSE_BTN} alt='close' />
        </button>
        <div className='content-box'>
          <div>
            <img src={memberInfor.avatar || AVATAR_DEFAULT} alt='avata' width={88} height={88} />
          </div>
          <div className='color-white'>
            <div>{memberInfor.member}</div>
            <div>{memberInfor.position}</div>
            <div>
              <div>
                <span> Career: </span>
                <span className='font-bold'>{memberInfor.career.total_number_of_races} </span>
                <span className='font-bold'>{memberInfor.career.first_count}/</span>
                <span className='font-bold'>{memberInfor.career.second_count}/</span>
                <span className='font-bold'>{memberInfor.career.third_count}</span>
              </div>
            </div>
            <div>
              <span>Win rate: </span>
              {memberInfor.winRate}
            </div>
          </div>
        </div>
        <div className='button-box'>
          <ButtonCustom onClickButton={_onClickPromote} buttonName='Promote' disabled={onDisabledBtnPromote()} />
          <ButtonCustom
            onClickButton={_onClickDowngrade}
            buttonName='Demote'
            type='warning'
            disabled={onDisabledBtnDowngrade()}
          />
          <ButtonCustom onClickButton={_onClickGuildOut} buttonName='Guild Out' type='danger' />
        </div>
      </ModalManageMemberStyle>
    </Modal>
  )
}

export default ModalManageMember
