/* eslint-disable @typescript-eslint/no-explicit-any */
import userApi from 'apis/userApi'
import { constants, links } from 'apps'
import HorseModal from 'features/Horse/components/HorseModal'
import { MyHorseItem } from 'features/Profile/components'
import { useAppSelector, useDebounce, useFetch, useIsMounted, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { defaultRegionFilters, Horse, MyHorseListParams, OWNER_STATUS, TypeSorts } from 'models'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import { Link, useSearchParams } from 'react-router-dom'
import { SearchInput } from 'shared'
import SelectCustom from 'shared/SelectCustom'
import ProfileCloneStyled from './styled'
import { LINE_BOT } from 'assets/images'
import { handleAsyncRequest } from 'utils/helper'
import ErrorModal from 'features/Horse/components/ErrorModal'
import { NUMBER, STRING_REGION } from 'apps/constants'
import ModalHorseBloodline from 'features/components/ModalHorseBloodline'

const defaultTypeFilter: TypeSorts = [
  {
    name: OWNER_STATUS.All,
    key: OWNER_STATUS.AllKey,
    isActive: false
  },
  {
    name: OWNER_STATUS.Owner,
    key: OWNER_STATUS.OwnerKey,
    isActive: false
  },
  {
    name: OWNER_STATUS.Lending,
    key: OWNER_STATUS.OwnerKey,
    isActive: false
  },
  {
    name: OWNER_STATUS.Guild,
    key: OWNER_STATUS.GuildKey,
    isActive: false
  }
]

export default function HorseList() {
  const myHorseListParams: MyHorseListParams = {
    limit: NUMBER.FIVE,
    page: NUMBER.ONE,
    ownStatus: null,
    search: null
  }

  const profile = useAppSelector(state => state.profile)
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)

  const [horseListParams, setHorseListParams] = useState<MyHorseListParams>(myHorseListParams)
  const [listHorse, setListHorse] = useState<Horse[]>([])
  const [isOpenHorseModal, setOpenHorseModal] = useState<boolean>(false)
  const [idBeChoose, setIdBeChoose] = useState<number>(0)
  const myName = profile.name

  const [numberHorse, setNumberHorse] = useState<string>('')
  const [pageCount, setPageCount] = useState<number>(0)
  const isMounted = useIsMounted()

  const [classSelect, setClassSelect] = useState('All')
  const [searchParams, setSearchParams] = useSearchParams() as any
  const paramsSearch = Object.fromEntries(searchParams.entries())
  const [isError, toggleIsError] = useToggle(false)
  const messageErrorRef = useRef('')
  const [regionSelect, setRegionSelect] = useState('Region')
  const [bloodlineTitle, setBloodlineTitle] = useState('')
  const [bloodlineContent, setBloodlineContent] = useState('')
  const [openHorseBloodlineModal, toggleOpenHorseBloodlineModal] = useToggle(false)

  const { data: horseListResponse } = useFetch(
    {
      fetcher: userApi.getUserHorseList,
      params: { ...horseListParams, page: paramsSearch.page ? +paramsSearch.page : horseListParams.page }
    },
    [horseListParams]
  )

  const { t } = useTranslation()

  const handleSearchValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    setSearchParams({ page: 1 })
    setHorseListParams({ ...horseListParams, page: 1, search: searchValue })
  }, [debounceSearchValue])

  const closeHorseModal = () => {
    setOpenHorseModal(false)
  }

  const closeModalError = () => {
    setOpenHorseModal(false)
    toggleIsError(false)
  }

  const horseNFTNotExist = (notExit: any) => {
    if (notExit) {
      messageErrorRef.current = 'Horse NFT does not exist.'
      toggleIsError(true)
    }
  }

  const handleOpenModalHorse = async (id: number) => {
    const [error]: any = await handleAsyncRequest(userApi.getSyncHorseDetail(id?.toString()))
    if (error?.code === 400 && error?.message === 'Horse NFT does not exist.') {
      messageErrorRef.current = error?.message || ''
      toggleIsError(true)
      return
    }
    setIdBeChoose(id)
    setOpenHorseModal(true)
  }

  useEffect(() => {
    if (!horseListResponse || !isMounted()) {
      return
    }
    setListHorse(horseListResponse.records)
    setPageCount(horseListResponse.total_page)
    setNumberHorse(horseListResponse.total.toString())
  }, [horseListResponse])

  const handlePageClick = (event: any) => {
    setSearchParams({ page: event.selected + NUMBER.ONE })
    setHorseListParams({ ...horseListParams, page: event.selected })
  }

  const handleKeyFilter = (status: string) => {
    switch (status) {
      case OWNER_STATUS.All:
        return null
      case OWNER_STATUS.Owner:
        return OWNER_STATUS.OwnerKey
      case OWNER_STATUS.Lending:
        return OWNER_STATUS.LendingKey
      case OWNER_STATUS.Guild:
        return OWNER_STATUS.GuildKey
      default:
        return null
    }
  }
  const handleClassFilterClicked = (item: string) => {
    setSearchParams({ page: NUMBER.ONE })
    setListHorse([])
    setSearchValue('')
    setHorseListParams({ ...horseListParams, ownStatus: handleKeyFilter(item), search: '' })
    setClassSelect(item)
  }

  const handleRegionFilterClicked = (item: string) => {
    setSearchParams({ page: NUMBER.ONE })
    setListHorse([])
    if (item?.toUpperCase() === STRING_REGION.SEOUL || item?.toUpperCase() === STRING_REGION.TOKYO) {
      setHorseListParams({ ...horseListParams, region: item?.toUpperCase() })
    } else {
      setHorseListParams({ ...horseListParams, region: '' })
    }
    setRegionSelect(item)
  }

  const handleOpenModalBloodline = (type: string, content: string) => {
    setBloodlineTitle(type)
    setBloodlineContent(content)
    toggleOpenHorseBloodlineModal(true)
  }

  const _handleClosePopup = () => {
    toggleOpenHorseBloodlineModal(false)
  }

  return (
    <ProfileCloneStyled>
      <div className='search-my-horse'>
        <div className='my-profile'>
          <span className='color-primary title active'>
            {t(`${NOTIFICATION_MESSAGE}.myHorses`)}
            <img src={LINE_BOT} className='active' alt='' />
          </span>
          <Link className='color-primary title items' to={links.profile.information()}>
            My Items
          </Link>
        </div>
        <span className='search-container'>
          <SearchInput
            searchValue={searchValue}
            handleSearchValueChanged={handleSearchValueChanged}
            customClass='search-horse-input'
            placeholder={t(`${NOTIFICATION_MESSAGE}.horseName`)}
          />
          <SelectCustom
            dataSelect={defaultRegionFilters}
            nameSelect={regionSelect}
            onSelected={(filterName: string) => handleRegionFilterClicked(filterName)}
            isFiltered
          />
          <SelectCustom
            dataSelect={defaultTypeFilter}
            nameSelect={classSelect}
            onSelected={handleClassFilterClicked}
            isFiltered
          />
        </span>
      </div>

      <div className='my-horse-container'>
        <div className='d-flex header-horse text-uppercase'>
          <span className='width-5'></span>
          <span className='horse width-10'>{t(`${NOTIFICATION_MESSAGE}.horse`)}</span>
          <span className='name width-15'>{t(`${NOTIFICATION_MESSAGE}.name`)}</span>
          <span className='name width-5'></span>
          <span className='name width-5'></span>
          <span className='blood-line width-16'>{t(`${NOTIFICATION_MESSAGE}.bloodline`)}</span>
          <span className='gender width-16'>{t(`${NOTIFICATION_MESSAGE}.gender`)}</span>
          <span className='classes width-16'>{t(`${NOTIFICATION_MESSAGE}.classes`)}</span>
          <span className='status width-12'>{t(`${NOTIFICATION_MESSAGE}.status`)}</span>
        </div>
        <table className='my-horse-table'>
          {listHorse.length === 0 && debounceSearchValue.length > 0 && (
            <span className='color-white font-size-20'>
              There is no horse <span className='color-orange'>{debounceSearchValue}</span> in your stable
            </span>
          )}
          {listHorse.length > 0 && (
            <tbody>
              {listHorse.map((horse, index) => {
                return (
                  <MyHorseItem
                    key={horse.id}
                    stt={index + (paramsSearch.page || 1) * 5 - 5}
                    horse={horse}
                    openHorseModal={isOpenHorseModal}
                    closeHorseModal={closeHorseModal}
                    handleOpenModalHorse={() => handleOpenModalHorse(horse?.id)}
                    myName={myName}
                    atProfile={false}
                    idBeChoose={idBeChoose}
                    handleOpenModalBloodline={handleOpenModalBloodline}
                  />
                )
              })}
            </tbody>
          )}
        </table>
        {listHorse.length > 0 && parseInt(numberHorse) > 5 && (
          <div className='paginate'>
            <ReactPaginate
              nextLabel='>'
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel='<'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              forcePage={paramsSearch.page ? +paramsSearch.page - 1 : 0}
            />
          </div>
        )}
      </div>

      {isOpenHorseModal && (
        <HorseModal
          horse_id={idBeChoose}
          onCloseButtonClick={closeHorseModal}
          horseNFTNotExist={horseNFTNotExist}
          myName={myName}
          atProfile={true}
        />
      )}

      {openHorseBloodlineModal && (
        <ModalHorseBloodline title={bloodlineTitle} content={bloodlineContent} onClose={_handleClosePopup} />
      )}

      {isError && (
        <ErrorModal
          toggleIsModalOpen={closeModalError}
          content={messageErrorRef.current}
          direction={`/profile/my-profile?page=${paramsSearch.page}`}
        />
      )}
    </ProfileCloneStyled>
  )
}
