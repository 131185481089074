/* eslint-disable @typescript-eslint/no-explicit-any */
import { shortenRaceNameV2 } from 'utils/helper'
import InfoBoxContainer from './styled'
import { AVATAR_DEFAULT } from 'assets/images'

const InfoBox = ({ data }: any) => {
  return (
    <InfoBoxContainer>
      <div className='content-box'>
        <div className='img-box'>
          <div className='img-inside'>
            <img src={data?.avatar || AVATAR_DEFAULT} alt='avatar' />
          </div>
        </div>
        <div className='info'>
          <p className='name'>{shortenRaceNameV2(data?.user_name)}</p>
          <div className='row-info first'>
            <span>{shortenRaceNameV2(data?.wallet_address)}</span>
          </div>
          <div className='row-info second'>
            <span>{data?.role ? `GUILD ${data?.role} ` : 'GUEST'}</span>
          </div>
        </div>
      </div>
    </InfoBoxContainer>
  )
}

export default InfoBox
