import React from 'react'
import LazyLoad from 'react-lazy-load'
import styled from 'styled-components'
import { FEMALE_ICON } from 'assets/images'

const FemaleStyled = styled.div`
  width: 30px;
  img {
    width: 100%;
    height: 100%;
  }
`

const MemoizedImageFEMALEICON = () => {
  return (
    <LazyLoad>
      <FemaleStyled>
        <img src={FEMALE_ICON} alt='' className='icon-gender' loading='lazy' />
      </FemaleStyled>
    </LazyLoad>
  )
}
export default React.memo(MemoizedImageFEMALEICON)
