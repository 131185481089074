/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit'
import weatherApi from 'apis/weatherApi'
import { handleAsyncRequest } from 'utils/helper'

export const getWeather = createAsyncThunk('weather/getWeather', async () => {
  const [error, result] = await handleAsyncRequest(weatherApi.getWeatherCurrentScheduled())
  if (error) {
    return (error as any)?.message
  }
  return (result as any)?.data
})

export const getListWeather = createAsyncThunk('weather/getWeather', async (param: any) => {
  const [error, result] = await handleAsyncRequest(weatherApi.getListWeather(param))
  if (error) {
    return (error as any)?.message
  }
  return (result as any)?.data
})
