/* eslint-disable @typescript-eslint/no-explicit-any */
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'

import { ListContainer } from '../../pages/styled'
import UserItem from 'features/Ranking/components/UserItem'
import { UserRank } from 'models'
import UserInfo from '../UserInfo'

const TopList = ({
  data,
  handlePageClick,
  pageCount,
  paramsSearch,
  numberUser,
  debounceSearchValue,
  myRankingData
}: any) => {
  const { t } = useTranslation()

  return (
    <ListContainer>
      <div className='my-horse-container'>
        <div className='d-flex header-horse text-uppercase'>
          <span className='width-25 center'>{t(`${NOTIFICATION_MESSAGE}.rank`)}</span>
          <span className='width-30'>{t(`${NOTIFICATION_MESSAGE}.owner`)}</span>
          <span className='width-20'>{t(`${NOTIFICATION_MESSAGE}.career`)}</span>
          <span className='width-25'>{t(`${NOTIFICATION_MESSAGE}.winRate`)}</span>
        </div>
        <table className='my-horse-table'>
          {data?.length === 0 && debounceSearchValue.length > 0 && (
            <span className='color-white font-size-20'>
              There is no user <span className='color-orange'>{debounceSearchValue}</span> in your top users
            </span>
          )}
          {data?.length > 0 && (
            <tbody>
              {data?.map((user: UserRank) => {
                return <UserItem key={user.owner_id} user={user} />
              })}
            </tbody>
          )}
        </table>
        {data.length > 0 && parseInt(numberUser) > 5 && (
          <div className='paginate custom'>
            <UserInfo data={myRankingData} />
            <ReactPaginate
              nextLabel='>'
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel='<'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              forcePage={paramsSearch.page ? +paramsSearch.page - 1 : 0}
            />
          </div>
        )}
      </div>
    </ListContainer>
  )
}

export default TopList
