import React from 'react'
import LazyLoad from 'react-lazy-load'
import styled from 'styled-components'
import { MALE_ICON } from 'assets/images'

const MaleStyled = styled.div`
  width: 30px;
  img {
    width: 100%;
    height: 100%;
  }
`

const MemoizedImageMALEICON = () => {
  return (
    <LazyLoad>
      <MaleStyled>
        <img src={MALE_ICON} alt='' className='icon-gender' loading='lazy' />
      </MaleStyled>
    </LazyLoad>
  )
}
export default React.memo(MemoizedImageMALEICON)
