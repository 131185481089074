import styled from 'styled-components'

const HourseModalStyle = styled.div`
  .choose-horse-modal {
    background-color: ${({ theme }) => theme.color.neutral};
    clip-path: polygon(0 0, 100% 0, 100% 100%, 40px 100%, 0 calc(100% - 40px));
    padding: 32px;
    position: relative;
    width: 615px;
    .close-btn {
      top: 40px;
      right: 40px;
      background-color: ${({ theme }) => theme.color.transparent};
      border: none;
      z-index: 1;
    }

    .confirm-horse {
      h4 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        color: #4ef076;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        margin-top: 14px;
        position: relative;
        .frame {
          position: absolute;
          bottom: -5px;
          left: 35%;
        }
      }
      .horse-detail {
        background-image: linear-gradient(89deg, #044817 4.18%, rgba(15, 18, 28, 0) 109.38%);
        margin-top: 33px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%, 0 calc(100% - 20px));
        .boots-img {
          background-image: linear-gradient(#4ff077, #48f7b9);
          padding: 14px 10px;
          width: 142px;
          text-align: center;
          img {
            height: 112px;
          }
        }
        .des {
          padding: 20px;
          p {
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 83.333% */
            text-transform: uppercase;
          }
          span {
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; /* 104.167% */
            text-transform: capitalize;
            display: block;
            color: #bbb;
            margin-bottom: 8px;
          }
        }
      }
      .des-meta {
        margin-top: 27px;
        textarea {
          resize: none;
          width: 100%;
          background: transparent;
          height: 100px;
          overflow: auto;
          color: rgba(255, 255, 255, 0.48);
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          margin-left: 16px;
          border: none;
          &.infinite-scroll-component::-webkit-scrollbar-track {
            border: 1px solid #000;
            padding: 2px 0;
            background-color: #000;
          }

          &.infinite-scroll-component::-webkit-scrollbar {
            width: 5px;
          }

          &.infinite-scroll-component::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #2f323e;
            border: 1px solid #000;
          }
        }
        .description {
          height: 100px;
          overflow: auto;
          display: block;
          &.infinite-scroll-component::-webkit-scrollbar-track {
            border: 1px solid #000;
            padding: 2px 0;
            background-color: #000;
          }

          &.infinite-scroll-component::-webkit-scrollbar {
            width: 5px;
          }

          &.infinite-scroll-component::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #2f323e;
            border: 1px solid #000;
          }
        }
        p {
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          text-transform: uppercase;
          background-color: #0f121c;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 13px 100%, 0 calc(100% - 20px));
          padding: 4px 14px;
          margin-bottom: 10px;
        }
        span {
          color: rgba(255, 255, 255, 0.48);
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          margin-left: 16px;
        }
      }
    }

    .race-name-container {
      position: absolute;
      left: 25px;
      top: 15px;
      .race-name {
        font-size: 24px;
        line-height: 20px;
        margin-bottom: 28px;
        span {
          margin-bottom: 4px;
        }
      }
    }

    .confirm-horse {
      &-btns {
        margin-top: 26px;
        button {
          border: 0;
          background: none;
          padding: 0;
          margin: 0 40px;
          position: relative;
          filter: brightness(0.8);

          span {
            position: absolute;
            width: 100%;
            margin: 0 auto;
            left: 0;
            color: #fff;
            top: 8px;
            font-size: 24px;
          }
          &:hover {
            filter: brightness(1);
          }
        }
      }
      &-title {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }
`

export default HourseModalStyle
