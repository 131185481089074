/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import SelectCustom from 'shared/SelectCustom'
import { SearchInput } from 'shared'
import {
  Career,
  GuildListSortType,
  HorseAvailable,
  MyHorseListParams,
  TypeSorts,
  GuildListFiltersType,
  defaultRegionFilters
} from 'models'
import { useAppSelector, useDebounce, useFocusTopScreen, useToggle, useUpdateEffect } from 'hooks'
import { constants, links } from 'apps'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { RaceTable, RequestLoginModal } from 'features/Race/components'
import { guildMyHorseColumns } from 'utils/columns'
import ChooseHorseModalGuildFarm from 'features/Guild/components/ChooseHoreModalGuildFarm'
import ConfirmGuildFarmModal from 'features/Guild/components/ConfirmGuildFarmModal'
import { convertStatusGuild, handleAsyncRequest, shortenUserName } from 'utils/helper'
import guildApi from 'apis/guildApi'
import openNotification from 'utils/toast'

import MyHorsesContainer from './styled'
import { TWO_LINE } from 'assets/images'
import Button from 'shared/Button'
import { NUMBER, STRING_REGION } from 'apps/constants'

const myHorseListParams: MyHorseListParams = {
  limit: NUMBER.TWENTY,
  page: NUMBER.ONE,
  sort: 'gh.in_farm_date-desc'
}

const defaultTypeFilters: TypeSorts = [
  {
    name: GuildListFiltersType.All,
    isActive: false
  },
  {
    name: GuildListFiltersType.InFarm,
    isActive: false
  },
  {
    name: GuildListFiltersType.GuildFarm,
    isActive: false
  },
  {
    name: GuildListFiltersType.OnMarket,
    isActive: false
  },
  {
    name: GuildListFiltersType.Renting,
    isActive: false
  }
]

const defaultTypeSorts: TypeSorts = [
  {
    name: GuildListSortType.Newest,
    isActive: false
  },
  {
    name: GuildListSortType.Oldest,
    isActive: false
  },
  {
    name: GuildListSortType.Ascending,
    isActive: false
  },
  {
    name: GuildListSortType.Decrease,
    isActive: false
  }
]

const MyHorses = () => {
  useFocusTopScreen()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const selectRef = useRef<any>(null)
  const filterRef = useRef<any>(null)
  const { profile, auth } = useAppSelector(state => state)

  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const [params, setParams] = useState<MyHorseListParams>(myHorseListParams)
  const [classSelect, setClassSelect] = useState<any>('Sorted by')
  const [isModalChooseHorseOpen, toggleIsChooseHorseModalOpen] = useToggle(false)
  const memoizedResultListSelectionColumns = useMemo(() => guildMyHorseColumns, [])
  const [listHorse, setListHorse] = useState([])
  const [total, setTotal] = useState()
  const [isModalSpecialRaceOpen, toggleIsModalSpecialRaceOpen] = useToggle(false)
  const [isRefresh, toggleIsRefresh] = useToggle(false)
  const [horseDetails, setHorseDetails] = useState<HorseAvailable>()
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [, setLoading] = useState<boolean>(false)
  const [classFilterSelect, setClassFilterSelect] = useState<any>('Filter by status')
  const [regionSelect, setRegionSelect] = useState('Region')

  useEffect(() => {
    if (isRefresh) {
      toggleIsModalSpecialRaceOpen(false)
      toggleIsChooseHorseModalOpen(false)
      setParams({ ...params, page: 1 })
      toggleIsRefresh(false)
    }
  }, [isRefresh])

  useEffect(() => {
    getGuildFarms()
  }, [params])

  useUpdateEffect(() => {
    setListHorse([])
    setParams({ ...params, page: NUMBER.ONE, search: searchValue })
  }, [debounceSearchValue])

  const getGuildFarms = async () => {
    setLoading(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.getMyHorses({ ...params }))

    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen()
        setLoading(false)
        return
      }
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }

    if (result) {
      const formatData = result.data.records.map((record: any) => {
        return {
          ...record,
          horse_name: record.horse.name,
          horse_gender: record.horse.gender,
          owner_name: record.owner.name,
          status: convertStatusGuild(record.lending_status),
          sub_avatar: record.horse.sub_avatar,
          racing_class_name: record.horse.racing_class,
          winRate: handleCareer(record.career),
          renterName: shortenUserName(record?.renter?.name) || '---',
          guildGender: record.horse.gender,
          guildRegion: record?.horse?.region
        }
      })
      setListHorse(params.page > NUMBER.ONE ? [...listHorse, ...formatData] : formatData)
      setTotal(result.data?.total)
    }
    setLoading(false)
  }

  const handleCareer = (career: Career) => {
    const totalRace = career?.total_number_of_races
    const firstClass = career?.first_count
    const secondClass = career?.second_count
    const thirdClass = career?.third_count
    const tltClass = (firstClass + secondClass + thirdClass) / totalRace
    const newTltClass = isNaN(tltClass) ? 0 : tltClass * 100
    const winRateResult = `${
      Number.isInteger((firstClass / totalRace) * 100)
        ? (firstClass / totalRace) * 100
        : ((firstClass / totalRace) * 100).toFixed(2)
    }% - ${Number.isInteger(newTltClass) ? newTltClass : newTltClass.toFixed(2)}%`
    let valueDisplay = ''
    if (totalRace === 0) {
      valueDisplay = '---'
    }
    if (totalRace > 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
      valueDisplay = '0.00% - 0.00%'
    }
    if (totalRace !== 0 && (firstClass !== 0 || secondClass !== 0 || thirdClass !== 0)) {
      valueDisplay = winRateResult
    }
    return valueDisplay
  }

  const handleClassSortClicked = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case GuildListSortType.Newest:
          return 'gh.in_farm_date-desc'
        case GuildListSortType.Oldest:
          return 'gh.in_farm_date-asc'
        case GuildListSortType.Ascending:
          return 'gh.name-asc'
        case GuildListSortType.Decrease:
          return 'gh.name-desc'
        default:
          return 'gh.in_farm_date-desc'
      }
    }

    selectRef.current = handleKeySort()
    setParams({
      ...params,
      page: 1,
      sort: selectRef.current,
      search: debounceSearchValue,
      status: filterRef.current
    })
    setClassSelect(item)
  }

  const handleClassFilterClicked = (item: string) => {
    const handleKeyFilter = () => {
      switch (item) {
        case GuildListFiltersType.All:
          return ''
        case GuildListFiltersType.InFarm:
          return 'IN_FARM'
        case GuildListFiltersType.GuildFarm:
          return 'GUILD_FARM'
        case GuildListFiltersType.OnMarket:
          return 'ON_MARKET'
        case GuildListFiltersType.Renting:
          return 'RENTING'
        default:
          return 'IN_FARM'
      }
    }

    filterRef.current = handleKeyFilter()
    setParams({
      ...params,
      page: NUMBER.ONE,
      sort: selectRef.current,
      search: debounceSearchValue,
      status: filterRef.current
    })
    setClassFilterSelect(item)
  }

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleRowItemClick = (value: any) => {
    if (value?.horse_guild?.id) {
      navigate(links.guild.horseDetail(value?.horse_guild?.id, value?.horse?.id))
    } else {
      navigate(links.guild.horseDetailInfarm(value?.horse?.id))
    }
  }

  const handleWidthdrawHorseFarm = () => {
    if (!auth.isLogged) {
      toggleIsRequestLoginModalOpen()
      return
    }
    toggleIsChooseHorseModalOpen(true)
  }

  const onGetHorseItem = (horse: HorseAvailable) => {
    setHorseDetails(horse)
  }

  const handleRegionFilterClicked = (item: string) => {
    if (item?.toUpperCase() === STRING_REGION.SEOUL || item?.toUpperCase() === STRING_REGION.TOKYO) {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: item?.toUpperCase()
      })
    } else {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: ''
      })
    }
    setRegionSelect(item)
  }

  return (
    <MyHorsesContainer>
      <div className='head'>
        <h3>
          my horses
          <img src={TWO_LINE} alt='line' />
        </h3>
      </div>
      <div className='button-box'>
        <Button
          buttonName='Put horse to farm'
          onClickButton={handleWidthdrawHorseFarm}
          type='default'
          height={52}
          width={181}
          classNames='remove-btn'
        />
      </div>

      <div className='search-box'>
        {/* region */}
        <SelectCustom
          dataSelect={defaultRegionFilters}
          nameSelect={regionSelect}
          onSelected={handleRegionFilterClicked}
          customClass='select-custom'
        />

        <SelectCustom
          dataSelect={defaultTypeFilters}
          nameSelect={classFilterSelect}
          onSelected={handleClassFilterClicked}
          customClass='select-custom'
        />

        <SelectCustom
          dataSelect={defaultTypeSorts}
          nameSelect={classSelect}
          onSelected={handleClassSortClicked}
          customClass='select-custom'
        />

        <SearchInput
          searchValue={searchValue}
          handleSearchValueChanged={handleSearchValueChanged}
          customClass='search-horse-input'
          placeholder={'Horse name, token id'}
        />
      </div>
      <div className='your-horse'>
        <RaceTable
          columns={memoizedResultListSelectionColumns}
          data={listHorse ?? []}
          raisePage={setParams}
          loader={true}
          params={params}
          isRowClickable
          checkLoadingPage={false}
          totalRace={total}
          onRowClick={handleRowItemClick}
          currentUser={profile}
          noDataText={t(`${NOTIFICATION_MESSAGE}.dataNotFound`)}
          isHorseTable
          isShowVipIcon
        />
      </div>
      {isModalChooseHorseOpen && (
        <ChooseHorseModalGuildFarm
          getHorseDetail={onGetHorseItem}
          toggleIsModalSpecialRaceOpen={toggleIsModalSpecialRaceOpen}
          toggleIsModalOpen={toggleIsChooseHorseModalOpen}
          onCloseButtonClick={() => toggleIsChooseHorseModalOpen(false)}
        />
      )}
      {isModalSpecialRaceOpen && (
        <ConfirmGuildFarmModal
          toggleIsRefresh={toggleIsRefresh}
          horse={horseDetails}
          toggleIsModalOpen={toggleIsModalSpecialRaceOpen}
          onCloseButtonClick={() => toggleIsModalSpecialRaceOpen(false)}
        />
      )}

      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </MyHorsesContainer>
  )
}

export default MyHorses
