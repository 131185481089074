/* eslint-disable @typescript-eslint/no-explicit-any */

import { Link, useLocation } from 'react-router-dom'
import { TwoLineTitle } from 'shared'
import TabsTitleStyle from './styled'

interface TabsProps {
  customClass?: string
  tabs: any[]
}

function TabsTitle({ customClass = '', tabs }: TabsProps) {
  const { pathname: currentPathname } = useLocation()

  return (
    <TabsTitleStyle className={customClass}>
      <div className='title-tabs-container'>
        <div className='title-tabs d-flex justify-content-center align-items-start'>
          {tabs.map(tab =>
            tab.link === currentPathname ? (
              <Link key={tab.name} to={tab.link} className='tab-link text-center'>
                <TwoLineTitle text={tab.name} />
              </Link>
            ) : (
              <Link key={tab.name} to={tab.link} className='tab-link font-bold text-uppercase text-center'>
                {tab.name}
              </Link>
            )
          )}
        </div>
      </div>
    </TabsTitleStyle>
  )
}

export default TabsTitle
