import { BOTTOM_LEFT_ICON, LEFT_ICON } from 'assets/images'
import { useAppSelector } from 'hooks'
import AvatarBoxContainer from './styled'

interface infoProps {
  informationData: {
    avatar: string
    guild_tag: string
    name: string
    level: string
    role_in_guild: string
    id: number
  }
}

const AvatarBox = ({ informationData }: infoProps) => {
  const { profile } = useAppSelector(state => state)

  const isMyGuild = informationData?.role_in_guild && profile?.guild_id === informationData?.id

  return (
    <AvatarBoxContainer>
      <div className='left-bg'>
        <img src={LEFT_ICON} alt='bg' />
      </div>
      <div className='bottom-bg'>
        <img src={BOTTOM_LEFT_ICON} alt='bg' />
      </div>
      <div className='avatar-box'>
        <div>
          {informationData?.avatar && (
            <img className='guild-avatar' src={informationData?.avatar} width={115} height={115} />
          )}
        </div>
        <div className={`avatar-content ${isMyGuild ? 'active' : ''}`}>
          <div className='tag'>
            {informationData?.guild_tag && <span className='color-yellow'> [{informationData?.guild_tag}] </span>}{' '}
            {informationData?.name}
          </div>
          <div className='level'>ROOKY</div>
        </div>
      </div>
    </AvatarBoxContainer>
  )
}

export default AvatarBox
