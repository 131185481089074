import styled from 'styled-components'
import { MODAL_BG_2 } from 'assets/images'

const ModalConfirmCreateGuildContainer = styled.div`
  width: 600px;
  height: 320px;
  position: relative;

  .modal__container {
    background: url(${MODAL_BG_2});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 600px;
    height: 320px;
    object-fit: cover;

    .header {
      margin-bottom: 16px;
      p {
        text-align: center;
        padding-top: 20px;

        .title {
          display: inline-block;
          width: fit-content;
          color: #4ef076;
          font-size: 24px;
          line-height: 24px;
        }
      }

      .line__under--title {
        display: block;
        width: 70%;
        margin: 0 auto;
        border-bottom: 1px solid #7a83a6;
      }
    }

    .close-btn {
      top: 10px;
      right: 10px;
      background-color: ${({ theme }) => theme.color.transparent};
      border: none;
      z-index: 1;
    }

    .content-box {
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: #fff;
        font-size: 20px;
        text-align: center;
      }
    }

    .error {
      width: 100%;
      min-height: 20px;
      text-align: center;
      color: #ff0000;
    }

    .button-box {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 20px 0 0;
    }
  }
`

export { ModalConfirmCreateGuildContainer }
