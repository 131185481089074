/* eslint-disable @typescript-eslint/no-explicit-any */
import raceApi from 'apis/raceApi'
import { links } from 'apps'
import { ARROW_RIGHT, BTN_CANCEL } from 'assets/images'
import _ from 'lodash'

import userApi from 'apis/userApi'
import { setCoinUser } from 'features/Balance/coinUser.slice'
import { OpenRaceBox } from 'features/Home/components'
import { useAppDispatch, useAppSelector, useFetch, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import {
  AuthState,
  GetRaceListParams,
  GetRaceListResponse,
  GetTopParams,
  RaceStatus,
  RecordRace,
  notificationTemplate
} from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { OneLineTitle } from 'shared'
import { WS_MANAGER } from 'socket/socketClient'
import { handleAsyncRequest } from 'utils/helper'
import StyledHome from './styled'

import { ReactComponent as LOGO_BIGHORSE } from 'assets/images/icons/logo-bighorse.svg'
import { setAuthState } from 'features/Auth/auth.slice'
import { ResultHorseModal } from 'features/Race/components'
import rankingApi from 'apis/rankingApi'
import { getTooltipInfo } from 'apps/redux/tooltip/action'

const getOpenRaceListParams: GetRaceListParams = {
  limit: 20,
  page: 1,
  status: RaceStatus.OPEN,
  freeRace: false,
  myHorse: false
}

const getNextRaceListParams: GetRaceListParams = {
  limit: 5,
  page: 1,
  status: RaceStatus.SCHEDULING,
  freeRace: false,
  myHorse: false
}

const date = new Date()
const year = date.getFullYear()
const month = date.getMonth() + 1

const defaultGetTopParams: GetTopParams = {
  yearMonth: `${year}-0${month}`
}

function HomeMain() {
  const { WAITING, SCHEDULING, OPEN, CLOSED, CANCEL } = RaceStatus
  const [fetchListRace, toggleFetchListRace] = useToggle(false)
  const [fetchListOpenRace, toggleFetchListOpenRace] = useToggle(false)
  const [, setBannerMeta] = useState(false)
  const [, setBannerHorse] = useState(false)

  const [, setNextRaceListResponseSort] = useState<RecordRace[]>([])
  const { t } = useTranslation()
  const { data: openRaceListResponse } = useFetch<GetRaceListResponse, GetRaceListParams>(
    {
      fetcher: raceApi.getRaceList,
      params: getOpenRaceListParams
    },
    [fetchListOpenRace]
  )
  const checkSpace = (name: any) => {
    return name?.includes('   ')
  }
  const cloneHorseListResponse = openRaceListResponse?.records?.filter(race => !checkSpace(race.name) === true)
  const { data: nextRaceListResponse } = useFetch<GetRaceListResponse, GetRaceListParams>(
    {
      fetcher: raceApi.getRaceListHome,
      params: getNextRaceListParams
    },
    [fetchListRace]
  )

  useFetch<any, any>({
    fetcher: rankingApi.getTopHorses,
    params: defaultGetTopParams
  })
  useFetch<any, any>({
    fetcher: rankingApi.getTopUsers,
    params: defaultGetTopParams
  })

  const auth = useAppSelector(state => state.auth)

  const dispatch = useAppDispatch()

  const [isModalNotification, setIsModalNotification] = useState(false)
  const [message, setMessage] = useState<notificationTemplate>()

  const getNotificationMessage = async () => {
    const [, result] = await handleAsyncRequest(userApi.getNotificationMessage())
    if (_.isEmpty(result?.data)) return
    setIsModalNotification(true)
    setMessage(!_.isEmpty(result?.data) && result?.data[0].template)
  }

  useEffect(() => {
    if (auth.isFirstLogin) {
      const authState: AuthState = {
        ...auth,
        isFirstLogin: false
      }
      getNotificationMessage()
      dispatch(setAuthState(authState))
    }
  }, [auth])

  const handleCloseModalJoinRaceOpen = () => {
    window.location.reload()
    return setIsModalNotification(false)
  }

  const fetchCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return

    dispatch(setCoinUser(resultCoinUser.data))
  }

  useEffect(() => {
    if (auth.isLogged === false || process.env.MODE === 'DEV') return
    fetchCoinUser()
  }, [auth])

  const handleSocketChange = (message: { body: string }) => {
    const { data } = JSON.parse(message.body)
    const { newStatus } = data[Object.keys(data)[0]]
    if (newStatus === SCHEDULING || newStatus === WAITING || newStatus === CLOSED) {
      toggleFetchListRace()
    }
    if (newStatus === SCHEDULING || newStatus === OPEN || newStatus === CANCEL) {
      toggleFetchListOpenRace()
    }
  }

  useEffect(() => {
    dispatch(getTooltipInfo())
    const subscription = WS_MANAGER.subscribe('/topic/race-status', handleSocketChange)
    const subscriptionSpecialRace = WS_MANAGER.subscribe('/topic/special-race-status', handleSocketChange)
    return () => {
      subscription.then(sub => sub?.unsubscribe())
      subscriptionSpecialRace.then(sub => sub?.unsubscribe())
    }
  }, [])

  useEffect(() => {
    if (nextRaceListResponse && nextRaceListResponse?.records?.length > 0) {
      const nextRaceRecords = nextRaceListResponse.records
      // const raceLive = nextRaceRecords.filter((x: RecordRace) => x.status === LIVE)
      // const raceScheduling = nextRaceRecords.filter((x: RecordRace) => x.status === SCHEDULING)
      // const raceWaiting = nextRaceRecords.filter((x: RecordRace) => x.status === WAITING)
      setNextRaceListResponseSort(nextRaceRecords)
    } else {
      setNextRaceListResponseSort([])
    }
  }, [nextRaceListResponse])

  useEffect(() => {
    let timer = 0
    const intervalId = setInterval(() => {
      timer = timer + 1
      if (timer >= 1) {
        setBannerMeta(true)
        setBannerHorse(false)
      }
      if (timer >= 2) {
        setBannerHorse(true)
        setBannerMeta(false)
      }
      if (timer >= 3) {
        setBannerMeta(true)
        setBannerHorse(true)
      }
      if (timer >= 4) {
        setBannerMeta(false)
        setBannerHorse(false)
        timer = 0
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])
  const onReloadPage = () => {
    window.location.reload()
  }

  return (
    <StyledHome>
      <div className='top-section d-flex flex-column flex-lg-row align-items-center align-items-lg-end'>
        <div className='container'>
          <div className='banner'>
            <div className='banner-background position-relative'>
              <div className='banner-logo position-absolute'>
                <LOGO_BIGHORSE />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-fluid'>
        <div className='container'>
          <div className='open-race-section'>
            <div className='head d-flex justify-content-between align-items-center align-items-lg-end'>
              <OneLineTitle text='open race' customClass='title' />
              <Link to={links.race.open()} className='view-btn'>
                <span className='color-light-neon'>{t(`${NOTIFICATION_MESSAGE}.viewAll`)}</span>
                <img src={ARROW_RIGHT} alt='' />
              </Link>
            </div>
            <div className='content row'>
              {cloneHorseListResponse?.map((race, index) =>
                index < 4 ? (
                  <OpenRaceBox key={race.id} race={race} customClass='open-race-item col-12 col-sm-6 col-lg-3' />
                ) : (
                  ''
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalNotification && (
        <ResultHorseModal
          // toggleIsModalOpen={setIsModalNotification}
          onCloseButtonClick={handleCloseModalJoinRaceOpen}
          message={
            <>
              <div className='font-bold font-size-20 color-orange text-uppercase'>{message?.title}</div>
              <div className='font-size-18'>{message?.body}</div>
              <div className='reload-page'>
                <button onClick={onReloadPage}>OK</button>
                <img src={BTN_CANCEL} alt='' />
              </div>
            </>
          }
        />
      )}
    </StyledHome>
  )
}

export default HomeMain
