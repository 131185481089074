/* eslint-disable @typescript-eslint/no-explicit-any */
import { CLOSE_BTN, FRAME_BOT } from 'assets/images'
import { Modal } from 'shared'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import HourseModalStyle from './styled'
import { ITEMS_MESSAGE } from 'i18n/constants'
import Button from 'shared/Button'
interface HorseModalRaceViewProps {
  onOverlayClick?: () => void
  onCloseButtonClick?: () => void
  onRemove: () => void
  onChange: () => void
  dataPreview?: any
  isLoading: boolean
}

function HorseModalConfirmRemoveItem({
  onOverlayClick,
  onRemove,
  onChange,
  onCloseButtonClick,
  dataPreview,
  isLoading
}: HorseModalRaceViewProps) {
  const { t } = useTranslation()
  return (
    <Modal onOverlayClick={onOverlayClick}>
      <HourseModalStyle>
        {dataPreview.length !== 0 ? (
          <div className='choose-horse-modal'>
            <button className='close-btn p-0 position-absolute' role='button' onClick={onCloseButtonClick}>
              <img src={CLOSE_BTN} alt='close' />
            </button>
            <div className='confirm-horse'>
              <h4 className='color-white'>
                {t(`${ITEMS_MESSAGE}.item_info`)}
                {isLoading ? '' : <img className='frame' src={FRAME_BOT} alt={dataPreview?.boost?.name} />}
              </h4>
              {isLoading ? (
                <span className='btn-name d-flex align-items-center justify-content-center'>
                  {isLoading && <Spin size='large' className='mt-5 mr-3' />}
                </span>
              ) : (
                <>
                  <div className='horse-detail d-flex align-items-center'>
                    <div className='boots-img'>
                      <img src={dataPreview?.boost?.img} alt={dataPreview?.boost?.name} />
                    </div>
                    <div className='des'>
                      <p className='color-white'>{dataPreview?.boost.name}</p>
                      <span>{t(`${ITEMS_MESSAGE}.type`)}: BOOSTER</span>
                    </div>
                  </div>
                  <div className='des-meta ``'>
                    <p className='color-white'>{t(`${ITEMS_MESSAGE}.attribute`)}</p>
                    <span>{dataPreview?.boost?.attribute}</span>
                  </div>
                  <div className='des-meta'>
                    <p className='color-white'>{t(`${ITEMS_MESSAGE}.information`)}</p>
                    <textarea name='textarea_field' className='infinite-scroll-component' disabled>
                      {dataPreview?.boost?.description}
                    </textarea>
                  </div>

                  <div className='confirm-horse-btns d-flex align-items-center'>
                    <Button
                      buttonName='Change'
                      onClickButton={onChange}
                      width={150}
                      height={50}
                      classNames='confirm-btn'
                    />
                    <Button
                      buttonName='Remove'
                      onClickButton={onRemove}
                      type='btnCancel'
                      width={150}
                      height={50}
                      classNames='confirm-btn'
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </HourseModalStyle>
    </Modal>
  )
}

export default HorseModalConfirmRemoveItem
