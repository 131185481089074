import { useLocation, useOutlet, useParams } from 'react-router-dom'

import { links } from 'apps'
import TabsTitle from 'features/components/TabsTitle'
import GuildStyle from './styled'
import GuildInfo from './guild-info'

function GuildMain() {
  const { pathname } = useLocation()
  const { guild_id } = useParams()
  const outlet = useOutlet()

  const tabsGuildFarm = [
    {
      name: 'Horse Farm',
      link: guild_id ? links.guild.guildFarmId(guild_id) : links.guild.guildFarm()
    },
    {
      name: 'Horse Owner',
      link: guild_id ? links.guild.horseOwnerId(guild_id) : links.guild.horseOwner()
    },
    {
      name: 'Member',
      link: guild_id ? links.guild.membersId(guild_id) : links.guild.members()
    }
  ]

  const tabsGuildInfo = [
    {
      name: 'Information',
      link: links.guild.updateInfo()
    },
    {
      name: 'InCome',
      link: links.guild.inCome()
    },
    {
      name: 'Disband Guild',
      link: links.guild.disbandGuild()
    }
  ]

  const tabsGuildManagement = [
    {
      name: 'Application',
      link: links.guild.applicationGuild()
    },
    {
      name: 'User Blacklist',
      link: links.guild.userBlacklistGuild()
    },
    {
      name: 'Horse Blacklist',
      link: links.guild.horseBlacklistGuild()
    },
    {
      name: 'Balance',
      link: links.guild.BalanceGuild()
    }
  ]

  const tabsGuild = () => {
    if (
      pathname.indexOf(links.guild.members()) !== -1 ||
      pathname.indexOf(links.guild.horseOwner()) !== -1 ||
      pathname.indexOf(links.guild.guildFarm()) !== -1
    ) {
      return 'GUILD_FARM'
    }
    if (
      pathname.indexOf(links.guild.updateInfo()) !== -1 ||
      pathname.indexOf(links.guild.inCome()) !== -1 ||
      pathname.indexOf(links.guild.disbandGuild()) !== -1
    ) {
      return 'GUILD_INFO'
    }
    if (
      pathname.indexOf(links.guild.applicationGuild()) !== -1 ||
      pathname.indexOf(links.guild.userBlacklistGuild()) !== -1 ||
      pathname.indexOf(links.guild.horseBlacklistGuild()) !== -1 ||
      pathname.indexOf(links.guild.BalanceGuild()) !== -1
    ) {
      return 'GUILD_MANAGEMENT'
    }
    return ''
  }

  const renderTabs = () => {
    const title = tabsGuild()
    switch (title) {
      case 'GUILD_FARM': {
        return <TabsTitle tabs={tabsGuildFarm} />
      }
      case 'GUILD_INFO': {
        return <TabsTitle tabs={tabsGuildInfo} />
      }
      case 'GUILD_MANAGEMENT': {
        return <TabsTitle tabs={tabsGuildManagement} />
      }
      default:
        break
    }
  }

  return (
    <GuildStyle>
      <div className={`container ${pathname?.includes('/guild/horse/in-farm') ? 'container__horse_farm' : ''}`}>
        {/* {
          titlePage().length > 0 ?
            <div className='title-tabs-container'>
              <div className='title-tabs color-primary'>
                <TwoLineTitle text={titlePage()} />
              </div>
            </div>
            : <></>
        } */}
        {tabsGuild() === 'GUILD_FARM' && <GuildInfo />}

        {tabsGuild() ? renderTabs() : <></>}
        <div className='content'>{outlet}</div>
      </div>
    </GuildStyle>
  )
}

export default GuildMain
