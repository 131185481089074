/* eslint-disable @typescript-eslint/no-explicit-any */
import { RESET_BUTTON } from 'assets/images'
import ButtonStyled from './styled'

interface ButtonProps {
  onClickButton: () => void
  width?: number
  height?: number
  disabled?: any
  classNames?: any
  type?: string
  margingTop?: boolean
}

function ButtonResetPoint({ onClickButton, width = 150, height = 45, disabled, classNames }: ButtonProps) {
  const handleClick = () => {
    if (disabled) return
    onClickButton()
  }

  return (
    <ButtonStyled className={classNames}>
      <div className={`btn-container-${disabled ? 'disable' : ''}`} onClick={handleClick}>
        <img src={RESET_BUTTON} alt='' className='background-btn' width={width} height={height} />
        <span className='reset_horse'>{'Reset point'}</span>
      </div>
    </ButtonStyled>
  )
}

export default ButtonResetPoint
