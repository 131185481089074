/* eslint-disable @typescript-eslint/no-explicit-any */

import { CLOSE_BTN, GAME_TOKEN_KUDA, GAME_TOKEN_MERAH, ONE_LINE_ORANGE, ICON_ADIL } from 'assets/images'
import classNames from 'classnames'
import { MODAL_TYPE } from 'models'
import { ReactNode } from 'react'
import { Modal } from 'shared'
import Button from 'shared/Button'
import ConfirmOkModalStyled from './styled'

interface ConfirmOkModalProps {
  toggleIsModalOpen?: (value?: boolean) => void
  onCloseButtonClick: () => void
  onConfirm?: () => void
  handleTotalPayment?: number
  message: ReactNode
  title?: string
  isLoading?: boolean
  btnOk?: string | React.ReactNode
  btnCancel?: string | React.ReactNode
  isDisabled?: boolean
  isModalWarning?: boolean
  btnName?: string | React.ReactNode
  heading?: string
  isContentCenter?: boolean
  itemDetails?: any
  margingTop?: boolean
}

function ConfirmOkModal({
  isContentCenter,
  btnOk,
  btnCancel,
  toggleIsModalOpen,
  onCloseButtonClick,
  onConfirm,
  title,
  isLoading,
  isDisabled,
  isModalWarning,
  btnName,
  heading,
  itemDetails,
  handleTotalPayment,
  margingTop = false
}: ConfirmOkModalProps) {
  const handleBackModalCancel = () => {
    onCloseButtonClick()
  }

  const classTitle = classNames([
    'race-name color-orange text-uppercase d-flex align-items-center flex-column',
    (title === 'completed' || title === 'success!') && 'color-primary'
  ])

  const classTitleFailed = classNames([
    'race-name color-orange text-uppercase d-flex align-items-center flex-column',
    title === MODAL_TYPE.failed && 'color-red'
  ])

  const handleClassTitle = () => {
    switch (title) {
      case MODAL_TYPE.completed:
        return classTitle
      case MODAL_TYPE.success:
        return classTitle
      case MODAL_TYPE.failed:
        return classTitleFailed
      default:
        return classTitle
    }
  }

  return (
    <Modal onOverlayClick={toggleIsModalOpen}>
      <ConfirmOkModalStyled>
        <div className={`choose-horse-modal ${heading ? 'head' : ''}`}>
          {heading && (
            <div className='header'>
              <p>
                <span className={`title ${heading === 'warning' ? 'warning' : ''}`}>{heading}</span>
              </p>
              <span className='line__under--title'></span>
            </div>
          )}

          {title && (
            <div className='race-name-container'>
              <p className={handleClassTitle()}>
                <span className='title'>
                  {title}
                  <img className='line-bottom' src={ONE_LINE_ORANGE} alt='' />
                </span>
              </p>
            </div>
          )}
          <button className='close-btn p-0 position-absolute' role='button' onClick={handleBackModalCancel}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className='confirm-horse'>
            <div className={`text message-err ${isContentCenter && 'text-center'}`}>
              You are about to buy <b className='color-yellow'>{itemDetails?.item?.name || itemDetails?.boost?.name}</b>{' '}
              with <br />
              {handleTotalPayment}
              {itemDetails?.currency === 'MERAH' || itemDetails?.currency === 'MERAH_NEW' ? (
                <img src={GAME_TOKEN_MERAH} alt='merah' className='icon merah' width={25} />
              ) : itemDetails?.currency === 'KUDA' || itemDetails?.currency === 'KUDA_NEW' ? (
                <img src={GAME_TOKEN_KUDA} alt='kuda' className='icon kuda' width={25} />
              ) : (
                <img src={ICON_ADIL} alt='adil' className='icon adil' width={25} />
              )}{' '}
              <br />
              Are you sure?
            </div>
            <div className='confirm-horse-btns d-flex align-items-center justify-content-center'>
              {isModalWarning && (
                <Button
                  buttonName={btnName ?? 'Close'}
                  onClickButton={handleBackModalCancel}
                  width={150}
                  type='btnCancel'
                />
              )}
              {!isModalWarning &&
                (title === MODAL_TYPE.failed || title === MODAL_TYPE.success ? (
                  <Button buttonName='OK' onClickButton={handleBackModalCancel} width={150} />
                ) : (
                  <>
                    <Button
                      buttonName={btnCancel ? btnCancel : 'No'}
                      onClickButton={handleBackModalCancel}
                      width={150}
                      height={35}
                      type='btnCancel'
                    />
                    <Button
                      buttonName={btnOk ? btnOk : 'Yes'}
                      onClickButton={() => onConfirm?.()}
                      width={150}
                      height={35}
                      isLoading={isLoading}
                      margingTop={margingTop}
                      disabled={isDisabled}
                    />
                  </>
                ))}
            </div>
          </div>
        </div>
      </ConfirmOkModalStyled>
    </Modal>
  )
}

export default ConfirmOkModal
