/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Spin } from 'antd'
import TopStableBox from '../../../components/TopStableBox'
import { moveIndexHorse } from 'utils/helper'
import TopMembersContainer from '../top-members/styled'
import { TopStable } from 'models/horse'
import { ARROW_RIGHT_ICON, TWO_LINE } from 'assets/images'
import { handleAsyncRequest } from 'utils/helper'
import guildApi from 'apis/guildApi'
import { links } from 'apps'

interface guildProps {
  guildId: number
}

const options = {
  scale: 1.2,
  speed: 1000,
  max: 30
}

const TopOwners = ({ guildId }: guildProps) => {
  const { guild_id } = useParams()
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const memoizedTopHorseList = useMemo<TopStable[]>(() => moveIndexHorse(data), [data])

  useEffect(() => {
    guildId && getTopOwners()
  }, [])

  const getTopOwners = async () => {
    const [error, result]: any = await handleAsyncRequest(guildApi.getTopOwners(guildId))

    if (error) {
      setLoading(false)
    }

    if (result) {
      setData(result.data)
    }
  }

  const ownerLength = memoizedTopHorseList?.length

  return (
    <TopMembersContainer>
      <div className='head'>
        <h3>
          Top owners
          <img src={TWO_LINE} alt='line' />
        </h3>
        <Link to={guild_id ? links.guild.horseOwnerId(guild_id) : links.guild.horseOwner()}>
          Owner list
          <img src={ARROW_RIGHT_ICON} alt='arow' className='link' />
        </Link>
      </div>
      {loading ? (
        <Spin />
      ) : (
        <div className={`content-box ${ownerLength === 1 ? 'top-one' : ''}`}>
          {memoizedTopHorseList.map((stable, index) => (
            <TopStableBox
              key={stable.id}
              stable={stable}
              customClass='col-12 col-sm-4'
              selfIndex={ownerLength === 1 ? 1 : index}
              options={options}
              isTopOwners
            />
          ))}
        </div>
      )}
    </TopMembersContainer>
  )
}

export default TopOwners
