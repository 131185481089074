import styled from 'styled-components'

interface StyledProps {
  colorHorse: string
}

const HorseBodyInfoStyled = styled.div<StyledProps>`
  width: 100%;
  .right-body {
    .attribute-container {
      .value {
        font-size: 18px;
      }

      .attribute-box {
        padding: 10px;
      }
    }
    .career-win-rates {
      padding-top: 30px;
    }

    .stats-body-container {
      padding: 20px 0px;

      .stats-type {
        font-size: 20px;
      }
      .stats-container {
        .title-stats {
          font-size: 24px;
        }
      }
    }
    .ability-container {
      padding-top: 20px;
      .ability-box-container {
        .ability-box {
          .title {
            font-size: 20px;
          }
        }
      }
    }
    .plus-item {
      &.only-view {
        .push-doping {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.5;
          margin-right: 5px;
        }
      }
    }
    .doping-plus {
      margin-top: 16px;
      .d-flex {
        position: relative;
        .frame-union {
          position: absolute;
          left: 0px;
          height: 65px;
          width: 100%;
        }
      }
      .name {
        color: ${({ theme }) => theme.color.lightNeon};
        margin-bottom: 0px;
        font-size: 20px;
      }
      .push-doping {
        background: transparent;
        border: none;
        position: relative;
        margin-right: 15px;
        &.new {
          margin-right: 5px;
        }
        &.use {
          margin-right: 20px;
        }
        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.5;
        }
        img {
          height: 40px;
          &.shadown {
            height: 36px;
          }
          &.d-block {
            height: 25px;
            position: absolute;
            top: 15%;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
        span {
          color: #fff;
          position: absolute;
          top: -10px;
          background: #ff7a00;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          right: -14px;
          padding: 8px;
        }
      }
    }
  }

  .horse-detail {
    .stats-container {
      .quick-stats-box {
        .content {
          justify-content: space-between;
        }
      }
    }
  }

  .box__bloodline {
    display: flex;
    align-items: center;

    .image_bloodline {
      height: 80px;
      margin-right: 40px;
      cursor: pointer;
    }

    .image_run_type {
      height: 40px;
      margin-right: 40px;
      cursor: pointer;
    }

    .image_charitic {
      height: 60px;
      margin-right: 40px;
      cursor: pointer;
    }

    .image_color {
      display: block;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: ${({ colorHorse }) => colorHorse};
      border: 1px solid #fff;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
`
export default HorseBodyInfoStyled
