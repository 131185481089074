/* eslint-disable @typescript-eslint/no-explicit-any */
import { Horse, OWNER_STATUS } from 'models'
import { ClassTag } from 'shared'
import { getGuildTag } from 'utils/helper'
import VipIcon from 'features/components/VipIcon'
import { useEffect, useState } from 'react'
import { STRING_ARMOR_SKIN, STRING_BLOODLINE_CHARACTER } from 'apps/constants'
import ImageArmorSkin from 'features/components/ImageArmorSkin'
import { VIEW_DOPING } from 'assets/images'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import MyHorseItemStyled from './styled'
import { getImageBloodline, getImageGender, getImageRegion } from 'utils/getLinkImage'

interface MyHorseItemProps {
  horse: Horse
  openHorseModal: boolean
  closeHorseModal: () => void
  handleOpenModalHorse: () => void
  myName: string
  atProfile: boolean
  idBeChoose: number
  stt: number
  handleOpenModalBloodline?: any
}

function MyHorseITem({ horse, handleOpenModalHorse, stt, handleOpenModalBloodline }: MyHorseItemProps) {
  const [horseHead, setHorseHead] = useState('')
  const [horseBody, setHorseBody] = useState('')
  const [horseLeg, setHorseLeg] = useState('')
  const [horseSkin, setHorseSkin] = useState('')

  useEffect(() => {
    if (horse) {
      horse?.weapon_items_equipped?.map((item: any) => {
        switch (item?.body_part) {
          case STRING_ARMOR_SKIN.HEAD: {
            return setHorseHead(STRING_ARMOR_SKIN.HEAD)
          }
          case STRING_ARMOR_SKIN.BODY: {
            return setHorseBody(STRING_ARMOR_SKIN.BODY)
          }
          case STRING_ARMOR_SKIN.LEG: {
            return setHorseLeg(STRING_ARMOR_SKIN.LEG)
          }
          case STRING_ARMOR_SKIN.SKIN: {
            return setHorseSkin(STRING_ARMOR_SKIN.SKIN)
          }
          default:
            break
        }
      })
    }
  }, [horse])

  const _handleClickImage = (event: React.MouseEvent, type: string, content: string) => {
    event.stopPropagation()
    handleOpenModalBloodline(type, content)
  }

  return (
    <MyHorseItemStyled onClick={handleOpenModalHorse} isActive={horse?.active}>
      <td className='color-white stt width-5'>{stt + 1}</td>
      <td className='color-white width-10'>
        <img src={horse.sub_avatar} alt='list-horse' className='horse-avatar' />
      </td>
      <td className='color-white width-15 horse-name horse__name__cus'>
        <div className='row-name'>
          <span className='name__horse'>
            <span className='color-yellow'>{horse.guild && getGuildTag(horse.guild.guild_tag)}</span>
            <span className={horse?.guild?.guild_tag ? 'name' : ''}>{horse.name}</span>
          </span>
        </div>
      </td>
      <td className='width-5'>
        <div className='vip__pass'>{horse?.msp_details?.msp && <VipIcon />}</div>
      </td>
      <td className='width-5'>
        <div className='vip__pass'>
          {horse?.equipped_boost_item && <MemoizedLoadImageCommon srcImg={VIEW_DOPING} />}
        </div>
      </td>
      <div
        onClick={event =>
          _handleClickImage(
            event,
            STRING_BLOODLINE_CHARACTER.BLOODLINE,
            `${horse?.bloodline?.name} - ${horse?.bloodline?.type}`
          )
        }
        className='width-16 image_bloodline'
      >
        <MemoizedLoadImageCommon srcImg={getImageBloodline(horse?.bloodline?.name)} />
      </div>
      <td className='color-white width-16'>
        <div className='gender__center'>
          <div className='gender'>
            <MemoizedLoadImageCommon srcImg={getImageGender(horse?.gender)} />
          </div>
        </div>
      </td>
      <td className='color-white width-16'>
        <ClassTag text={horse.racing_class} isActive={true} customClass='horse-class position-relative' />
      </td>
      <td className='width-12'>
        <div className='color-red'>{horse.active === false ? 'Disabled' : ''}</div>
        {horse?.own_status === 'LENDING' ? <div className='color-white'>BORROWED</div> : ''}
        {horse?.own_status === OWNER_STATUS.GuildKey ? <div className='color-white'>RENTING</div> : ''}
      </td>
    </MyHorseItemStyled>
  )
}

export default MyHorseITem
