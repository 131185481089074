/* eslint-disable @typescript-eslint/no-explicit-any */
import { DROPDOWN_TAG_ICON } from 'assets/images'
import { useOnClickOutside } from 'hooks'
import { useRef, useState } from 'react'
import SelectCustomStyled from './styled'

interface PropsSelectCustom {
  nameSelect?: string
  imgSelect?: any
  dataSelect: any
  onSelected: (item: string) => void
  customClass?: string
  disabled?: boolean
  isFiltered?: boolean
}
function SelectCustomImg({
  nameSelect,
  imgSelect,
  dataSelect,
  onSelected,
  customClass = '',
  disabled
}: PropsSelectCustom) {
  const selectRef = useRef(null)

  const [show, setShow] = useState(false)

  useOnClickOutside(selectRef, e => {
    if (e.target == selectRef.current) {
      return
    }
    setShow(false)
  })

  return (
    <SelectCustomStyled>
      <div className={`select-box-customs ${customClass}`} onClick={() => setShow(!show)} ref={selectRef}>
        <span className='select-name'>
          <span className='img_select'>
            <img src={imgSelect} alt='' />
          </span>
          {nameSelect}
        </span>
        {!disabled && (
          <img
            className={!show ? 'select-drop-icon ' : 'select-drop-icon up-down-icon '}
            src={DROPDOWN_TAG_ICON}
            alt=''
          />
        )}
        {/* <img src={ORANGE_LINE} alt='' className='orange-line position-absolute' /> */}
        {show && !disabled && (
          <ul className='select-content-dropdown'>
            {dataSelect.map((item: any, index: any) => (
              <li
                key={index}
                onClick={() => {
                  onSelected(item)
                  setShow(false)
                }}
                className={nameSelect == item?.name ? 'active' : ''}
              >
                <span className='img_contain'>
                  <img src={item?.img} alt='' />
                </span>
                {item?.name}
              </li>
            ))}
            <div className='bottom_line'></div>
          </ul>
        )}
      </div>
    </SelectCustomStyled>
  )
}
export default SelectCustomImg
