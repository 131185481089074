/* eslint-disable @typescript-eslint/no-explicit-any */

import { notification } from 'antd'
import guildApi from 'apis/guildApi'
import ChooseHorseModalGuildFarm from 'features/Guild/components/ChooseHoreModalGuildFarm'
import ConfirmGuildFarmModal from 'features/Guild/components/ConfirmGuildFarmModal'
import { RaceTable, RequestLoginModal } from 'features/Race/components'
import { useAppSelector, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { HorseAvailable, LendingFilterType, MyHorseListParams, TypeSorts } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonCustom from 'shared/ButtonCustom'
import SelectCustom from 'shared/SelectCustom'
import { guildYourHorseColumns } from 'utils/columns'
import { handleAsyncRequest } from 'utils/helper'
import YourHorseStyled from './styled'

const defaultTypeFilter: TypeSorts = [
  {
    name: LendingFilterType.ALL,
    isActive: false
  },
  {
    name: LendingFilterType.IN_FARM,
    isActive: false
  },
  {
    name: LendingFilterType.LENDING,
    isActive: false
  },
  {
    name: LendingFilterType.BORROWED,
    isActive: false
  }
]

export default function YourHorse() {
  const myHorseListParams: MyHorseListParams = {
    limit: 20,
    page: 1,
    sort: 'lendingDate-desc'
  }
  const [api] = notification.useNotification()
  const [classFilter, setClassFilter] = useState('All')
  const [, setLoading] = useState<boolean>(false)
  const memoizedResultListSelectionColumns = useMemo(() => guildYourHorseColumns, [])
  const [params, setParams] = useState<MyHorseListParams>(myHorseListParams)
  const [total, setTotal] = useState()
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [listHorse, setListHorse] = useState([])
  const [isModalChooseHorseOpen, toggleIsChooseHorseModalOpen] = useToggle(false)
  const [, setIsRegister] = useState(true)
  const [horseDetails, setHorseDetails] = useState<HorseAvailable>()
  const [isRefresh, toggleIsRefresh] = useToggle(false)
  const [isModalSpecialRaceOpen, toggleIsModalSpecialRaceOpen] = useToggle(false)

  const { auth, profile } = useAppSelector(state => state)
  const { t } = useTranslation()

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'top',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  useEffect(() => {
    if (isRefresh) {
      toggleIsChooseHorseModalOpen()
      setParams({ ...params, page: 1 })
    }
  }, [isRefresh])

  useEffect(() => {
    if (profile.id) {
      getGuildFarms()
    }
  }, [profile, params])

  const getGuildFarms = async () => {
    setLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      profile.guild_id
        ? guildApi.getGuildFarms(profile.guild_id, { ...params, myHorse: true })
        : guildApi.getYourHorseGuildFarms({ ...params, myHorse: true })
    )
    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen()
        setLoading(false)
        return
      }
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }
    if (result) {
      const formatData = result.data.records.map((record: any) => {
        return {
          ...record,
          horse_name: record.horse.name,
          horse_gender: record.horse.gender,
          owner_name: record.owner.name,
          status: record.lending_status,
          sub_avatar: record.horse.sub_avatar,
          racing_class_name: record.horse.racing_class
        }
      })
      setListHorse(params.page > 1 ? [...listHorse, ...formatData] : formatData)
      setTotal(result.data?.total)
    }
    setLoading(false)
    toggleIsRefresh(false)
  }

  const handleRowItemClick = (value: any) => {
    console.log(value)
    // navigate(links.guild.detail(value.horse.token_id))
  }

  const handleWidthdrawHorseFarm = () => {
    if (!auth.isLogged) {
      toggleIsRequestLoginModalOpen()
      return
    }
    toggleIsChooseHorseModalOpen()
    setIsRegister(true)
  }

  const handleHorseItem = (horse: HorseAvailable) => {
    setHorseDetails(horse)
  }

  const handleFilterClicked = (item: string) => {
    setParams({
      ...params,
      page: 1,
      status: item === LendingFilterType.ALL ? null : item.toUpperCase()
    })
    setClassFilter(item)
  }

  return (
    <YourHorseStyled>
      <div className='your-horse'>
        <div className='d-flex pt-3 justify-content-end gap-5'>
          <ButtonCustom onClickButton={handleWidthdrawHorseFarm} buttonName='Register' />
          <SelectCustom
            dataSelect={defaultTypeFilter}
            nameSelect={classFilter}
            onSelected={handleFilterClicked}
            isFiltered
          />
        </div>
        <RaceTable
          columns={memoizedResultListSelectionColumns}
          data={listHorse ?? []}
          raisePage={setParams}
          loader={true}
          params={params}
          isRowClickable
          checkLoadingPage={false}
          totalRace={total}
          onRowClick={handleRowItemClick}
          currentUser={profile}
          noDataText={t(`${NOTIFICATION_MESSAGE}.dataNotFound`)}
          isHorseTable
        />
      </div>

      {isModalChooseHorseOpen && (
        <ChooseHorseModalGuildFarm
          getHorseDetail={handleHorseItem}
          toggleIsModalSpecialRaceOpen={toggleIsModalSpecialRaceOpen}
          toggleIsModalOpen={toggleIsChooseHorseModalOpen}
          onCloseButtonClick={() => toggleIsChooseHorseModalOpen(false)}
        />
      )}
      {isModalSpecialRaceOpen && (
        <ConfirmGuildFarmModal
          toggleIsRefresh={toggleIsRefresh}
          horse={horseDetails}
          toggleIsModalOpen={toggleIsModalSpecialRaceOpen}
          onCloseButtonClick={() => toggleIsModalSpecialRaceOpen(false)}
        />
      )}
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </YourHorseStyled>
  )
}
