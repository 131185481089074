import { Skeleton, Space } from 'antd'
import LoadingContainer from './styled'

const Loading = () => {
  return (
    <LoadingContainer>
      <Space style={{ width: '100%' }}>
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
        <Skeleton.Input style={{ width: '100%' }} active size='default' />
      </Space>
    </LoadingContainer>
  )
}

export default Loading
