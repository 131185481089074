import styled from 'styled-components'

const OneLineTitleCloneStyled = styled.div`
  background-color: transparent;
  padding-bottom: 57px;

  .text {
    text-transform: uppercase;
    color: #4ef076;
  }

  .total {
    color: #fff566;
  }
`

export default OneLineTitleCloneStyled
