import styled from 'styled-components'

const EnergyItemModalStyled = styled.div`
  position: relative;
  width: 800px;

  .close-btn {
    top: 10px;
    right: 90px;
    background-color: ${({ theme }) => theme.color.transparent};
    border: none;
    z-index: 1;
  }
  .title {
    width: 200px;
    background-color: ${({ theme }) => theme.color.transparent};
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
    .text {
      font-size: 24px;
    }
  }
  .quick-view {
    background-color: ${({ theme }) => theme.color.neutral};
    margin: 0 80px;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      font-size: 20px;
      margin: 32px 0px;
      color: white;
    }

    .item-container {
      display: flex;
      gap: 20px;
      .item-list {
        width: 157px;
        -webkit-clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
        clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
        background: #515460;
        padding: 1px;
        cursor: pointer;
        .item-line {
          position: relative;
          background: linear-gradient(0deg, #030317, #030317), linear-gradient(0deg, #515460, #515460);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 33px 0px;
          -webkit-clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
          clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
          padding-top: 40px;
          padding-bottom: 20px;
        }
        .item-line:hover {
          background-image: linear-gradient(#4ff077, #48f7b9);
          .numb {
            border: 1.5px solid;
            border-radius: 50%;
            width: 38px;
            height: 38px;
            span {
              color: #000;
            }
          }
        }
        .amount {
          position: absolute;
          top: 5%;
          left: 5%;
        }
        .numb {
          position: relative;
          img {
            width: 38px;
          }
          span {
            position: absolute;
            font-weight: 600;
            left: 1px;
            color: #fff566;
            font-size: 18px;
            width: 100%;
            text-align: center;
            bottom: 5px;
          }
        }
      }
      .active {
        .item-line {
          background: linear-gradient(#4ff077, #48f7b9);
          .amount {
            img {
              filter: none;
            }
            span {
              color: black;
            }
          }
        }
      }
      .item-amount {
        position: relative;
        .amount {
          position: absolute;
          left: 50%;
          top: 45%;
          transform: translate(-50%, -50%);
          color: white;
          width: 100%;
          text-align: center;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          padding: 0 5px;
        }
        img {
          width: 155px;
        }
      }
    }
    .btn-group {
      display: flex;
      gap: 20px;
      padding-top: 20px;
      div {
        cursor: pointer;
      }
    }
  }
`

export default EnergyItemModalStyled
