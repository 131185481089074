/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate, Route, Routes } from 'react-router-dom'

import { constants, paths } from 'apps'
import { useLocalStorage } from 'hooks'
import { CommonLayout } from 'layouts'
import { useEffect } from 'react'
import { PublicRoute } from 'routes'
import { handleAsyncRequest } from 'utils/helper'
import { getSigner } from 'utils/metamask'
import { TopHorses, TopUsers, RankingMain } from './pages'
function SpecialRace() {
  const [, setUserId] = useLocalStorage(constants.USER_ID_KEY, 0)
  const [, setAccessToken] = useLocalStorage(constants.ACCESS_TOKEN_KEY, null)
  const [, setRefreshToken] = useLocalStorage(constants.REFRESH_TOKEN_KEY, null)

  const checkSignerProfile = async () => {
    const [signerError] = await handleAsyncRequest(getSigner())
    if (signerError) {
      setUserId(0)
      setAccessToken('')
      setRefreshToken('')
    }
  }
  useEffect(() => {
    checkSignerProfile()
  }, [])
  return (
    <Routes>
      <Route
        path={paths.default()}
        element={
          <PublicRoute layout={CommonLayout}>
            <RankingMain />
          </PublicRoute>
        }
      >
        <Route path={paths.ranking.topHorses()} element={<TopHorses />} />
        <Route path={paths.ranking.topUsers()} element={<TopUsers />} />
      </Route>
      <Route path='*' element={<Navigate replace to={paths.notFound.feature()} />} />
    </Routes>
  )
}

export default SpecialRace
