import links from './links'

export const headerProps = [
  {
    name: 'Race',
    key: 'race',
    path: links.race.open()
  },
  {
    name: 'Lending',
    key: 'lending',
    path: links.market.market()
  },
  {
    name: 'Shop',
    key: 'shop',
    path: `${links.shop.buyItems()}?type=HEAD`
  }
]
