import styled from 'styled-components'

const ItemNFTStyled = styled.div`
  .item {
    width: 175px;
    height: 175px;
    position: relative;
    .btn-detail-container {
      position: absolute;
      top: 45%;
      left: 20%;
      display: none;
      clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
      background: linear-gradient(90deg, #4ef07621 0%, #48f7ba17 100%);
      z-index: 5;
      padding: 1px;
      width: 103px;
      height: 34px;
      aspect-ratio: 100 / 42;

      .btn-detail {
        clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
        border: none;
        background: linear-gradient(
          178.19deg,
          rgba(78, 240, 118, 0.5) 1.64%,
          rgba(72, 247, 186, 0) 47.93%,
          rgba(78, 240, 118, 0.5) 97.84%
        );
        font-size: 16px;
        line-height: 20px;
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      .btn-detail-container {
        display: block;
      }
    }
    .avt-item {
      position: absolute;
      height: 100px;
    }
    .description {
      margin-bottom: 5px;
      .level {
        .rate-star {
          &.disable {
            display: none;
          }
        }
      }
      span {
        img {
          width: 15px;
        }
      }
    }
    .description {
      position: absolute;
      width: 100%;
      bottom: 0;
      text-align: center;

      h4 {
        color: #fff;
        font-size: 16px;
        margin-bottom: 0px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        padding: 0 5px;
      }
      span {
        color: #fff;
        font-size: 16px;
      }
    }
  }
  .item {
    overflow: hidden;
    position: relative;
    .avt-item {
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1.5);
    }

    &.stats,
    &.ability {
      .avt-item {
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.8);
      }
    }
    &.energy {
      .avt-item {
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.8);
      }
    }
  }
  .detail {
    overflow: hidden;
    &.skin {
      .avt-item {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.5);
      }
    }
    &.stats,
    &.ability {
      .avt-item {
        transform: scale(1);
        left: 34%;
        top: 15%;
      }
    }
    &.energy {
      .avt-item {
        transform: scale(1);
        left: 25%;
        top: 15%;
      }
    }
    .avt-item {
      padding-top: 10px;
    }
  }

  .detail:hover {
    transform: none;
  }
`

export default ItemNFTStyled
