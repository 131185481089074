import styled from 'styled-components'

const ManagementDetailStyled = styled.div`
  width: 100%;
  color: #fff;

  margin-bottom: 50px;
  .head {
    width: 575px;
    height: 50px;
    background: linear-gradient(
      90deg,
      rgba(18, 21, 32, 0) 0%,
      rgba(78, 242, 125, 0.4) 51.04%,
      rgba(18, 22, 33, 0) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 20px;

    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }

    span {
      color: #fff;
      font-size: 20px;
      display: inline-block;
      padding: 0 3px;

      &:first-child {
        padding-left: 20px;
      }
    }
  }

  .time-since {
    margin: 0 auto;
    width: 975px;
    font-size: 18px;
    line-height: 27px;
    .color-text {
      color: #fff566;
      padding-right: 5px;
    }
  }

  .content-horse {
    margin: 0 auto;
    width: 975px;
    min-height: 240px;
    border: 1px solid #47f0b1;
    position: relative;
    background: linear-gradient(180deg, rgba(19, 2, 41, 0) 0%, #252b43 100%);
    padding: 20px;
    padding-left: 80px;
    display: grid;
    grid-template-columns: 440px 600px;

    .line-1 {
      position: absolute;
      bottom: -11px;
      left: 0;
    }
    .line-2 {
      position: absolute;
      bottom: -11px;
      right: 0;
    }
  }

  .group-button-action {
    margin: 0 auto;
    width: 600px;
    margin-top: 60px;
    .flex-btn {
      display: flex;
      justify-content: space-evenly;
    }
  }
`

export default ManagementDetailStyled
